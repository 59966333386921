import { createSlice } from "@reduxjs/toolkit";
import { FormatTypes } from "utilities/constants";
import { MailMergeObjectTypes } from "utilities/reportEditor";
import {
  SectionTypeEnum,
  isGridSelected,
  isColumnSelected,
  isSectionSelected,
  isRowSelected,
  isCellSelected,
  findSection,
  findRelatedSectionByType,
  updateSection,
  setSectionField,
  setFormatStyleProperty,
  setDefaultStyleItem,
  getDefaultStyleItem,
  validInsert,
  validDelete,
  getSelectedType,
  shiftCellsLeft,
  deleteSection,
  deleteColumnCells,
  removeUnusedSectionCells,
  OwnerTypes,
} from "utilities/reportEditor";
import { filterOutOnId, findById, findIndexById, idsAreEqual } from "utilities/stringAndArray";

// Redux global state component for handling report definitions
export const reportSlice = createSlice({
  name: "Report",
  initialState: {
    allRecordsLoaded: false,
    records: [],
    selectedGridElements: [],
    selectedRecordIndex: null,
  },
  reducers: {
    addRecord: (state, action) => {
      if (findById(state.records, action.payload.report.id)) {
        state.records = state.records.map((record) =>
          idsAreEqual(record.id, action.payload.report.id) ? action.payload.report : record
        );
      } else {
        state.records = [...state.records, { ...action.payload.report }];
      }
      if (action.payload.selected === true) {
        state.selectedRecordIndex = findIndexById(state.records, action.payload.report.id);
        state.selectedGridElements = [];
      }
    },
    updateRecord: (state, action) => {
      state.records = state.records.map((record) =>
        idsAreEqual(record.id, action.payload.report.id) ? action.payload.report : record
      );
    },
    deleteRecord: (state, action) => {
      state.records = [...filterOutOnId(state.records, action.payload.reportId)];
    },
    addAllRecords: (state, action) => {
      if (state.allRecordsLoaded === true) return;
      state.records = [...action.payload.reports];
      state.allRecordsLoaded = true;
    },

    // Ownership
    updateOwnership: (state, action) => {
      let record = findById(state.records, action.payload.reportId);
      record.ownerType = action.payload.ownerType;
      switch (record.ownerType) {
        case OwnerTypes.ACCOUNT:
          record.accountId = action.payload.ownerId;
          break;
        case OwnerTypes.GROUP:
          record.groupId = action.payload.ownerId;
          break;
        default:
      }
    },
    updateSimpleFieldValue: (state, action) => {
      const fieldName = action.payload.fieldName;
      let record = findById(state.records, action.payload.reportId);
      if (record.hasOwnProperty(fieldName)) {
        record[fieldName] = action.payload.value;
      }
    },

    // Sort order
    setSortOrderProperty: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      let newSortColumns = [];
      let oldSortColumn = null;
      const newSortColumn = { id: ++identityCurrent, ...action.payload.sortColumn };

      oldSortColumn = format.sortColumns.find((sortColumn, index) => index === action.payload.index);
      newSortColumns = [
        ...format.sortColumns.map((sortColumn, index) => (index === action.payload.index ? newSortColumn : sortColumn)),
      ];

      switch (format.formatType) {
        case FormatTypes.MAILMERGE:
          // Only one output footer allowed
          if (newSortColumn.outputFooter) {
            newSortColumns = newSortColumns.map((sortColumn, index) => {
              if (index === action.payload.index || !sortColumn.outputFooter) {
                return sortColumn;
              }
              return { ...sortColumn, outputFooter: false };
            });
          }

          state.records[state.selectedRecordIndex].queryObject = {
            ...format,
            identityCurrent: identityCurrent,
            sortColumns: newSortColumns,
          };
          break;
        case FormatTypes.GRID:
          {
            let sectionsChanged = false;
            let newSections = [...format.gridSectionGroup.sections];

            if (oldSortColumn.outputHeader !== newSortColumn.outputHeader) {
              newSections = newSections.map((section) =>
                section.sectionTypeId === SectionTypeEnum.GROUP_HEADER && section.index === action.payload.index
                  ? { ...section, isHidden: !newSortColumn.outputHeader }
                  : section
              );
              sectionsChanged = true;
            }
            if (oldSortColumn.outputFooter !== newSortColumn.outputFooter) {
              newSections = newSections.map((section) =>
                section.sectionTypeId === SectionTypeEnum.GROUP_FOOTER && section.index === action.payload.index
                  ? { ...section, isHidden: !newSortColumn.outputFooter }
                  : section
              );
              sectionsChanged = true;
            }

            if (sectionsChanged) {
              const newSectionGroup = {
                ...format.gridSectionGroup,
                sections: newSections,
              };
              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridSectionGroup: newSectionGroup,
                sortColumns: newSortColumns,
              };
            } else {
              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                identityCurrent: identityCurrent,
                sortColumns: newSortColumns,
              };
            }
          }
          break;
        default:
          state.records[state.selectedRecordIndex].queryObject = {
            ...format,
            identityCurrent: identityCurrent,
            sortColumns: newSortColumns,
          };
      }
    },
    addSortOrderProperty: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      let newSortColumns = [];

      const newSortColumn = { id: ++identityCurrent, ...action.payload.sortColumn };

      if (format.sortColumns) {
        newSortColumns = [...format.sortColumns, { ...newSortColumn }];
      } else {
        newSortColumns = [{ ...newSortColumn }];
      }

      if (format.formatType !== FormatTypes.GRID) {
        state.records[state.selectedRecordIndex].queryObject = {
          ...format,
          identityCurrent: identityCurrent,
          sortColumns: newSortColumns,
        };
        return;
      }

      const newIndex = newSortColumns.length - 1;

      // Section changes if needed
      let sectionsChanged = false;
      let newSections = [...format.gridSectionGroup.sections];

      const headerSection = newSections.find(
        (section) => section.sectionTypeId === SectionTypeEnum.GROUP_HEADER && section.index === newIndex
      );

      if (!headerSection) {
        newSections.push({
          id: ++identityCurrent,
          sectionTypeId: SectionTypeEnum.GROUP_HEADER,
          index: newIndex,
          isHidden: !newSortColumn.outputHeader,
          rowCount: 1,
          sectionGroups: [],
          cells: [],
        });
        sectionsChanged = true;
      } else {
        newSections = newSections.map((section) =>
          section.sectionTypeId === SectionTypeEnum.GROUP_HEADER && section.index === newIndex
            ? { ...section, isHidden: !newSortColumn.outputHeader }
            : section
        );
        sectionsChanged = true;
      }

      const footerSection = newSections.find(
        (section) => section.sectionTypeId === SectionTypeEnum.GROUP_FOOTER && section.index === newIndex
      );

      if (!footerSection) {
        newSections.push({
          id: ++identityCurrent,
          sectionTypeId: SectionTypeEnum.GROUP_FOOTER,
          index: newIndex,
          isHidden: !newSortColumn.outputHeader,
          rowCount: 1,
          sectionGroups: [],
          cells: [],
        });
        sectionsChanged = true;
      } else {
        newSections = newSections.map((section) =>
          section.sectionTypeId === SectionTypeEnum.GROUP_FOOTER && section.index === newIndex
            ? { ...section, isHidden: !newSortColumn.outputFooter }
            : section
        );
        sectionsChanged = true;
      }

      if (sectionsChanged) {
        newSections.sort((a, b) => {
          const aCompare =
            a.sectionTypeId === SectionTypeEnum.GROUP_FOOTER
              ? a.sectionTypeId - (a.index ?? 0) / 100
              : a.sectionTypeId + (a.index ?? 0) / 100;
          const bCompare =
            b.sectionTypeId === SectionTypeEnum.GROUP_FOOTER
              ? b.sectionTypeId - (b.index ?? 0) / 100
              : b.sectionTypeId + (b.index ?? 0) / 100;

          return aCompare - bCompare;
        });
        const newSectionGroup = {
          ...format.gridSectionGroup,
          sections: newSections,
        };
        state.records[state.selectedRecordIndex].queryObject = {
          ...format,
          identityCurrent: identityCurrent,
          gridSectionGroup: newSectionGroup,
          sortColumns: newSortColumns,
        };
      } else {
        state.records[state.selectedRecordIndex].queryObject = {
          ...format,
          identityCurrent: identityCurrent,
          sortColumns: newSortColumns,
        };
      }
    },
    removeSortOrderProperty: (state, action) => {
      if (action.payload.index !== null) {
        const format = state.records[state.selectedRecordIndex].queryObject;

        const newSortColumns = format.sortColumns
          .filter((sortColumn, index) => index !== action.payload.index)
          .map((sortColumn, index) => (sortColumn.index !== index ? { ...sortColumn, index: index } : sortColumn));

        if (format.formatType !== FormatTypes.GRID) {
          state.records[state.selectedRecordIndex].queryObject = {
            ...format,
            sortColumns: newSortColumns,
          };
          return;
        }

        let newSections = [...format.gridSectionGroup.sections];

        newSections = newSections.filter(
          (section) =>
            !(
              (section.sectionTypeId === SectionTypeEnum.GROUP_HEADER ||
                section.sectionTypeId === SectionTypeEnum.GROUP_FOOTER) &&
              section.index === action.payload.index
            )
        );

        const newSectionGroup = {
          ...format.gridSectionGroup,
          sections: newSections,
        };

        state.records[state.selectedRecordIndex].queryObject = {
          ...format,
          gridSectionGroup: newSectionGroup,
          sortColumns: newSortColumns,
        };
      }
    },

    // Property setting
    setFormatProperty: (state, action) => {
      for (const selected of state.selectedGridElements) {
        const format = state.records[state.selectedRecordIndex].queryObject;
        let identityCurrent = format.identityCurrent;

        switch (selected.type) {
          case "cell":
            {
              let startRow;
              let endRow;
              if (selected.spanRow) {
                startRow = Math.min(selected.row, selected.spanRow);
                endRow = Math.max(selected.row, selected.spanRow);
              } else {
                startRow = selected.row;
                endRow = selected.row;
              }
              let startCol;
              let endCol;
              if (selected.spanCol) {
                startCol = Math.min(selected.col, selected.spanCol);
                endCol = Math.max(selected.col, selected.spanCol);
              } else {
                startCol = selected.col;
                endCol = selected.col;
              }

              const thisSection = findSection(format.gridSectionGroup, selected.sectionId);

              let newSection = { ...thisSection };
              for (let row = startRow; row <= endRow; row++) {
                for (let col = startCol; col <= endCol; col++) {
                  let thisCell = null;
                  if (newSection.cells) {
                    thisCell = newSection.cells.find((cell) => cell.row === row && cell.column === col);
                  }

                  let updatedObject;
                  if (thisCell) {
                    updatedObject = { ...thisCell };
                  } else {
                    updatedObject = {
                      id: ++identityCurrent,
                      row: row,
                      column: col,
                    };
                  }

                  updatedObject.style = setFormatStyleProperty(
                    thisCell,
                    action.payload.name,
                    action.payload.value,
                    action.payload.resetValue
                  );

                  if (thisCell) {
                    newSection.cells = newSection.cells.map((cell) =>
                      cell.row === updatedObject.row && cell.column === updatedObject.column ? updatedObject : cell
                    );
                  } else {
                    if (newSection.cells) {
                      newSection.cells = [...newSection.cells, { ...updatedObject }];
                    } else {
                      newSection.cells = [{ ...updatedObject }];
                    }
                  }
                }
              }

              const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                identityCurrent: identityCurrent,
                gridSectionGroup: newSectionGroup,
              };
            }
            break;
          case "row":
            {
              const section = findSection(format.gridSectionGroup, selected.sectionId);

              let newSection = { ...section };

              let startRow;
              let endRow;
              if (selected.spanRow) {
                startRow = Math.min(selected.row, selected.spanRow);
                endRow = Math.max(selected.row, selected.spanRow);
              } else {
                startRow = selected.row;
                endRow = selected.row;
              }

              for (let row = startRow; row <= endRow; row++) {
                let thisObject = null;
                if (newSection.rows) {
                  thisObject = newSection.rows.find((testRow) => testRow.row === row);
                }

                let updatedObject;
                if (thisObject) {
                  updatedObject = { ...thisObject };
                } else {
                  updatedObject = { id: ++identityCurrent, row: row };
                }

                updatedObject.style = setFormatStyleProperty(
                  thisObject,
                  action.payload.name,
                  action.payload.value,
                  action.payload.resetValue
                );

                if (thisObject) {
                  newSection.rows = newSection.rows.map((row) => (row.row === updatedObject.row ? updatedObject : row));
                } else {
                  if (newSection.rows) {
                    newSection.rows = [...newSection.rows, { ...updatedObject }];
                  } else {
                    newSection.rows = [{ ...updatedObject }];
                  }
                }
              }

              const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                identityCurrent: identityCurrent,
                gridSectionGroup: newSectionGroup,
              };
            }
            break;
          case "column":
            {
              let startCol;
              let endCol;
              if (selected.spanCol) {
                startCol = Math.min(selected.col, selected.spanCol);
                endCol = Math.max(selected.col, selected.spanCol);
              } else {
                startCol = selected.col;
                endCol = selected.col;
              }
              let updateColumns = [...format.gridColumns];

              for (let col = startCol; col <= endCol; col++) {
                const thisColumn = updateColumns.find((column) => column.column === col);

                let updateColumn = { ...thisColumn };

                updateColumn.style = setFormatStyleProperty(
                  updateColumn,
                  action.payload.name,
                  action.payload.value,
                  action.payload.resetValue
                );

                updateColumns = updateColumns.map((column) =>
                  column.column === updateColumn.column ? updateColumn : column
                );
              }

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridColumns: updateColumns,
              };
            }
            break;
          case "grid":
            {
              const style = setFormatStyleProperty(
                format,
                action.payload.name,
                action.payload.value,
                action.payload.resetValue
              );

              const newSectionGroup = {
                ...format.gridSectionGroup,
                style: style,
              };

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridSectionGroup: newSectionGroup,
              };
            }
            break;
          case "section":
            {
              const thisSection = findSection(format.gridSectionGroup, selected.sectionId);
              let newSection = { ...thisSection };

              newSection.style = setFormatStyleProperty(
                newSection,
                action.payload.name,
                action.payload.value,
                action.payload.resetValue
              );

              const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridSectionGroup: newSectionGroup,
              };
            }
            break;
          default:
        }
      }
    },
    setBorderProperty: (state, action) => {
      const borderStyle = action.payload.style ? action.payload.style : "solid";

      for (const selected of state.selectedGridElements) {
        const format = state.records[state.selectedRecordIndex].queryObject;
        let identityCurrent = format.identityCurrent;

        switch (selected.type) {
          case "cell":
            {
              const thisSection = findSection(format.gridSectionGroup, selected.sectionId);

              let startRow;
              let endRow;
              if (selected.spanRow) {
                startRow = Math.min(selected.row, selected.spanRow);
                endRow = Math.max(selected.row, selected.spanRow);
              } else {
                startRow = selected.row;
                endRow = selected.row;
              }
              let startCol;
              let endCol;
              if (selected.spanCol) {
                startCol = Math.min(selected.col, selected.spanCol);
                endCol = Math.max(selected.col, selected.spanCol);
              } else {
                startCol = selected.col;
                endCol = selected.col;
              }

              let newSection = { ...thisSection };

              for (let row = startRow; row <= endRow; row++) {
                for (let col = startCol; col <= endCol; col++) {
                  let thisCell = null;
                  if (newSection.cells) {
                    thisCell = newSection.cells.find((cell) => cell.row === row && cell.column === col);
                  }

                  let cellStartRow = row;
                  let cellEndRow = thisCell && thisCell.rowSpan ? thisCell.row + thisCell.rowSpan - 1 : row;
                  let cellStartCol = col;
                  let cellEndCol = thisCell && thisCell.colSpan ? thisCell.column + thisCell.colSpan - 1 : col;

                  // Just process the selected area
                  if (
                    cellStartRow > startRow &&
                    cellEndRow < endRow &&
                    cellStartCol !== startCol &&
                    cellEndCol !== endCol
                  ) {
                    continue;
                  }

                  switch (action.payload.type) {
                    case "none":
                      if (!thisCell || !thisCell.style) {
                        // Nothing to remove
                        continue;
                      }
                      break;
                    case "all":
                      // Let it through
                      break;
                    case "outside":
                      if (
                        cellStartRow !== startRow &&
                        cellEndRow !== endRow &&
                        cellStartCol !== startCol &&
                        cellEndCol !== endCol
                      ) {
                        continue;
                      }
                      break;
                    case "top":
                      if (cellStartRow !== startRow) {
                        continue;
                      }
                      break;
                    case "left":
                      if (cellStartCol !== startCol) {
                        continue;
                      }
                      break;
                    case "right":
                      if (cellEndCol !== endCol) {
                        continue;
                      }
                      break;
                    case "bottom":
                      if (cellEndRow !== endRow) {
                        continue;
                      }
                      break;
                    default:
                      continue;
                  }

                  let thisFormatStyle = null;
                  let thisFormatStyleItem = null;
                  if (thisCell) {
                    thisFormatStyle = thisCell.style;
                    thisFormatStyleItem = getDefaultStyleItem(thisCell.style);
                  }

                  let updateBorder = { style: borderStyle };
                  if (action.payload.width) {
                    updateBorder.width = action.payload.width;
                  }
                  if (action.payload.color) {
                    updateBorder.color = action.payload.color;
                  }

                  let updateFormatStyleItem;
                  if (thisFormatStyleItem) {
                    updateFormatStyleItem = { ...thisFormatStyleItem };
                  } else {
                    updateFormatStyleItem = { default: true };
                  }

                  switch (action.payload.type) {
                    case "none":
                      // Delete all border entries
                      if (updateFormatStyleItem.border) {
                        delete updateFormatStyleItem.border;
                      }
                      if (updateFormatStyleItem.topBorder) {
                        delete updateFormatStyleItem.topBorder;
                      }
                      if (updateFormatStyleItem.bottomBorder) {
                        delete updateFormatStyleItem.bottomBorder;
                      }
                      if (updateFormatStyleItem.leftBorder) {
                        delete updateFormatStyleItem.leftBorder;
                      }
                      if (updateFormatStyleItem.rightBorder) {
                        delete updateFormatStyleItem.rightBorder;
                      }
                      break;
                    case "all":
                      updateFormatStyleItem.border = updateBorder;
                      break;
                    case "top":
                      updateFormatStyleItem.topBorder = updateBorder;
                      break;
                    case "bottom":
                      updateFormatStyleItem.bottomBorder = updateBorder;
                      break;
                    case "left":
                      updateFormatStyleItem.leftBorder = updateBorder;
                      break;
                    case "right":
                      updateFormatStyleItem.rightBorder = updateBorder;
                      break;
                    case "outside":
                      if (
                        cellStartRow === startRow &&
                        cellEndRow === endRow &&
                        cellStartCol === startCol &&
                        cellEndCol === endCol
                      ) {
                        updateFormatStyleItem.border = updateBorder;
                      } else {
                        if (cellStartRow === startRow) {
                          updateFormatStyleItem.topBorder = { ...updateBorder };
                        }
                        if (cellStartRow === endRow) {
                          updateFormatStyleItem.bottomBorder = { ...updateBorder };
                        }
                        if (cellStartCol === startCol) {
                          updateFormatStyleItem.leftBorder = { ...updateBorder };
                        }
                        if (cellEndCol === endCol) {
                          updateFormatStyleItem.rightBorder = { ...updateBorder };
                        }
                      }
                      break;
                    default:
                  }

                  let updateCell = null;
                  if (!thisCell) {
                    updateCell = { id: ++identityCurrent, row: row, column: col };
                  } else {
                    updateCell = { ...thisCell };
                  }

                  updateCell.style = setDefaultStyleItem(thisFormatStyle, updateFormatStyleItem);

                  if (thisCell) {
                    newSection.cells = newSection.cells.map((cell) =>
                      cell.row === row && cell.column === col ? updateCell : cell
                    );
                  } else {
                    if (newSection.cells) {
                      newSection.cells = [...newSection.cells, { ...updateCell }];
                    } else {
                      newSection.cells = [{ ...updateCell }];
                    }
                  }
                }
              }

              const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                identityCurrent: identityCurrent,
                gridSectionGroup: newSectionGroup,
              };
            }
            break;
          case "column":
            {
              let startCol;
              let endCol;
              if (selected.spanCol) {
                startCol = Math.min(selected.col, selected.spanCol);
                endCol = Math.max(selected.col, selected.spanCol);
              } else {
                startCol = selected.col;
                endCol = selected.col;
              }

              let updateColumns = [...format.gridColumns];

              for (let col = startCol; col <= endCol; col++) {
                const thisColumn = updateColumns.find((column) => column.column === col);

                let thisFormatStyleItem = null;
                let updateColumn = null;
                if (thisColumn) {
                  updateColumn = { ...thisColumn };
                  thisFormatStyleItem = getDefaultStyleItem(updateColumn.style);
                } else {
                  updateColumn = { id: ++identityCurrent, column: col };
                }

                let updateFormatStyleItem = null;
                if (thisFormatStyleItem) {
                  updateFormatStyleItem = { ...thisFormatStyleItem };
                } else {
                  updateFormatStyleItem = { default: true };
                }

                let updateBorder = { style: borderStyle };
                if (action.payload.width) {
                  updateBorder.width = action.payload.width;
                }
                if (action.payload.color) {
                  updateBorder.color = action.payload.color;
                }

                switch (action.payload.type) {
                  case "none":
                    // Delete all border entries
                    if (updateFormatStyleItem.border) {
                      delete updateFormatStyleItem.border;
                    }
                    if (updateFormatStyleItem.topBorder) {
                      delete updateFormatStyleItem.topBorder;
                    }
                    if (updateFormatStyleItem.bottomBorder) {
                      delete updateFormatStyleItem.bottomBorder;
                    }
                    if (updateFormatStyleItem.leftBorder) {
                      delete updateFormatStyleItem.leftBorder;
                    }
                    if (updateFormatStyleItem.rightBorder) {
                      delete updateFormatStyleItem.rightBorder;
                    }
                    if (updateFormatStyleItem.innerVerticalBorder) {
                      delete updateFormatStyleItem.innerVerticalBorder;
                    }
                    if (updateFormatStyleItem.innerHorizontalBorder) {
                      delete updateFormatStyleItem.innerHorizontalBorder;
                    }
                    break;
                  case "all":
                  case "outside":
                    if (col === startCol && col === endCol) {
                      updateFormatStyleItem.border = updateBorder;
                    } else if (col === startCol) {
                      updateFormatStyleItem.topBorder = updateBorder;
                      updateFormatStyleItem.bottomBorder = updateBorder;
                      updateFormatStyleItem.leftBorder = updateBorder;
                    } else if (col === endCol) {
                      updateFormatStyleItem.topBorder = updateBorder;
                      updateFormatStyleItem.bottomBorder = updateBorder;
                      updateFormatStyleItem.rightBorder = updateBorder;
                    } else {
                      updateFormatStyleItem.topBorder = updateBorder;
                      updateFormatStyleItem.bottomBorder = updateBorder;
                    }
                    if (action.payload.type === "all") {
                      updateFormatStyleItem.innerHorizontalBorder = updateBorder;
                    }
                    break;
                  case "top":
                    updateFormatStyleItem.topBorder = updateBorder;
                    break;
                  case "bottom":
                    updateFormatStyleItem.bottomBorder = updateBorder;
                    break;
                  case "left":
                    if (col === startCol) {
                      updateFormatStyleItem.leftBorder = updateBorder;
                    }
                    break;
                  case "right":
                    if (col === endCol) {
                      updateFormatStyleItem.rightBorder = updateBorder;
                    }
                    break;
                  default:
                }

                updateColumn.style = setDefaultStyleItem(updateColumn.style, updateFormatStyleItem);

                updateColumns = updateColumns.map((column) =>
                  column.column === updateColumn.column ? updateColumn : column
                );
              }

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                identityCurrent: identityCurrent,
                gridColumns: updateColumns,
              };
            }
            break;
          case "grid":
            {
              const thisFormatStyleItem = getDefaultStyleItem(format.gridSectionGroup.style);

              let updateBorder = { style: borderStyle };
              if (action.payload.width) {
                updateBorder.width = action.payload.width;
              }
              if (action.payload.color) {
                updateBorder.color = action.payload.color;
              }

              let updateFormatStyleItem;
              if (thisFormatStyleItem) {
                updateFormatStyleItem = { ...thisFormatStyleItem };
              } else {
                updateFormatStyleItem = { default: true };
              }

              switch (action.payload.type) {
                case "none":
                  // Delete all border entries
                  if (updateFormatStyleItem.border) {
                    delete updateFormatStyleItem.border;
                  }
                  if (updateFormatStyleItem.topBorder) {
                    delete updateFormatStyleItem.topBorder;
                  }
                  if (updateFormatStyleItem.bottomBorder) {
                    delete updateFormatStyleItem.bottomBorder;
                  }
                  if (updateFormatStyleItem.leftBorder) {
                    delete updateFormatStyleItem.leftBorder;
                  }
                  if (updateFormatStyleItem.rightBorder) {
                    delete updateFormatStyleItem.rightBorder;
                  }
                  if (updateFormatStyleItem.innerVerticalBorder) {
                    delete updateFormatStyleItem.innerVerticalBorder;
                  }
                  if (updateFormatStyleItem.innerHorizontalBorder) {
                    delete updateFormatStyleItem.innerHorizontalBorder;
                  }
                  break;
                case "all":
                  updateFormatStyleItem.border = updateBorder;
                  updateFormatStyleItem.innerVerticalBorder = updateBorder;
                  updateFormatStyleItem.innerHorizontalBorder = updateBorder;
                  break;
                case "top":
                  updateFormatStyleItem.topBorder = updateBorder;
                  break;
                case "bottom":
                  updateFormatStyleItem.bottomBorder = updateBorder;
                  break;
                case "left":
                  updateFormatStyleItem.leftBorder = updateBorder;
                  break;
                case "right":
                  updateFormatStyleItem.rightBorder = updateBorder;
                  break;
                case "outside":
                  updateFormatStyleItem.border = updateBorder;
                  break;
                default:
              }

              const updateSectionGroup = { ...format.gridSectionGroup };

              updateSectionGroup.style = setDefaultStyleItem(format.gridSectionGroup.style, updateFormatStyleItem);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridSectionGroup: updateSectionGroup,
              };
            }
            break;
          case "row":
            {
              const section = findSection(format.gridSectionGroup, selected.sectionId);

              let newSection = { ...section };

              let startRow;
              let endRow;
              if (selected.spanRow) {
                startRow = Math.min(selected.row, selected.spanRow);
                endRow = Math.max(selected.row, selected.spanRow);
              } else {
                startRow = selected.row;
                endRow = selected.row;
              }

              for (let row = startRow; row <= endRow; row++) {
                let thisObject = null;
                if (newSection.rows) {
                  thisObject = newSection.rows.find((testRow) => testRow.row === row);
                }

                let updatedObject;
                if (thisObject) {
                  updatedObject = { ...thisObject };
                } else {
                  updatedObject = { id: ++identityCurrent, row: row };
                }

                let thisFormatStyle = null;
                let thisFormatStyleItem = null;
                if (thisObject) {
                  thisFormatStyle = thisObject.style;
                  thisFormatStyleItem = getDefaultStyleItem(thisObject.style);
                }

                let updateBorder = { style: borderStyle };
                if (action.payload.width) {
                  updateBorder.width = action.payload.width;
                }
                if (action.payload.color) {
                  updateBorder.color = action.payload.color;
                }

                let updateFormatStyleItem;
                if (thisFormatStyleItem) {
                  updateFormatStyleItem = { ...thisFormatStyleItem };
                } else {
                  updateFormatStyleItem = { default: true };
                }

                switch (action.payload.type) {
                  case "none":
                    // Delete all border entries
                    if (updateFormatStyleItem.border) {
                      delete updateFormatStyleItem.border;
                    }
                    if (updateFormatStyleItem.topBorder) {
                      delete updateFormatStyleItem.topBorder;
                    }
                    if (updateFormatStyleItem.bottomBorder) {
                      delete updateFormatStyleItem.bottomBorder;
                    }
                    if (updateFormatStyleItem.leftBorder) {
                      delete updateFormatStyleItem.leftBorder;
                    }
                    if (updateFormatStyleItem.rightBorder) {
                      delete updateFormatStyleItem.rightBorder;
                    }
                    if (updateFormatStyleItem.innerVerticalBorder) {
                      delete updateFormatStyleItem.innerVerticalBorder;
                    }
                    if (updateFormatStyleItem.innerHorizontalBorder) {
                      delete updateFormatStyleItem.innerHorizontalBorder;
                    }
                    break;
                  case "top":
                    if (row === startRow) {
                      updateFormatStyleItem.topBorder = updateBorder;
                    }
                    break;
                  case "bottom":
                    if (row === endRow) {
                      updateFormatStyleItem.bottomBorder = updateBorder;
                    }
                    break;
                  case "left":
                    updateFormatStyleItem.leftBorder = updateBorder;
                    break;
                  case "right":
                    updateFormatStyleItem.rightBorder = updateBorder;
                    break;
                  case "all":
                  case "outside":
                    if (row === startRow && row === endRow) {
                      updateFormatStyleItem.border = updateBorder;
                    } else {
                      if (row === startRow) {
                        updateFormatStyleItem.topBorder = updateBorder;
                      }
                      if (row === endRow) {
                        updateFormatStyleItem.bottomBorder = updateBorder;
                      }
                      updateFormatStyleItem.leftBorder = updateBorder;
                      updateFormatStyleItem.rightBorder = updateBorder;
                    }
                    if (action.payload.type === "all") {
                      updateFormatStyleItem.innerVerticalBorder = updateBorder;
                    }
                    break;
                  default:
                }

                updatedObject.style = setDefaultStyleItem(thisFormatStyle, updateFormatStyleItem);

                if (thisObject) {
                  newSection.rows = newSection.rows.map((row) => (row.row === updatedObject.row ? updatedObject : row));
                } else {
                  if (newSection.rows) {
                    newSection.rows = [...newSection.rows, { ...updatedObject }];
                  } else {
                    newSection.rows = [{ ...updatedObject }];
                  }
                }
              }

              const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                identityCurrent: identityCurrent,
                gridSectionGroup: newSectionGroup,
              };
            }

            break;
          case "section":
            {
              const thisSection = findSection(format.gridSectionGroup, selected.sectionId);
              let newSection = { ...thisSection };

              const thisFormatStyleItem = getDefaultStyleItem(newSection.style);

              let updateBorder = { style: borderStyle };
              if (action.payload.width) {
                updateBorder.width = action.payload.width;
              }
              if (action.payload.color) {
                updateBorder.color = action.payload.color;
              }

              let updateFormatStyleItem;
              if (thisFormatStyleItem) {
                updateFormatStyleItem = { ...thisFormatStyleItem };
              } else {
                updateFormatStyleItem = { default: true };
              }

              switch (action.payload.type) {
                case "none":
                  // Delete all border entries
                  if (updateFormatStyleItem.border) {
                    delete updateFormatStyleItem.border;
                  }
                  if (updateFormatStyleItem.topBorder) {
                    delete updateFormatStyleItem.topBorder;
                  }
                  if (updateFormatStyleItem.bottomBorder) {
                    delete updateFormatStyleItem.bottomBorder;
                  }
                  if (updateFormatStyleItem.leftBorder) {
                    delete updateFormatStyleItem.leftBorder;
                  }
                  if (updateFormatStyleItem.rightBorder) {
                    delete updateFormatStyleItem.rightBorder;
                  }
                  if (updateFormatStyleItem.innerVerticalBorder) {
                    delete updateFormatStyleItem.innerVerticalBorder;
                  }
                  if (updateFormatStyleItem.innerHorizontalBorder) {
                    delete updateFormatStyleItem.innerHorizontalBorder;
                  }
                  break;
                case "all":
                  updateFormatStyleItem.border = updateBorder;
                  updateFormatStyleItem.innerVerticalBorder = updateBorder;
                  updateFormatStyleItem.innerHorizontalBorder = updateBorder;
                  break;
                case "top":
                  updateFormatStyleItem.topBorder = updateBorder;
                  break;
                case "bottom":
                  updateFormatStyleItem.bottomBorder = updateBorder;
                  break;
                case "left":
                  updateFormatStyleItem.leftBorder = updateBorder;
                  break;
                case "right":
                  updateFormatStyleItem.rightBorder = updateBorder;
                  break;
                case "outside":
                  updateFormatStyleItem.border = updateBorder;
                  break;
                default:
              }

              newSection.style = setDefaultStyleItem(format.gridSectionGroup.style, updateFormatStyleItem);

              const updateSectionGroup = updateSection(format.gridSectionGroup, newSection);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridSectionGroup: updateSectionGroup,
              };
            }
            break;
          default:
        }
      }
    },
    setGridColumnCount: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      let newColumns = [];
      let sectionGroup = [];

      if (action.payload.value < format.gridColumnCount) {
        // Remove unused columns
        newColumns = format.gridColumns.filter((column) => column.column <= action.payload.value);
        // Remove unused cells
        sectionGroup = removeUnusedSectionCells(format.gridSectionGroup, action.payload.value);
      } else {
        // Add new columns
        newColumns = [...format.gridColumns];
        for (let i = format.gridColumnCount + 1; i <= action.payload.value; i++) {
          newColumns.push({ id: ++identityCurrent, column: i, width: 100 });
        }
        sectionGroup = format.gridSectionGroup;
      }

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent: identityCurrent,
        gridColumnCount: action.payload.value,
        gridColumns: newColumns,
        gridSectionGroup: sectionGroup,
      };
    },
    setColumnWidth: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      let updateColumns = format.gridColumns;

      for (const selected of state.selectedGridElements) {
        let startCol;
        let endCol;
        if (selected.spanCol) {
          startCol = Math.min(selected.col, selected.spanCol);
          endCol = Math.max(selected.col, selected.spanCol);
        } else {
          startCol = selected.col;
          endCol = selected.col;
        }

        for (let col = startCol; col <= endCol; col++) {
          updateColumns = updateColumns.map((column) =>
            column.column === col ? { ...column, width: action.payload.value } : column
          );
        }
      }

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        gridColumns: updateColumns,
      };
    },
    setSectionRowCount: (state, action) => {
      for (const selected of state.selectedGridElements) {
        if (selected.type === "section") {
          const format = state.records[state.selectedRecordIndex].queryObject;

          const section = findSection(format.gridSectionGroup, selected.sectionId);
          const newSection = { ...section, rowCount: action.payload.value };

          const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

          state.records[state.selectedRecordIndex].queryObject = { ...format, gridSectionGroup: newSectionGroup };
        }
      }
    },
    setSectionStripe: (state, action) => {
      for (const selected of state.selectedGridElements) {
        if (selected.type === "section") {
          const format = state.records[state.selectedRecordIndex].queryObject;

          const section = findSection(format.gridSectionGroup, selected.sectionId);
          const newSection = { ...section, stripe: action.payload.value };

          const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

          state.records[state.selectedRecordIndex].queryObject = { ...format, gridSectionGroup: newSectionGroup };
        }
      }
    },
    setSectionRowHeight: (state, action) => {
      for (const selected of state.selectedGridElements) {
        if (selected.type === "row" || selected.type === "cell") {
          // eslint-disable-next-line no-loop-func
          const format = state.records[state.selectedRecordIndex].queryObject;
          let identityCurrent = format.identityCurrent;
          const section = findSection(format.gridSectionGroup, selected.sectionId);
          const newSection = { ...section };

          let rows = null;
          if (section.rows) {
            rows = section.rows;
          }

          let row = null;
          if (rows) {
            row = rows.find((row) => row.row === selected.row);
          }
          if (row) {
            rows = rows.map((row) => (row.row === selected.row ? { ...row, rowHeight: action.payload.value } : row));
          } else {
            if (rows) {
              rows = [...rows, { id: ++identityCurrent, row: selected.row, rowHeight: action.payload.value }];
            } else {
              rows = [{ id: ++identityCurrent, row: selected.row, rowHeight: action.payload.value }];
            }
          }

          newSection.rows = rows;

          const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

          state.records[state.selectedRecordIndex].queryObject = {
            ...format,
            identityCurrent: identityCurrent,
            gridSectionGroup: newSectionGroup,
          };
        }
      }
    },
    setSectionCellText: (state, action) => {
      for (const selected of state.selectedGridElements) {
        if (selected.type === "cell") {
          const format = state.records[state.selectedRecordIndex].queryObject;
          let identityCurrent = format.identityCurrent;

          const section = findSection(format.gridSectionGroup, selected.sectionId);

          const newSection = { ...section };

          let cells = null;
          if (section.cells) {
            cells = section.cells;
          }

          let cell = null;
          if (cells) {
            cell = cells.find((cell) => cell.row === selected.row && cell.column === selected.col);
          }
          if (cell) {
            cells = cells.map((cell) => {
              if (cell.row === selected.row && cell.column === selected.col) {
                const newCell = { ...cell, text: action.payload.value };

                delete newCell.fieldName;
                delete newCell.displayDataFieldLabel;

                return newCell;
              } else {
                return cell;
              }
            });
          } else {
            if (cells) {
              cells = [
                ...cells,
                {
                  id: ++identityCurrent,
                  column: selected.col,
                  row: selected.row,
                  text: action.payload.value,
                },
              ];
            } else {
              cells = [
                {
                  id: ++identityCurrent,
                  column: selected.col,
                  row: selected.row,
                  text: action.payload.value,
                },
              ];
            }
          }

          newSection.cells = cells;

          const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

          state.records[state.selectedRecordIndex].queryObject = {
            ...format,
            identityCurrent: identityCurrent,
            gridSectionGroup: newSectionGroup,
          };
        }
      }
    },
    setSectionCellTextDirect: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      const section = findSection(format.gridSectionGroup, action.payload.sectionId);

      const newSection = { ...section };

      let cells = null;
      if (section.cells) {
        cells = section.cells;
      }

      let cell = null;
      if (cells) {
        cell = cells.find((cell) => cell.row === action.payload.cell.row && cell.column === action.payload.cell.column);
      }
      if (cell) {
        cells = cells.map((cell) => {
          if (cell.row === action.payload.cell.row && cell.column === action.payload.cell.column) {
            const newCell = { ...cell, text: action.payload.value };

            delete newCell.fieldName;
            delete newCell.displayDataFieldLabel;

            return newCell;
          } else {
            return cell;
          }
        });
      } else {
        if (cells) {
          cells = [
            ...cells,
            {
              id: ++identityCurrent,
              column: action.payload.cell.column,
              row: action.payload.cell.row,
              text: action.payload.value,
            },
          ];
        } else {
          cells = [
            {
              id: ++identityCurrent,
              column: action.payload.cell.column,
              row: action.payload.cell.row,
              text: action.payload.value,
            },
          ];
        }
      }

      newSection.cells = cells;

      const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent: identityCurrent,
        gridSectionGroup: newSectionGroup,
      };
    },
    setSectionCellDataProperty: (state, action) => {
      for (const selected of state.selectedGridElements) {
        if (selected.type === "cell") {
          const format = state.records[state.selectedRecordIndex].queryObject;
          let identityCurrent = format.identityCurrent;

          const section = findSection(format.gridSectionGroup, selected.sectionId);

          const newSection = { ...section };

          let cells = null;
          if (section.cells) {
            cells = section.cells;
          }

          let cell = null;
          if (cells) {
            cell = cells.find((cell) => cell.row === selected.row && cell.column === selected.col);
          }

          let newCell = null;

          if (cell) {
            newCell = { ...cell };
          } else {
            newCell = { id: ++identityCurrent, column: selected.col, row: selected.row };
          }

          switch (action.payload.value) {
            case "multirow":
              if (newCell.displayDataFieldLabel) {
                delete newCell.displayDataFieldLabel;
              }
              newCell.multiRowSubquery = true;
              break;
            case "title":
              if (newCell.multiRowSubquery) {
                delete newCell.multiRowSubquery;
              }
              newCell.displayDataFieldLabel = true;
              break;
            default:
              if (newCell.displayDataFieldLabel) {
                delete newCell.displayDataFieldLabel;
              }
              if (newCell.multiRowSubquery) {
                delete newCell.multiRowSubquery;
              }
          }

          if (cell) {
            cells = cells.map((cell) => {
              if (cell.row === selected.row && cell.column === selected.col) {
                return newCell;
              } else {
                return cell;
              }
            });
          } else {
            if (cells) {
              cells = [...cells, newCell];
            } else {
              cells = [newCell];
            }
          }

          newSection.cells = cells;

          const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

          state.records[state.selectedRecordIndex].queryObject = {
            ...format,
            identityCurrent: identityCurrent,
            gridSectionGroup: newSectionGroup,
          };
        }
      }
    },
    setSectionCellField: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      const targetSection = findSection(format.gridSectionGroup, action.payload.sectionId);
      let targetCell = targetSection.cells.find(
        (cell) => cell.row === action.payload.row && cell.column === action.payload.column
      );
      if (!targetCell) {
        targetCell = { id: ++identityCurrent, row: action.payload.row, column: action.payload.column };
      }

      const dataField = action.payload.dataField;

      let relatedSectionType = null;
      if (dataField.dataSourceName !== "CustomerImage") {
        if (targetSection.sectionTypeId === SectionTypeEnum.LINE) {
          relatedSectionType = SectionTypeEnum.PAGE_HEADER;
        } else if (targetSection.sectionTypeId === SectionTypeEnum.PAGE_HEADER) {
          relatedSectionType = SectionTypeEnum.LINE;
        }
      }

      let relatedSection = null;
      if (relatedSectionType) {
        relatedSection = findRelatedSectionByType(format.gridSectionGroup, targetSection.id, relatedSectionType);
      }

      let newSectionGroup = setSectionField(format.gridSectionGroup, targetSection, targetCell, dataField);

      let targetRelatedRow = 0;

      if (relatedSection) {
        if (targetSection.rowCount === relatedSection.rowCount) {
          targetRelatedRow = action.payload.row;
        } else if (
          targetSection.sectionTypeId === SectionTypeEnum.PAGE_HEADER &&
          targetSection.rowCount > 1 &&
          relatedSection.rowCount === 1
        ) {
          targetRelatedRow = 1;
        } else if (targetSection.sectionTypeId === SectionTypeEnum.LINE && targetSection.rowCount === 1) {
          targetRelatedRow = relatedSection.rowCount;
        }
      }

      if (
        relatedSection &&
        relatedSection.rowCount !== 0 &&
        targetRelatedRow !== 0 &&
        targetRelatedRow <= targetSection.rowCount
      ) {
        let relatedCell = null;

        if (targetSection.cells) {
          relatedCell = targetSection.cells.find(
            (cell) => cell.row === targetRelatedRow && cell.column === action.payload.column
          );
        }

        if (!relatedCell) {
          relatedCell = {
            id: ++identityCurrent,
            row: targetRelatedRow,
            column: action.payload.column,
          };
        }
        newSectionGroup = setSectionField(newSectionGroup, relatedSection, relatedCell, dataField);
      }

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent: identityCurrent,
        gridSectionGroup: newSectionGroup,
      };
    },

    mergeCells: (state) => {
      // Check for any merged cell areas
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      let mergeCells = true;
      let updateSectionGroup = { ...format.gridSectionGroup };

      for (const selected of state.selectedGridElements) {
        switch (selected.type) {
          case "cell":
            if (
              (!selected.spanRow || selected.spanRow === selected.row) &&
              (!selected.spanCol || selected.spanCol === selected.col)
            ) {
              continue;
            }
            const startRow = Math.min(selected.spanRow, selected.row);
            const startCol = Math.min(selected.spanCol, selected.col);

            const section = findSection(format.gridSectionGroup, selected.sectionId);

            if (!section.cells) {
              continue;
            }

            const thisObject = section.cells.find((cell) => cell.row === startRow && cell.column === startCol);

            if (!thisObject) {
              continue;
            }

            if ((thisObject.rowSpan && thisObject.rowSpan > 1) || (thisObject.colSpan && thisObject.colSpan > 1)) {
              mergeCells = false;
            }

            break;
          default:
        }
        if (!mergeCells) {
          break;
        }
      }

      for (const selected of state.selectedGridElements) {
        switch (selected.type) {
          case "cell":
            if (
              (!selected.spanRow || selected.spanRow === selected.row) &&
              (!selected.spanCol || selected.spanCol === selected.col)
            ) {
              continue;
            }
            // Get the minimum value of each
            const startRow = Math.min(selected.spanRow, selected.row);
            const endRow = Math.max(selected.spanRow, selected.row);
            const startCol = Math.min(selected.spanCol, selected.col);
            const endCol = Math.max(selected.spanCol, selected.col);

            const section = findSection(format.gridSectionGroup, selected.sectionId);

            let thisObject = null;
            if (section.cells) {
              thisObject = section.cells.find((cell) => cell.row === startRow && cell.column === startCol);
            }

            if (!mergeCells && !thisObject) {
              continue;
            }

            let updatedObject;
            if (mergeCells) {
              if (thisObject) {
                updatedObject = {
                  ...thisObject,
                  rowSpan: 1 + endRow - startRow,
                  colSpan: 1 + endCol - startCol,
                };
              } else {
                updatedObject = {
                  id: ++identityCurrent,
                  row: startRow,
                  column: startCol,
                  rowSpan: 1 + endRow - startRow,
                  colSpan: 1 + endCol - startCol,
                };
              }
            } else {
              updatedObject = { ...thisObject };
              delete updatedObject.rowSpan;
              delete updatedObject.colSpan;
            }

            let newSection = { ...section };
            if (thisObject) {
              newSection.cells = section.cells
                .map((cell) =>
                  cell.row === updatedObject.row && cell.column === updatedObject.column ? updatedObject : cell
                )
                .filter(
                  (cell) =>
                    cell.row < startRow ||
                    cell.row > endRow ||
                    cell.column < startCol ||
                    cell.column > endCol ||
                    (cell.row === startRow && cell.column === startCol)
                );
            } else if (newSection.cells) {
              newSection.cells = [...section.cells, updatedObject];
            } else {
              newSection.cells = [updatedObject];
            }

            updateSectionGroup = updateSection(updateSectionGroup, newSection);

            break;
          default:
        }
      }

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent: identityCurrent,
        gridSectionGroup: updateSectionGroup,
      };
    },

    clearProperties: (state) => {
      for (const selected of state.selectedGridElements) {
        const format = state.records[state.selectedRecordIndex].queryObject;

        switch (selected.type) {
          case "cell":
            {
              let startRow;
              let endRow;
              if (selected.spanRow) {
                startRow = Math.min(selected.row, selected.spanRow);
                endRow = Math.max(selected.row, selected.spanRow);
              } else {
                startRow = selected.row;
                endRow = selected.row;
              }
              let startCol;
              let endCol;
              if (selected.spanCol) {
                startCol = Math.min(selected.col, selected.spanCol);
                endCol = Math.max(selected.col, selected.spanCol);
              } else {
                startCol = selected.col;
                endCol = selected.col;
              }

              const thisSection = findSection(format.gridSectionGroup, selected.sectionId);

              let newSection = { ...thisSection };

              if (newSection.cells) {
                newSection.cells = newSection.cells.filter(
                  (cell) => cell.row < startRow || cell.row > endRow || cell.column < startCol || cell.column > endCol
                );
              }

              const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridSectionGroup: newSectionGroup,
              };
            }
            break;
          case "row":
            {
              const section = findSection(format.gridSectionGroup, selected.sectionId);

              let newSection = { ...section };

              let startRow;
              let endRow;
              if (selected.spanRow) {
                startRow = Math.min(selected.row, selected.spanRow);
                endRow = Math.max(selected.row, selected.spanRow);
              } else {
                startRow = selected.row;
                endRow = selected.row;
              }

              for (let row = startRow; row <= endRow; row++) {
                let thisObject = null;
                if (newSection.rows) {
                  thisObject = newSection.rows.find((testRow) => testRow.row === row);
                }

                if (!thisObject) {
                  continue;
                }

                let updatedObject = { ...thisObject };

                delete updatedObject.style;

                newSection.rows = newSection.rows.map((row) => (row.row === updatedObject.row ? updatedObject : row));
              }

              const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridSectionGroup: newSectionGroup,
              };
            }
            break;
          case "column":
            {
              let startCol;
              let endCol;
              if (selected.spanCol) {
                startCol = Math.min(selected.col, selected.spanCol);
                endCol = Math.max(selected.col, selected.spanCol);
              } else {
                startCol = selected.col;
                endCol = selected.col;
              }

              let updateColumns = [...format.gridColumns];

              for (let col = startCol; col <= endCol; col++) {
                const thisColumn = updateColumns.find((column) => column.column === col);

                let updateColumn = { ...thisColumn };

                delete updateColumn.style;

                updateColumns = updateColumns.map((column) =>
                  column.column === updateColumn.column ? updateColumn : column
                );

                state.records[state.selectedRecordIndex].queryObject = {
                  ...format,
                  gridColumns: updateColumns,
                };
              }
            }
            break;
          case "grid":
            {
              const newSectionGroup = {
                ...format.gridSectionGroup,
              };

              delete newSectionGroup.style;

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridSectionGroup: newSectionGroup,
              };
            }
            break;
          case "section":
            {
              const thisSection = findSection(format.gridSectionGroup, selected.sectionId);
              let newSection = { ...thisSection };

              delete newSection.style;

              const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridSectionGroup: newSectionGroup,
              };
            }
            break;
          default:
        }
      }
    },

    formatInsert: (state, action) => {
      if (validInsert(state.selectedGridElements)) {
        switch (getSelectedType(state.selectedGridElements)) {
          case "column":
            for (const selected of state.selectedGridElements) {
              const format = state.records[state.selectedRecordIndex].queryObject;
              let identityCurrent = format.identityCurrent;

              let startCol;
              let endCol;
              if (selected.spanCol) {
                startCol = Math.min(selected.col, selected.spanCol);
                endCol = Math.max(selected.col, selected.spanCol);
              } else {
                startCol = action.payload.isAfter ? selected.col + 1 : selected.col;
                endCol = startCol;
              }

              const insertColumns = endCol - startCol + 1;

              const updateColumns = format.gridColumns.map((column) => {
                if (column.column >= startCol) {
                  return { ...column, column: column.column + insertColumns };
                } else {
                  return column;
                }
              });

              for (let col = startCol; col <= endCol; ++col) {
                updateColumns.push({ id: ++identityCurrent, column: col, width: 100 });
              }

              updateColumns.sort((a, b) => a.column - b.column);

              const newFormatSectionGroup = shiftCellsLeft(format.gridSectionGroup, startCol, endCol, insertColumns);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                identityCurrent: identityCurrent,
                gridColumnCount: format.gridColumnCount + insertColumns,
                gridColumns: updateColumns,
                gridSectionGroup: newFormatSectionGroup,
              };
            }
            break;
          case "grid":
            // setShowSectionManager(true);
            break;
          case "row":
            for (const selected of state.selectedGridElements) {
              const format = state.records[state.selectedRecordIndex].queryObject;

              const section = findSection(format.gridSectionGroup, selected.sectionId);

              let newSection = section;

              let startRow;
              let endRow;
              if (selected.spanRow) {
                startRow = Math.min(selected.row, selected.spanRow);
                endRow = Math.max(selected.row, selected.spanRow);
              } else {
                startRow = action.payload.isAfter ? selected.row + 1 : selected.row;
                endRow = startRow;
              }

              const insertRows = endRow - startRow + 1;

              // Shift cells down
              if (newSection.cells) {
                newSection.cells = newSection.cells.map((cell) => {
                  let newCell = cell;

                  if (newCell.row >= startRow) {
                    newCell = { ...cell, row: cell.row + insertRows };
                  }

                  if (newCell.rowSpan && newCell.row + cell.rowSpan - 1 >= startRow && newCell.row <= endRow) {
                    newCell = {
                      ...newCell,
                      rowSpan: newCell.rowSpan + insertRows,
                    };
                  }

                  return newCell;
                });
              }

              if (newSection.rows) {
                newSection.rows = newSection.rows.map((row) => {
                  if (row.row >= startRow) {
                    return { ...row, row: row.row + insertRows };
                  } else {
                    return row;
                  }
                });
              }

              newSection.rowCount = newSection.rowCount + insertRows;

              const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

              state.records[state.selectedRecordIndex].queryObject = {
                ...format,
                gridSectionGroup: newSectionGroup,
              };
            }
            break;
          case "section":
            // handleAddSubsection();
            break;
          default:
        }
      }
    },

    formatInsertColumnSingleDirect: (state, action) => {
      const col = action.payload;
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      const updateColumns = format.gridColumns.map((column) => {
        if (column.column >= col) {
          return { ...column, column: column.column + 1 };
        } else {
          return column;
        }
      });

      updateColumns.push({ id: ++identityCurrent, column: col, width: 100 });
      updateColumns.sort((a, b) => a.column - b.column);

      const newFormatSectionGroup = shiftCellsLeft(format.gridSectionGroup, col, col, 1);

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent: identityCurrent,
        gridColumnCount: format.gridColumnCount + 1,
        gridColumns: updateColumns,
        gridSectionGroup: newFormatSectionGroup,
      };
    },
    // TODO: Hasn't yet been modified
    // formatInsertRowSingleDirect: (state, action) => {
    //         for (const selected of state.selectedGridElements) {
    //           const format = state.records[state.selectedRecordIndex].queryObject;

    //           const section = findSection(format.gridSectionGroup, selected.sectionId);

    //           let newSection = section;

    //           let startRow;
    //           let endRow;
    //           if (selected.spanRow) {
    //             startRow = Math.min(selected.row, selected.spanRow);
    //             endRow = Math.max(selected.row, selected.spanRow);
    //           } else {
    //             startRow = action.payload.isAfter ? selected.row + 1 : selected.row;
    //             endRow = startRow;
    //           }

    //           const insertRows = endRow - startRow + 1;

    //           // Shift cells down
    //           if (newSection.cells) {
    //             newSection.cells = newSection.cells.map((cell) => {
    //               let newCell = cell;

    //               if (newCell.row >= startRow) {
    //                 newCell = { ...cell, row: cell.row + insertRows };
    //               }

    //               if (newCell.rowSpan && newCell.row + cell.rowSpan - 1 >= startRow && newCell.row <= endRow) {
    //                 newCell = {
    //                   ...newCell,
    //                   rowSpan: newCell.rowSpan + insertRows,
    //                 };
    //               }

    //               return newCell;
    //             });
    //           }

    //           if (newSection.rows) {
    //             newSection.rows = newSection.rows.map((row) => {
    //               if (row.row >= startRow) {
    //                 return { ...row, row: row.row + insertRows };
    //               } else {
    //                 return row;
    //               }
    //             });
    //           }

    //           newSection.rowCount = newSection.rowCount + insertRows;

    //           const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

    //           state.records[state.selectedRecordIndex].queryObject = {
    //             ...format,
    //             gridSectionGroup: newSectionGroup,
    //           };
    //         }
    //         break;
    //       case "section":
    //         // handleAddSubsection();
    //         break;
    //       default:
    //     }
    //   }
    // },

    formatDelete: (state) => {
      if (validDelete(state.selectedGridElements)) {
        switch (getSelectedType(state.selectedGridElements)) {
          case "column":
            for (const selected of state.selectedGridElements) {
              const format = state.records[state.selectedRecordIndex].queryObject;

              switch (selected.type) {
                case "column":
                  {
                    let startCol;
                    let endCol;

                    // Calculate columns to be deleted
                    if (selected.spanCol) {
                      startCol = Math.min(selected.col, selected.spanCol);
                      endCol = Math.max(selected.col, selected.spanCol);
                    } else {
                      startCol = selected.col;
                      endCol = selected.col;
                    }

                    // Sanity check
                    if (format.gridColumnCount === 1 || startCol > format.gridColumnCount) {
                      continue;
                    }

                    if (endCol > format.gridColumnCount) {
                      endCol = format.gridColumnCount;
                    }

                    const deleteColumns = endCol - startCol + 1;

                    let updateColumns = [...format.gridColumns];

                    // Remove columns
                    updateColumns = updateColumns.filter(
                      (column) => column.column < startCol || column.column > endCol
                    );

                    // Set column numbers
                    updateColumns = updateColumns.map((column) =>
                      column.column > endCol ? { ...column, column: column.column - deleteColumns } : column
                    );

                    const newFormatSectionGroup = deleteColumnCells(
                      format.gridSectionGroup,
                      startCol,
                      endCol,
                      deleteColumns
                    );

                    state.records[state.selectedRecordIndex].queryObject = {
                      ...format,
                      gridColumnCount: format.gridColumnCount - deleteColumns,
                      gridColumns: updateColumns,
                      gridSectionGroup: newFormatSectionGroup,
                    };
                  }
                  break;
                default:
              }
            }

            break;
          case "grid":
            break;
          case "row":
            for (const selected of state.selectedGridElements) {
              const format = state.records[state.selectedRecordIndex].queryObject;

              switch (selected.type) {
                case "row":
                  {
                    const section = findSection(format.gridSectionGroup, selected.sectionId);

                    let newSection = section;

                    let startRow;
                    let endRow;
                    if (selected.spanRow) {
                      startRow = Math.min(selected.row, selected.spanRow);
                      endRow = Math.max(selected.row, selected.spanRow);
                    } else {
                      startRow = selected.row;
                      endRow = selected.row;
                    }

                    // Sanity check
                    if (newSection.rowCount === 1) {
                      continue;
                    }

                    const deleteRows = endRow - startRow + 1;

                    if (newSection.cells) {
                      newSection.cells = newSection.cells.filter((cell) => cell.row < startRow || cell.row > endRow);

                      newSection.cells = newSection.cells.map((cell) => {
                        let newCell = cell;

                        if (newCell.row > endRow) {
                          newCell = { ...newCell, row: newCell.row - deleteRows };
                        }

                        if (newCell.rowSpan && newCell.row + newCell.rowSpan - 1 >= startRow && newCell.row <= endRow) {
                          let maxRowSpanReduce = newCell.rowSpan - Math.max(startRow - newCell.row, 0);
                          let reduceSpan = Math.min(deleteRows, maxRowSpanReduce);

                          if (newCell.rowSpan - reduceSpan <= 1) {
                            newCell = { ...newCell };
                            delete newCell.rowSpan;
                          } else {
                            newCell = {
                              ...newCell,
                              rowSpan: newCell.rowSpan - reduceSpan,
                            };
                          }
                        }

                        return newCell;
                      });
                    }

                    if (newSection.rows) {
                      newSection.rows = newSection.rows.filter((row) => row.row < startRow || row.row > endRow);

                      newSection.rows = newSection.rows.map((row) =>
                        row.row > endRow ? { ...row, row: row.row - deleteRows } : row
                      );
                    }

                    newSection.rowCount = newSection.rowCount - deleteRows;

                    const newSectionGroup = updateSection(format.gridSectionGroup, newSection);

                    state.records[state.selectedRecordIndex].queryObject = {
                      ...format,
                      gridSectionGroup: newSectionGroup,
                    };
                  }
                  break;
                default:
              }
            }

            break;
          case "section":
            for (const selected of state.selectedGridElements) {
              const format = state.records[state.selectedRecordIndex].queryObject;

              switch (selected.type) {
                case "section":
                  const newSectionGroup = deleteSection(format.gridSectionGroup, selected.sectionId);

                  state.records[state.selectedRecordIndex].queryObject = {
                    ...format,
                    gridSectionGroup: newSectionGroup,
                  };
                  break;
                default:
              }
            }
            break;
          default:
        }

        // clearInvalidSelections(setSelected, setFormatObject);
        const newSelected = [];
        const format = state.records[state.selectedRecordIndex].queryObject;

        for (const prevSelected of state.selectedGridElements) {
          let valid = true;
          switch (prevSelected.type) {
            case "cell":
              {
                const section = findSection(format.gridSectionGroup, prevSelected.sectionId);
                if (!section) {
                  valid = false;
                } else {
                  let endRow;
                  if (prevSelected.spanRow) {
                    endRow = Math.max(prevSelected.row, prevSelected.spanRow);
                  } else {
                    endRow = prevSelected.row;
                  }

                  if (endRow > section.rowCount) {
                    valid = false;
                  } else {
                    let endCol;
                    if (prevSelected.spanCol) {
                      endCol = Math.max(prevSelected.col, prevSelected.spanCol);
                    } else {
                      endCol = prevSelected.col;
                    }
                    if (endCol > format.gridColumnCount) {
                      valid = false;
                    }
                  }
                }
              }
              break;
            case "column":
              {
                let endCol;
                if (prevSelected.spanCol) {
                  endCol = Math.max(prevSelected.col, prevSelected.spanCol);
                } else {
                  endCol = prevSelected.col;
                }
                if (endCol > format.gridColumnCount) {
                  valid = false;
                }
              }
              break;
            case "row":
              {
                const section = findSection(format.gridSectionGroup, prevSelected.sectionId);
                if (!section) {
                  valid = false;
                } else {
                  let endRow;
                  if (prevSelected.spanRow) {
                    endRow = Math.max(prevSelected.row, prevSelected.spanRow);
                  } else {
                    endRow = prevSelected.row;
                  }
                  if (endRow > section.rowCount) {
                    valid = false;
                  }
                }
              }
              break;
            case "section":
              {
                const section = findSection(format.gridSectionGroup, prevSelected.sectionId);
                if (!section) {
                  valid = false;
                }
              }
              break;
            default:
          }

          if (valid) {
            newSelected.push(prevSelected);
          }
        }

        state.selectedGridElements = newSelected;
      }
    },

    formatInsertSection: (state, action) => {
      if (state.selectedGridElements.length !== 1 || state.selectedGridElements[0].type !== "grid") {
        return;
      }

      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;
      const newSections = [...format.gridSectionGroup.sections];

      newSections.push({
        id: ++identityCurrent,
        sectionTypeId: action.payload.sectionTypeId,
        rowCount: 1,
        sectionGroups: [],
        cells: [],
      });

      newSections.sort((a, b) => a.sectionTypeId - b.sectionTypeId);

      const newSectionGroup = {
        ...format.gridSectionGroup,
        sections: newSections,
      };

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent: identityCurrent,
        gridSectionGroup: newSectionGroup,
      };
    },
    formatInsertSubsection: (state, action) => {
      if (state.selectedGridElements.length !== 1 || state.selectedGridElements[0].type !== "section") {
        return;
      }

      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      const lineSection = format.gridSectionGroup.sections.find(
        (section) => section.sectionTypeId === SectionTypeEnum.LINE
      );

      if (!lineSection) {
        return;
      }

      let newFormatsectionGroups;

      if (lineSection.sectionGroups) {
        newFormatsectionGroups = [...lineSection.sectionGroups];
      } else {
        newFormatsectionGroups = [];
      }

      const newSubsection = {
        id: ++identityCurrent,
        dataSourceName: action.payload.dataSourceName,
        sections: [
          {
            id: ++identityCurrent,
            sectionTypeId: SectionTypeEnum.REPORT_HEADER,
            rowCount: 1,
            cells: [],
          },
          {
            id: ++identityCurrent,
            sectionTypeId: SectionTypeEnum.LINE,
            rowCount: 1,
            cells: [],
          },
        ],
      };

      newFormatsectionGroups.push(newSubsection);

      const newlineSection = {
        ...lineSection,
        sectionGroups: newFormatsectionGroups,
      };

      const sections = format.gridSectionGroup.sections.map((section) =>
        section.sectionTypeId === SectionTypeEnum.LINE ? newlineSection : section
      );

      const newSectionGroup = {
        ...format.gridSectionGroup,
        sections: sections,
      };

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent: identityCurrent,
        gridSectionGroup: newSectionGroup,
      };
    },

    // Selected format cells
    setGridSelected: (state, action) => {
      if (action.payload.ctrlKey) {
        state.selectedGridElements = isGridSelected(state.selectedGridElements)
          ? state.selectedGridElements.filter((item) => item.type !== "grid")
          : [...state.selectedGridElements, { type: "grid" }];
      } else {
        state.selectedGridElements = isGridSelected(state.selectedGridElements) ? [] : [{ type: "grid" }];
      }
    },
    setGridColumnSelected: (state, action) => {
      if (action.payload.shiftKey) {
        // Get the last selected item
        let lastItem = null;
        if (state.selectedGridElements.length > 0) {
          lastItem = state.selectedGridElements[state.selectedGridElements.length - 1];
        }
        if (lastItem?.type === "column") {
          // Change the span items of the last item
          const updatedItem = {
            ...lastItem,
            spanCol: action.payload.column,
          };
          const newValues = [...state.selectedGridElements];
          newValues.pop();
          newValues.push(updatedItem);
          state.selectedGridElements = newValues;
        } else if (!isColumnSelected(state.selectedGridElements, action.payload.column)) {
          state.selectedGridElements = [
            ...state.selectedGridElements,
            {
              type: "column",
              col: action.payload.column,
            },
          ];
        } else {
          // Not change
        }
      } else if (action.payload.ctrlKey) {
        state.selectedGridElements = isColumnSelected(state.selectedGridElements, action.payload.column)
          ? state.selectedGridElements.filter((item) => item.type !== "column" || item.column !== action.payload.column)
          : [...state.selectedGridElements, { type: "column", col: action.payload.column }];
      } else {
        state.selectedGridElements = isColumnSelected(state.selectedGridElements, action.payload.column)
          ? []
          : [{ type: "column", col: action.payload.column }];
      }
    },
    setGridSectionSelected: (state, action) => {
      const order = action.payload.sectionGroup?.order ? action.payload.sectionGroup.order : 0;

      if (action.payload.ctrlKey) {
        state.selectedGridElements = isSectionSelected(state.selectedGridElements, action.payload.section.id, order)
          ? state.selectedGridElements.filter(
              (item) =>
                item.type !== "section" ||
                !idsAreEqual(item.sectionId, action.payload.section.id) ||
                item.order !== order
            )
          : [
              ...state.selectedGridElements,
              {
                type: "section",
                sectionId: action.payload.section.id,
                order: order,
              },
            ];
      } else {
        state.selectedGridElements = isSectionSelected(state.selectedGridElements, action.payload.section.id, order)
          ? []
          : [
              {
                type: "section",
                sectionId: action.payload.section.id,
                order: order,
              },
            ];
      }
    },
    setGridSectionRowSelected: (state, action) => {
      if (action.payload.shiftKey) {
        // Get the last selected item
        let lastItem = null;
        if (state.selectedGridElements.length > 0) {
          lastItem = state.selectedGridElements[state.selectedGridElements.length - 1];
        }
        if (lastItem?.type === "row" && lastItem.sectionId === action.payload.section.id) {
          // Change the span items of the last item
          const updatedItem = {
            ...lastItem,
            spanRow: action.payload.row.row,
          };
          const newValues = [...state.selectedGridElements];
          newValues.pop();
          newValues.push(updatedItem);
          state.selectedGridElements = newValues;
        } else if (!isRowSelected(state.selectedGridElements, action.payload.section.id, action.payload.row.row)) {
          state.selectedGridElements = [
            ...state.selectedGridElements,
            {
              type: "row",
              sectionId: action.payload.section.id,
              row: action.payload.row.row,
            },
          ];
        } else {
          // No change
        }
      } else if (action.payload.ctrlKey) {
        state.selectedGridElements = isRowSelected(
          state.selectedGridElements,
          action.payload.section.id,
          action.payload.row.row
        )
          ? state.selectedGridElements.filter(
              (item) =>
                item.type !== "row" ||
                item.sectionId !== action.payload.section.id ||
                item.row !== action.payload.row.row
            )
          : [
              ...state.selectedGridElements,
              {
                type: "row",
                sectionId: action.payload.section.id,
                row: action.payload.row.row,
              },
            ];
      } else {
        state.selectedGridElements = isRowSelected(
          state.selectedGridElements,
          action.payload.section.id,
          action.payload.row.row
        )
          ? []
          : [
              {
                type: "row",
                sectionId: action.payload.section.id,
                row: action.payload.row.row,
              },
            ];
      }
    },
    setGridSectionCellSelected: (state, action) => {
      const isSelected = isCellSelected(
        state.selectedGridElements,
        action.payload.section.id,
        action.payload.cell.row,
        action.payload.cell.column
      );

      if (action.payload.shiftKey) {
        // Get the last selected item
        let lastItem = null;
        if (state.selectedGridElements.length > 0) {
          lastItem = state.selectedGridElements[state.selectedGridElements.length - 1];
        }
        if (lastItem?.type === "cell" && lastItem.sectionId === action.payload.section.id) {
          // Change the span items of the last item
          const updatedItem = {
            ...lastItem,
            spanRow: action.payload.cell.row,
            spanCol: action.payload.cell.column,
          };
          const newValues = [...state.selectedGridElements];
          newValues.pop();
          newValues.push(updatedItem);
          state.selectedGridElements = newValues;
        } else if (!isSelected) {
          return [
            ...state.selectedGridElements,
            {
              type: "cell",
              sectionId: action.payload.section.id,
              row: action.payload.cell.row,
              col: action.payload.cell.column,
            },
          ];
        } else {
          // No Change
        }
      } else if (action.payload.ctrlKey) {
        state.selectedGridElements = isSelected
          ? state.selectedGridElements.filter(
              (item) =>
                item.type !== "cell" ||
                item.sectionId !== action.payload.section.id ||
                item.row !== action.payload.cell.row ||
                item.col !== action.payload.cell.column
            )
          : [
              ...state.selectedGridElements,
              {
                type: "cell",
                sectionId: action.payload.section.id,
                row: action.payload.cell.row,
                col: action.payload.cell.column,
              },
            ];
      } else {
        if (isSelected) {
          state.selectedGridElements = [];
        } else {
          const newSelection = {
            type: "cell",
            sectionId: action.payload.section.id,
            row: action.payload.cell.row,
            col: action.payload.cell.column,
          };

          if (action.payload.cell.rowSpan) {
            newSelection.spanRow = action.payload.cell.row + action.payload.cell.rowSpan - 1;
          }

          if (action.payload.cell.colSpan) {
            newSelection.spanCol = action.payload.cell.column + action.payload.cell.colSpan - 1;
          }

          state.selectedGridElements = [newSelection];
        }
      }
    },

    // Pivot fields
    addPivotField: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      const newValue = {
        id: ++identityCurrent,
        fieldName: action.payload.fieldName,
      };

      if (action.payload.type === "data") {
        newValue.function = "Count";
      }

      let property = "";

      switch (action.payload.type) {
        case "data":
          property = "dataFields";
          break;
        case "column":
          property = "columnFields";
          break;
        case "row":
          property = "rowFields";
          break;
        default:
          // Not recognised
          return;
      }

      const fields = [...format.formatPivot[property], newValue];

      const formatPivot = {
        ...format.formatPivot,
        [property]: fields,
      };

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent: identityCurrent,
        formatPivot: formatPivot,
      };
    },
    removePivotField: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;

      let property = "";

      switch (action.payload.type) {
        case "data":
          property = "dataFields";
          break;
        case "column":
          property = "columnFields";
          break;
        case "row":
          property = "rowFields";
          break;
        default:
          // Not recognised
          return;
      }

      const fields = filterOutOnId(format.formatPivot[property], action.payload.id);

      const formatPivot = {
        ...format.formatPivot,
        [property]: fields,
      };

      state.records[state.selectedRecordIndex].queryObject = { ...format, formatPivot: formatPivot };
    },

    // List fields
    addListField: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      const newValue = {
        id: ++identityCurrent,
        dataFieldName: action.payload.fieldName,
      };

      const toIndex = action.payload.toIndex;

      let listColumns;
      if (toIndex >= format.listColumns.length) {
        listColumns = [...format.listColumns, newValue];
      } else if (toIndex <= 0) {
        listColumns = [newValue, ...format.listColumns];
      } else {
        listColumns = format.listColumns.toSpliced(toIndex, 0, newValue);
      }

      const maxEntries = action.payload.maxEntries;
      if (maxEntries && listColumns.length > maxEntries) {
        listColumns.length = maxEntries;
      }

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent,
        listColumns,
      };
    },
    removeListField: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      const listColumns = filterOutOnId(format.listColumns, action.payload.id);

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        listColumns,
      };
    },
    moveListField: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      const listColumns = [...format.listColumns];
      const fromIndex = Math.max(0, Math.min(action.payload.fromIndex, listColumns.length - 1));
      const toIndex = Math.max(0, Math.min(action.payload.toIndex, listColumns.length - 1));

      // Sanity check indexes
      if (toIndex === fromIndex) {
        return;
      }

      const [movedItem] = listColumns.splice(fromIndex, 1);
      listColumns.splice(toIndex, 0, movedItem);

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        listColumns,
      };
    },

    // Mail merge
    updateMailMergeHtml: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      const formatMailMerge = format.formatMailMerge;

      formatMailMerge.messageBodyHtml = action.payload.html;

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        formatMailMerge: { ...formatMailMerge },
      };
    },

    setMailMergeProperty: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      const formatMailMerge = format.formatMailMerge;

      formatMailMerge[action.payload.fieldName] = action.payload.value;

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        formatMailMerge: { ...formatMailMerge },
      };
    },

    addMailMergeObject: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;

      let identityCurrent = format.identityCurrent;

      const newObject = {
        id: ++identityCurrent,
        mailMergeFormatObjectType: action.payload.mailMergeFormatObjectType,
        dataSourceName: action.payload.dataSourceName,
        name: action.payload.name,
      };

      switch (action.payload.mailMergeFormatObjectType) {
        case MailMergeObjectTypes.TABLE:
          newObject.tableColumns = [];
          break;
        default:
          newObject.tableColumns = null;
          break;
      }

      const objects = [...(format.formatMailMerge?.objects ?? []), newObject];

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent: identityCurrent,
        formatMailMerge: {
          ...format.formatMailMerge,
          objects,
        },
      };
    },

    deleteMailMergeObject: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      const formatMailMerge = format.formatMailMerge;
      const objects = formatMailMerge.objects ?? [];
      const newObjects = objects.filter((object) => object.id !== action.payload.id);
      formatMailMerge.objects = newObjects;
      // Remove the object from the html
      formatMailMerge.messageBodyHtml = formatMailMerge.messageBodyHtml.replace(
        `{{__objectId__${action.payload.id}}}`,
        ""
      );
    },

    updateMailMergeObjectName: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      const objects = format.formatMailMerge.objects ?? [];
      const selectedObject = objects.find((object) => object.id === action.payload.id);
      selectedObject.name = action.payload.name;
    },

    // Mail merge table object columns
    addMailMergeTableObjectColumn: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      const newValue = {
        id: ++identityCurrent,
        dataFieldName: action.payload.fieldName,
      };

      const objectId = action.payload.objectId;
      const toIndex = action.payload.toIndex;

      const objects = format.formatMailMerge.objects ?? [];
      const selectedObject = objects.find((object) => object.id === objectId);

      if (toIndex >= selectedObject.tableColumns.length) {
        selectedObject.tableColumns = [...selectedObject.tableColumns, newValue];
      } else if (toIndex <= 0) {
        selectedObject.tableColumns = [newValue, ...selectedObject.tableColumns];
      } else {
        selectedObject.tableColumns = selectedObject.tableColumns.toSpliced(toIndex, 0, newValue);
      }

      format.formatMailMerge.objects = objects.map((object) =>
        object.id === objectId ? { ...selectedObject } : object
      );

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent,
        formatMailMerge: { ...format.formatMailMerge },
      };
    },
    removeMailMergeTableObjectColumn: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      const objects = format.formatMailMerge.objects ?? [];

      const objectId = action.payload.objectId;

      const selectedObject = objects.find((object) => object.id === objectId);

      selectedObject.tableColumns = filterOutOnId(selectedObject.tableColumns, action.payload.id);

      format.formatMailMerge.objects = objects.map((object) =>
        object.id === objectId ? { ...selectedObject } : object
      );

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        formatMailMerge: { ...format.formatMailMerge },
      };
    },
    moveMailMergeTableObjectColumn: (state, action) => {
      const format = state.records[state.selectedRecordIndex].queryObject;
      let identityCurrent = format.identityCurrent;

      const objectId = action.payload.objectId;

      const objects = format.formatMailMerge.objects ?? [];
      const selectedObject = objects.find((object) => object.id === objectId);

      const fromIndex = Math.max(0, Math.min(action.payload.fromIndex, selectedObject.tableColumns.length - 1));
      const toIndex = Math.max(0, Math.min(action.payload.toIndex, selectedObject.tableColumns.length - 1));

      if (toIndex === fromIndex) {
        return;
      }

      const [movedItem] = selectedObject.tableColumns.splice(fromIndex, 1);
      selectedObject.tableColumns.splice(toIndex, 0, movedItem);

      format.formatMailMerge.objects = objects.map((object) =>
        object.id === objectId ? { ...selectedObject } : object
      );

      state.records[state.selectedRecordIndex].queryObject = {
        ...format,
        identityCurrent,
        formatMailMerge: { ...format.formatMailMerge },
      };
    },
  },
});

export const {
  addRecord,
  updateRecord,
  deleteRecord,
  addAllRecords,
  updateOwnership,
  updateSimpleFieldValue,
  setSortOrderProperty,
  addSortOrderProperty,
  removeSortOrderProperty,
  setBorderProperty,
  setFormatProperty,
  setGridColumnCount,
  setColumnWidth,
  setSectionRowCount,
  setSectionStripe,
  setSectionRowHeight,
  setSectionCellText,
  setSectionCellTextDirect,
  setSectionCellDataProperty,
  setSectionCellField,
  mergeCells,
  unMergeCells,
  clearProperties,
  formatInsert,
  formatInsertColumnSingleDirect,
  formatInsertSection,
  formatInsertSubsection,
  formatDelete,
  setGridSelected,
  setGridColumnSelected,
  setGridSectionSelected,
  setGridSectionRowSelected,
  setGridSectionCellSelected,
  addPivotField,
  removePivotField,
  addListField,
  removeListField,
  moveListField,
  deleteMailMergeObject,
  updateMailMergeObjectName,
  updateMailMergeHtml,
  setMailMergeProperty,
  addMailMergeObject,
  addMailMergeTableObjectColumn,
  removeMailMergeTableObjectColumn,
  moveMailMergeTableObjectColumn,
} = reportSlice.actions;

export default reportSlice.reducer;
