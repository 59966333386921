import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { setMailMergeProperty } from "redux/reportSlice";
import { getDisplayNameForFieldName, getDataFieldsBySubType } from "utilities/datafield";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";

function Properties({ format, matterTypeIds }) {
  const dispatch = useDispatch();
  const [emailDataFields, setEmailDataFields] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    const sortDataFields = getDataFieldsBySubType("email");

    setEmailDataFields(
      sortDataFields.map((dataField) => ({
        id: dataField.name,
        displayValue: getDisplayNameForFieldName(dataField.name, matterTypeIds),
      }))
    );
  }, [matterTypeIds]);

  const handleSelectChange = (fieldName, value) => {
    dispatch(
      setMailMergeProperty({
        fieldName,
        value,
      })
    );
  };

  return (
    <div className="mail-merge-container__properties">
      <div className="properties__title">
        <div className="data-panel__caret clickable" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <FaCaretDown /> : <FaCaretRight />}
        </div>
        <div>{t("Properties")}</div>
      </div>
      {isExpanded && (
        <div className="properties__body">
          {emailDataFields.length > 0 && (
            <>
              <div className="mail-merge-container__label">{t("Target email address")}</div>
              <div className="mail-merge-container__input">
                <SuperSelect
                  id="emailAddressField"
                  selectedOptionId={format.formatMailMerge.emailAddressField}
                  options={emailDataFields}
                  onChange={(optionId) => handleSelectChange("emailAddressField", optionId)}
                />
              </div>
            </>
          )}
          <div className="mail-merge-container__label">{t("Email subject")}</div>
          <div className="mail-merge-container__input">
            <input
              id="emailSubject"
              value={format.formatMailMerge.emailSubject}
              onChange={(e) => handleSelectChange("emailSubject", e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

Properties.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default Properties;
