import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaUpload } from "react-icons/fa";
import PropTypes from "prop-types";
import { t } from "locale/dictionary";
import { stringArrayBufferToBase64 } from "utilities/stringAndArray";
import { useDispatch, useSelector } from "react-redux";
import { saveDocumentData, saveImageData } from "redux/batchUpdateSlice";

let tempIdCounter = 1;

function BatchUpdateFileUploader({ isImage, isHeaderControl, children, onAddNew }) {
  const batchUpdateImages = useSelector((state) => state.batchUpdate).imageInserts;
  const [isDragReject, setIsDragReject] = useState(false);
  const lookupState = useSelector((state) => state.lookup);

  const dispatch = useDispatch();

  const [numExistingImages, setNumExistingImages] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles) => {
      let newSortIndex = numExistingImages;
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const base64String = stringArrayBufferToBase64(reader.result);
          if (isImage) {
            newSortIndex++;
            dispatch(
              saveImageData({
                imageId: tempIdCounter++,
                fileName: file.name,
                description: file.name,
                base64: base64String,
                sortIndex: newSortIndex,
              })
            );
          } else
            dispatch(
              saveDocumentData({
                documentId: tempIdCounter++,
                fileName: file.name,
                description: file.name,
                base64: base64String,
              })
            );
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [numExistingImages]
  );

  useEffect(() => {
    if (batchUpdateImages) setNumExistingImages(batchUpdateImages.length);
  }, [batchUpdateImages]);

  // Map custom identifiers to MIME types
  const mimeTypes = {
    aiff: "audio/aiff",
    arch: "application/zip",
    avchd: "video/avchd",
    avi: "video/x-msvideo",
    bmp: "image/bmp",
    doc: "application/msword",
    email: "message/rfc822",
    gif: "image/gif",
    heiff: "image/heif",
    jpeg: "image/jpeg",
    mpeg: "video/mpeg",
    ogg: "audio/ogg",
    pdf: "application/pdf",
    png: "image/png",
    pres: "application/vnd.ms-powerpoint",
    qtff: "video/quicktime",
    rf64: "audio/rf64",
    spread: "application/vnd.ms-excel",
    tiff: "image/tiff",
    txt: "text/plain",
    wav: "audio/wav",
    webm: "video/webm",
    wmv: "video/x-ms-wmv",
  };

  const validFiles = lookupState?.global?.find((lookup) => lookup.name === "FileExtensions")?.lookup;
  let accept = {};
  validFiles?.forEach((validFile) => {
    const mimeType = mimeTypes[validFile.code];
    if (mimeType) {
      accept[mimeType] = validFile.extensions.map((extension) => `.${extension}`);
    }
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: isHeaderControl,
    accept,
    onDragEnter: () => setIsDragReject(false),
    onDragLeave: () => setIsDragReject(false),
    onDropRejected: () => setIsDragReject(true),
  });

  const outerClassNameBase = "file-uploader";
  let outerClassName = outerClassNameBase;

  if (isDragActive) outerClassName += ` ${outerClassNameBase}--is-dragging`;
  if (isDragReject) outerClassName += ` ${outerClassNameBase}--is-rejected`;

  return (
    <div className={outerClassName} {...getRootProps()}>
      <input {...getInputProps()} />

      <div className="flex-column-center">
        <button className="flex-row-center">
          {t("UPLOAD FILE(S)")}&nbsp; &nbsp;
          <FaUpload />
        </button>
        <p>{t("Or drop file(s) into this panel")}</p>
        {isDragReject && <p className="color-text-red">{t("File type not accepted")}</p>}
      </div>
    </div>
  );
}

BatchUpdateFileUploader.propTypes = {
  isImage: PropTypes.bool,
  isHeaderControl: PropTypes.bool,
  children: PropTypes.element,
  setSelectedIndexLast: PropTypes.func,
  onAddNew: PropTypes.func,
};

export default BatchUpdateFileUploader;
