import { Routes, Route } from "react-router";
import { useLocation } from "react-router-dom";
import Home from "components/content/home/Home";
import SearchResults from "components/content/search-results/SearchResults";
import MatterRecordContainer from "components/content/matter-record/Container";
import ReportEditor from "components/content/report-editor/ReportEditor";
import ReportMenu from "components/content/report-editor/ReportMenu";
import Customers from "./security/Customers";
import CustomerDetails from "./security/CustomerDetails";
import AccountDetails from "./security/AccountDetails";
import UserDetails from "./security/UserDetails";
import UserGroupDetails from "./security/UserGroupDetails";
import SecurityContainer from "./security/components/layout/SecurityContainer";
import Test from "testArea/Test";
import ImageStore from "./image-store/ImageStore";
import BatchCreateContainer from "./project/batch-create/BatchCreateContainer";
import BatchImportContainer from "./project/batch-import/BatchImportContainer";
import Configuration from "./configuration/Configuration";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setViewTypeSimpleSearch } from "redux/simpleSearchSlice";
import { setViewTypeSearchResults } from "redux/searchResultsSlice";
import { searchViewTypes } from "utilities/constants";
import ReportEditorContainer from "./report-editor/ReportEditorContainer";
import BatchUpdateContainer from "./batch-update/BatchUpdateContainer";

// Component that sorts out which page-specific content to display below the header
// This is determined by user selection via the header menu
// It creates the illusion of a multi-page website, when in fact it's just a single page
// application that is showing and hiding various components
export default function Content() {
  const location = useLocation(); // Get the current location
  const dispatch = useDispatch();

  //TODO: If the location has returned to the home page we need to set the viewType on simpleSearch to MAIN
  // This is to ensure that the search results are cleared when the user returns to the home page

  useEffect(() => {
    if (location.pathname === "/") {
      // Dispatch action to set the view type to MAIN
      //console.log("detected location set to home page. Setting view type to MAIN");
      dispatch(setViewTypeSimpleSearch(searchViewTypes.MAIN));
      dispatch(setViewTypeSearchResults(searchViewTypes.MAIN));
    }

    return () => {
      // Optional cleanup if necessary
    };
  }, [location, dispatch]); // Re-run effect when location or dispatch changes

  return (
    <div className="content">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="matter/:paramsRecordId" element={<MatterRecordContainer />} />
        <Route path="matter/:paramsRecordId/:section" element={<MatterRecordContainer />} />
        <Route path="/results/batch-update" element={<BatchUpdateContainer />} />
        <Route path="/results" exact element={<SearchResults />} />
        <Route path="/results/matter/:paramsRecordId" element={<MatterRecordContainer />} />
        <Route path="/results/matter/:paramsRecordId/:section" element={<MatterRecordContainer />} />
        <Route path="/results/reporteditor/:param" element={<ReportEditorContainer />} />
        <Route path="/reportmenu" exact element={<ReportMenu />} />
        <Route path="/reportmenu/:mode" element={<ReportMenu />} />
        <Route path="/reportmenu/reporteditor/:param" element={<ReportEditorContainer />} />
        <Route path="/imagestore" element={<ImageStore />} />
        <Route path="/batch-create" element={<BatchCreateContainer />} />
        <Route path="/batch-import" element={<BatchImportContainer />} />
        <Route path="/configuration" element={<Configuration />} />

        {/* SECURITY */}
        <Route element={<SecurityContainer />}>
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:customerId" element={<CustomerDetails />} />
          <Route path="/customers/:customerId/accounts/:accountId" element={<AccountDetails />} />
          {[
            "/customers/:customerId/users/:userId",
            "/customers/:customerId/accounts/:accountId/users/:userId",
            "/customers/:customerId/accounts/:accountId/usergroups/:userGroupId/users/:userId",
          ].map((path) => (
            <Route key={path} path={path} element={<UserDetails />} />
          ))}

          <Route
            path="/customers/:customerId/accounts/:accountId/usergroups/:userGroupId"
            element={<UserGroupDetails />}
          />
        </Route>

        <Route path="/test987123" element={<Test />} />
      </Routes>
    </div>
  );
}
