import { useDispatch, useSelector } from "react-redux";
import { FaChartPie, FaListAlt, FaMap, FaTh, FaMailBulk } from "react-icons/fa";
import { resultViewChange } from "redux/searchResultsSlice";
import PropTypes from "prop-types";
import { FormatTypes } from "utilities/constants";

// Component for the view type selector on the search results header
function ViewTypeSelector({ viewType }) {
  const searchResultsState = useSelector((state) => state.searchResults);
  const searchResults = searchResultsState?.currentSearch;
  const dispatch = useDispatch();

  const onResultIconClick = () => {
    if (viewType !== "Data" && searchResults?.selected && searchResults.selected.length === 0)
      alert("Please select one or more results to run report");
    else dispatch(resultViewChange(viewType));
  };

  let iconRender = <FaListAlt />;
  let displayText = "Data";

  switch (viewType) {
    case FormatTypes.GRID:
      iconRender = <FaTh />;
      displayText = "Report";
      break;
    case FormatTypes.MAP:
      iconRender = <FaMap />;
      displayText = "Map";
      break;
    case FormatTypes.PIVOT:
      iconRender = <FaChartPie />;
      displayText = "Graph";
      break;
    case FormatTypes.MAILMERGE:
      iconRender = <FaMailBulk />;
      displayText = "Mail Merge";
      break;
    default:
      break;
  }

  const iconClassBase = "header__result-type-icon";

  return (
    <div
      className={`${iconClassBase} ${searchResults?.resultViewType === viewType && `${iconClassBase}--selected`} `}
      onClick={() => onResultIconClick()}
    >
      {iconRender}
      <div className="header__result-type-icon-text">{displayText}</div>
    </div>
  );
}

ViewTypeSelector.propTypes = {
  viewType: PropTypes.number,
};

export default ViewTypeSelector;
