import { useDispatch, useSelector } from "react-redux";
import { FaEllipsisH, FaArrowRight, FaStar, FaRegStar } from "react-icons/fa";
import { GiBeveledStar } from "react-icons/gi";
import {
  addFilter,
  inputTextValueChange,
  fieldTextValueChange,
  labelSelectedChange,
  searchValueChange,
  setKeyboardFocusedSelection,
  setPopupWholeSection,
} from "redux/simpleSearchSlice";
import { showPartialMatchHighlight, anyWordStartsWith, idsAreEqual, hasMatchingId } from "utilities/stringAndArray";
import { labelTypes, simpleSearchKeyboardFocusedPopup } from "utilities/constants";
import PropTypes from "prop-types";
import { t } from "locale/dictionary";
import { useRef, useState, useEffect } from "react";
import { addToFavouriteFields, removeFromFavouriteFields } from "api/datafield";
import SuggestedFieldPopupContainer from "./SuggestedFieldPopupContainer";

function SuggestedFieldMatch({
  section,
  sectionDisplayName,
  field,
  value,
  isMyFilterFields,
  onEnterKeyPressDone,
  isEnterKeyPressed,
}) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const dispatch = useDispatch();

  const refSuggestedFieldMatch = useRef(null);
  const refPopupContainer = useRef(null);

  const fieldsMatchingName = simpleSearch.fieldsMatchingName;
  const fieldsMatchingValue = simpleSearch.fieldsMatchingValue;
  const fieldsQualifiedValues = simpleSearch.fieldsQualifiedValues;

  const fieldNameMatch = fieldsMatchingName?.find((f) => f.fieldName === field.fieldName);
  const fieldValueMatch = fieldsMatchingValue?.find((f) => f.fieldName === field.fieldName);
  const fieldQualifiedValuesMatch = fieldsQualifiedValues?.find((f) => f.dataFieldName === field.fieldName);

  const inputArray = simpleSearch.inputArray;

  const [isSelected, setIsSelected] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const isSelectedLocal =
      simpleSearch.labelSelected.section === section && simpleSearch.labelSelected.fieldName === field.fieldName;
    setIsSelected(isSelectedLocal);
  }, [simpleSearch.labelSelected, section, field.fieldName]);

  useEffect(() => {
    if (isSelected && isEnterKeyPressed) {
      handleMatchingValuesClick();
      onEnterKeyPressDone();
    }
  }, [isSelected, isEnterKeyPressed, onEnterKeyPressDone]);

  // Adjust the position of the popup to ensure it is always visible on the screen
  // Note the use of refs to get the DOM object positions and sizes
  // and advanced use of the ResizeObserver to detect changes in the size of the popup container after initial render
  useEffect(() => {
    const adjustPopupPosition = () => {
      if (isSelected && refPopupContainer.current && refSuggestedFieldMatch.current) {
        const rectContainer = refSuggestedFieldMatch.current.getBoundingClientRect();
        const popupRect = refPopupContainer.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        let top = rectContainer.top;

        if (top + popupRect.height > viewportHeight) {
          top = viewportHeight - popupRect.height - 10; // 10px margin from the bottom
        }

        setPopupPosition({ top, left: rectContainer.right });
      }
    };

    const observer = new ResizeObserver(() => {
      adjustPopupPosition();
    });

    if (refPopupContainer.current) {
      observer.observe(refPopupContainer.current);
    }

    adjustPopupPosition();

    return () => {
      if (refPopupContainer.current) {
        observer.unobserve(refPopupContainer.current);
      }
    };
  }, [isSelected]);

  let matchingLookups = null;
  let displayValue = "";
  let numValueMatches = 1;
  if (fieldValueMatch) {
    if (fieldValueMatch.lookupDisplayList) {
      matchingLookups =
        fieldValueMatch.lookupDisplayList &&
        fieldValueMatch.lookupDisplayList.filter((lookupItem) =>
          inputArray.some(
            (inputItem) =>
              (lookupItem.displayValue &&
                anyWordStartsWith(lookupItem.displayValue.toLowerCase(), inputItem.toLowerCase())) ||
              (lookupItem.optionalCodes &&
                anyWordStartsWith(lookupItem.optionalCodes.toLowerCase(), inputItem.toLowerCase()))
          )
        );
      numValueMatches = matchingLookups.length;
      displayValue =
        matchingLookups && numValueMatches > 1 ? `${numValueMatches} ${t("matches")}` : matchingLookups[0].displayValue;
    } else if (fieldQualifiedValuesMatch) {
      if (
        fieldQualifiedValuesMatch.qualifyType === "MATTER" ||
        fieldQualifiedValuesMatch.qualifyType === "MATTER_LINK"
      ) {
        matchingLookups = fieldQualifiedValuesMatch.valueMatches;
        numValueMatches = fieldQualifiedValuesMatch.valueMatches.reduce(
          (acc, valueMatch) => acc + valueMatch.matterTypes.reduce((acc, matterType) => acc + matterType.count, 0),
          0
        );
      } else {
        matchingLookups = fieldQualifiedValuesMatch.valueMatches;
        numValueMatches = matchingLookups.length;
      }
      displayValue =
        matchingLookups && numValueMatches > 1 ? `${numValueMatches} ${t("matches")}` : matchingLookups[0].name;
    }
  }

  const handleOuterDivClick = () => {
    if (!isSelected) {
      dispatch(labelSelectedChange({ section, fieldName: field.fieldName, clickSource: "Field" }));
      if (simpleSearch.isKeyboardFocusedSelection !== simpleSearchKeyboardFocusedPopup.MAIN)
        dispatch(setKeyboardFocusedSelection(simpleSearchKeyboardFocusedPopup.MAIN));
    }
    dispatch(setPopupWholeSection(null));
  };

  const handleMatchingValuesClick = (e) => {
    e?.stopPropagation(); // Prevent the outer div from being clicked and messing up the setting of focus back to the main search input
    if (!fieldValueMatch) return;

    if (fieldValueMatch.lookupDisplayList) {
      let lookupDisplayList = [...fieldValueMatch.lookupDisplayList];
      const matchingOptions = lookupDisplayList.filter((lookup) => hasMatchingId(matchingLookups, lookup.id));
      lookupDisplayList = [
        ...matchingOptions,
        ...lookupDisplayList.filter((lookup) => !hasMatchingId(matchingLookups, lookup.id)),
      ];
      const matchingArray = lookupDisplayList.filter((lookup) => hasMatchingId(matchingLookups, lookup.id));
      const valueArray = matchingArray.map((mlu) => mlu.id);

      dispatch(
        addFilter({
          fieldName: field.fieldName,
          operator: "=",
          valueArray,
          qualified: false,
        })
      );
    } else if (fieldQualifiedValuesMatch) {
      const matterSearch =
        fieldQualifiedValuesMatch.qualifyType === "MATTER" || fieldQualifiedValuesMatch.qualifyType === "MATTER_LINK";
      const qualifiedDictionary = fieldQualifiedValuesMatch ? [...fieldQualifiedValuesMatch.valueMatches] : [];
      const valueArray = fieldQualifiedValuesMatch.valueMatches?.map((item) => (matterSearch ? item.name : item.id));

      dispatch(
        addFilter({
          fieldName: field.fieldName,
          operator: "=",
          valueArray,
          qualified: true,
          qualifiedDictionary,
        })
      );
    }

    dispatch(labelSelectedChange(false));
    dispatch(searchValueChange({ text: "", locale: "", fieldsQualifiedValues: [] }));
    dispatch(inputTextValueChange(""));
    dispatch(fieldTextValueChange(""));
    dispatch(setKeyboardFocusedSelection(simpleSearchKeyboardFocusedPopup.SEARCH_PANEL));
    document.getElementsByClassName("simple-search__input")[0].focus();
    //dispatch(setSavedSearchLoadedId(null));
  };

  const handleAddToFavourites = () => {
    addToFavouriteFields(field.fieldName);
  };

  const handleRemoveFromFavourites = () => {
    removeFromFavouriteFields(field.fieldName);
  };

  // TODO: Remove this variable to allow for multiple filters on the same field
  const isAlreadyFilter =
    !isMyFilterFields && simpleSearch.filters.find((filter) => filter.fieldName === field.fieldName);

  if (value && isAlreadyFilter && field.dataType === labelTypes.STRING) {
    dispatch(fieldTextValueChange(value));
  }

  const isQualified = field.qualifyType;

  const classNameOuterBase = "simple-search__suggested-field";
  let classNameOuter = classNameOuterBase;
  if (fieldNameMatch || fieldValueMatch) classNameOuter += ` ${classNameOuterBase}--matching`;
  if (isSelected) classNameOuter += ` ${classNameOuterBase}--selected`;

  const classNameFieldBase = "suggested-field__name";
  let classNameField = classNameFieldBase;
  if (fieldNameMatch) classNameField += ` ${classNameFieldBase}--match`;

  const classNameValueBase = "suggested-field__value";
  let classNameValue = classNameValueBase;
  if (fieldValueMatch) classNameValue += ` ${classNameValueBase}--match`;
  else if (field.lookupDisplayList?.length > 0) classNameValue += ` ${classNameValueBase}--no-match`;

  const matchingNameRenderArray = showPartialMatchHighlight(field.displayName, inputArray);
  const matchingValueRenderArray =
    numValueMatches > 1 ? ["", displayValue, ""] : showPartialMatchHighlight(displayValue, inputArray);

  if (isSelected) {
    refSuggestedFieldMatch?.current?.scrollIntoViewIfNeeded({ behaviour: "smooth", block: "nearest" });
  }

  return (
    !isAlreadyFilter && (
      <div className="simple-search__suggested-field-container">
        {section === "TopDataFields" ? (
          <div
            className="simple-search__fav-icon simple-search__fav-icon--favourite"
            onClick={handleRemoveFromFavourites}
          >
            <FaStar />
          </div>
        ) : (
          <div className="simple-search__fav-icon" onClick={handleAddToFavourites}>
            <FaRegStar />
          </div>
        )}
        <div className={classNameOuter} onClick={handleOuterDivClick} ref={refSuggestedFieldMatch}>
          <div>
            <span>
              {matchingNameRenderArray[0]}
              <span className={classNameField}>{matchingNameRenderArray[1]}</span>
              {matchingNameRenderArray[2]}
            </span>
            {isQualified && (
              <span className="suggested-field__icon-qualified">
                <GiBeveledStar />
              </span>
            )}
            {fieldValueMatch && (
              <span>
                {":  "}
                {matchingValueRenderArray[0]}
                <span className={classNameValue} onClick={handleMatchingValuesClick}>
                  {matchingValueRenderArray[1]}
                </span>
                {matchingValueRenderArray[2]}
              </span>
            )}
          </div>
          {isSelected ? (
            <>
              <div className="simple-search__arrow">
                <FaArrowRight />
              </div>
              <div
                className="suggested-field__popup-container"
                ref={refPopupContainer}
                style={{ top: popupPosition.top, left: popupPosition.left, position: "fixed" }}
              >
                <SuggestedFieldPopupContainer
                  fields={[field]}
                  sectionDisplayName={sectionDisplayName}
                  parent="Field"
                  isFieldNameMatch={matchingNameRenderArray[1].length > 0}
                />
              </div>
            </>
          ) : (
            <div className="simple-search__ellipsis">
              <FaEllipsisH />
            </div>
          )}
        </div>
      </div>
    )
  );
}

SuggestedFieldMatch.propTypes = {
  sectionDisplayName: PropTypes.string,
  field: PropTypes.object,
  value: PropTypes.string,
  isMyFilterFields: PropTypes.bool,
  onEnterKeyPressDone: PropTypes.func,
  isEnterKeyPressed: PropTypes.bool,
};

export default SuggestedFieldMatch;
