import store from "redux/store";
import { matterTypes, permissionTypes } from "./constants";
import { getAllLookupValuesForSource } from "./lookup";

export function getMatterRecordPermissions(matterTypeId, accountId) {
  if (matterTypeId === matterTypes.COMPANY) return getCompanyRecordPermissions();
  const state = store.getState();
  const accountPermissions = state.app.permissions;
  const accountPermission = accountPermissions.find((acc) => acc.accountId === accountId);

  if (!accountPermission) return permissionTypes.NONE;

  const exception = accountPermission.exceptions?.find((ex) => ex.matterTypeId === matterTypeId);
  return exception ? exception.crudPermissions : accountPermission.crudPermissions;
}

export function getSectionPermissions(tableName, matterTypeId, accountId) {
  const matterObjects = getAllLookupValuesForSource("MatterObjects");
  const sectionObject = matterObjects.find((mo) => mo.objectName === tableName);
  // For companies, we get the most powerful CRUD permission for the section/object type across all accounts
  if (matterTypeId === matterTypes.COMPANY) return getCompanySectionPermissions(sectionObject);
  if (!sectionObject) return permissionTypes.NONE;
  return getMatterObjectPermissions(matterTypeId, sectionObject.id, accountId);
}

export function canCreateMatterRecord(matterTypeId) {
  return hasAnyAccountAccessToMatterType(matterTypeId, permissionTypes.CREATE);
}

export function canReadMatterRecord(matterTypeId) {
  return hasAnyAccountAccessToMatterType(matterTypeId, permissionTypes.READ);
}

export function canUpdateMatterRecord(matterTypeId, accountId) {
  return canAccessMatterRecord(matterTypeId, accountId, permissionTypes.UPDATE);
}

export function canDeleteMatterRecord(matterTypeId, accountId) {
  return canAccessMatterRecord(matterTypeId, accountId, permissionTypes.DELETE);
}

export function canCreateMatterObject(matterTypeId, matterObjectId, accountId) {
  return canAccessMatterObject(matterTypeId, matterObjectId, accountId, permissionTypes.CREATE);
}

export function canReadMatterObject(matterTypeId, matterObjectId, accountId) {
  return canAccessMatterObject(matterTypeId, matterObjectId, accountId, permissionTypes.READ);
}

export function canUpdateMatterObject(matterTypeId, matterObjectId, accountId) {
  return canAccessMatterObject(matterTypeId, matterObjectId, accountId, permissionTypes.UPDATE);
}

export function canDeleteMatterObject(matterTypeId, matterObjectId, accountId) {
  return canAccessMatterObject(matterTypeId, matterObjectId, accountId, permissionTypes.DELETE);
}

export function createMatterAccounts(matterTypeId) {
  const state = store.getState();
  const accountPermissions = state.app.permissions;

  const createAccounts = [];

  for (const accountPermission of accountPermissions) {
    if (hasAccessToMatterType(accountPermission, matterTypeId, permissionTypes.CREATE)) {
      createAccounts.push(accountPermission);
    }
  }

  return createAccounts;
}

// INTERNAL FUNCTIONS

function hasAnyAccountAccessToMatterType(matterTypeId, requiredCrudPermission) {
  const state = store.getState();
  const accountPermissions = state.app.permissions;

  for (const accountPermission of accountPermissions) {
    if (hasAccessToMatterType(accountPermission, matterTypeId, requiredCrudPermission)) {
      return true;
    }
  }

  return false;
}

function canAccessMatterRecord(matterTypeId, accountId, requiredCrudPermission) {
  const state = store.getState();
  const accountPermissions = state.app.permissions;
  const accountPermission = accountPermissions.find((acc) => acc.accountId === accountId);

  if (!accountPermission) return false;

  return hasAccessToMatterType(accountPermission, matterTypeId, requiredCrudPermission);
}

function canAccessMatterObject(matterTypeId, matterObjectId, accountId, requiredCrudPermission) {
  const state = store.getState();
  const accountPermissions = state.app.permissions;
  const accountPermission = accountPermissions.find((acc) => acc.accountId === accountId);

  if (!accountPermission) return false;

  if (!hasAccessToMatterType(accountPermission, matterTypeId, permissionTypes.READ)) {
    return false;
  }

  const matterObject = accountPermission.matterObjects?.find((ex) => ex.matterObjectId === matterObjectId);

  if (matterObject) {
    return canAccess(matterObject.crudPermissions, requiredCrudPermission);
  } else {
    return canAccess(accountPermission.crudPermissions, requiredCrudPermission);
  }
}

function getMatterObjectPermissions(matterTypeId, matterObjectId, accountId) {
  const state = store.getState();
  const accountPermissions = state.app.permissions;
  const accountPermission = accountPermissions.find((acc) => acc.accountId === accountId);

  if (!accountPermission) return permissionTypes.NONE;

  // If no access to the core matter then no access to the matter object e.g. actions
  if (!hasAccessToMatterType(accountPermission, matterTypeId, permissionTypes.READ)) {
    return permissionTypes.NONE;
  }

  const matterObject = accountPermission.matterObjects?.find((ex) => ex.matterObjectId === matterObjectId);

  // If the matter object has specific crud permission use them else if the master crud permission has update
  // permission then the object has full CRUD permissions
  // i.e. if you can edit a core matter record details you can perform full CRUD on related matter table e.g. actions
  if (matterObject) {
    return matterObject.crudPermissions;
  } else {
    const exception = accountPermission.exceptions?.find((ex) => ex.matterTypeId === matterTypeId);
    const matterCrudPermissions = exception ? exception.crudPermissions : accountPermission.crudPermissions;

    if (matterCrudPermissions & permissionTypes.UPDATE) return permissionTypes.ALL;
    else return permissionTypes.READ;
  }
}

function hasAccessToMatterType(accountAccess, matterTypeId, requiredCrudPermission) {
  // If there an exception for the matter type then use that
  const exception = accountAccess.exceptions?.find((ex) => ex.matterTypeId === matterTypeId);

  if (exception) {
    if (canAccess(exception.crudPermissions, requiredCrudPermission)) {
      return true;
    }
  } else if (canAccess(accountAccess.crudPermissions, requiredCrudPermission)) {
    return true;
  }

  return false;
}

function canAccess(crudPermissions, requiredCrudPermission) {
  return (crudPermissions & requiredCrudPermission) === requiredCrudPermission;
}

// Company records are a special case as they are not linked to an account
// Users always have a minimum of read access to company records
function getCompanyRecordPermissions() {
  let companyPermissions = permissionTypes.READ;
  if (hasAnyAccountAccessToMatterType(matterTypes.COMPANY, permissionTypes.CREATE))
    companyPermissions |= permissionTypes.CREATE;
  if (hasAnyAccountAccessToMatterType(matterTypes.COMPANY, permissionTypes.UPDATE))
    companyPermissions |= permissionTypes.UPDATE;
  if (hasAnyAccountAccessToMatterType(matterTypes.COMPANY, permissionTypes.DELETE))
    companyPermissions |= permissionTypes.DELETE;
  return companyPermissions;
}

function getCompanySectionPermissions(sectionObject) {
  const state = store.getState();
  const accountPermissions = state.app.permissions;

  let companyPermissions = permissionTypes.NONE;
  for (const accountPermission of accountPermissions) {
    // For each account get the most powerful CRUD permission for the section/object type
    const objectPermission = getMatterObjectPermissions(
      matterTypes.COMPANY,
      sectionObject.id,
      accountPermission.accountId
    );
    if (objectPermission > companyPermissions) companyPermissions = objectPermission;
  }
  return companyPermissions;
}
