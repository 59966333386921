import React, { useEffect } from "react";
import { getSectionDefinitions } from "utilities/sectionDefinitions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBatchImage, deleteBatchImage, updateBatchImage } from "api/batchCreate";
import { addImage, deleteImage, updateImageField } from "redux/batchCreateSlice";
import Panel from "components/content/matter-record/Panel";
import { findById } from "utilities/stringAndArray";
import { projectStatuses } from "utilities/constants";
//import PropTypes from "prop-types";

function BatchCreateImages() {
  const batchCreateState = useSelector((state) => state.batchCreate);
  const project = batchCreateState.project;

  const dispatch = useDispatch();

  const [keyProps, setKeyProps] = useState(null);

  const secDef = findById(getSectionDefinitions(), 91);

  useEffect(() => {
    // Trick the data panel into thinking we have a standard matter record image thumbnails control
    if (project?.id) {
      const projectMatterMapped = {
        ...project,
        images: project.images.map((img) => {
          return {
            id: img.id,
            matterImage_ImageId: img.imageId,
            matterImage_ByteCount: img.byteCount,
            matterImage_Description: img.description,
            matterImage_FileName: img.fileName,
            matterImage_Height: img.height,
            matterImage_Width: img.width,
            matterImage_SortIndex: img.sortIndex,
          };
        }),
      };

      setKeyProps({
        sectionType: secDef.type,
        sectionName: secDef.name,
        sectionId: secDef.id,
        tableName: secDef.tableName,
        fieldList: secDef.fieldList,
        record: projectMatterMapped,
        isProject: true,
      });
    }
  }, [project]);

  const handleAddNewImage = async (image) => {
    try {
      const newImage = await createBatchImage(project.id, image);
      dispatch(addImage(newImage));
    } catch (error) {
      console.error("Error adding new image:", error);
    }
  };

  const handleFieldChange = async (changedFieldName, imageId, value) => {
    try {
      await updateBatchImage(project.id, imageId, changedFieldName, value);
      dispatch(updateImageField({ imageId, changedFieldName, value }));
    } catch (error) {
      console.error("Error updating image field:", error);
    }
  };

  const handleImageDelete = async (id) => {
    try {
      await deleteBatchImage(project.id, id);
      dispatch(deleteImage(id));
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  return (
    keyProps !== null && (
      <Panel
        keyProps={keyProps}
        onAddNew={handleAddNewImage}
        onChange={handleFieldChange}
        onDelete={handleImageDelete}
        isReadOnly={project?.project_Status === projectStatuses.MATTER_GENERATED}
      />
    )
  );
}

export default BatchCreateImages;
