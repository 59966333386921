import React, { useEffect } from "react";
import ProjectSection from "../ProjectSection";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBatchProject } from "api/project";
import { getDisplayNameForFieldName } from "utilities/datafield";
import { updateProjectField } from "redux/projectSlice";
import { refreshBatchSummary } from "api/batchCreate";
import { projectStatuses } from "utilities/constants";

function BatchCreateCoreDetails() {
  // NOTE: The matter name is only relevant to the batch create project but we're storing it in the project slice.
  // This is an ANOMALY and should be fixed at some point, but no obvious solution presents itself.
  const project = useSelector((state) => state.project).project;
  const batchCreateState = useSelector((state) => state.batchCreate);

  const dispatch = useDispatch();

  const [matterNameValue, setMatterNameValue] = useState("");
  const [matterNameLabel, setMatterNameLabel] = useState("");

  useEffect(() => {
    if (project?.id) {
      setMatterNameValue(project.project_MatterName ?? batchCreateState.project?.project_MatterName); // Another manifestation of the ANOMALY
      setMatterNameLabel(getDisplayNameForFieldName("matter_Name", [project?.project_MatterTypeId])); // Should really be "project_MatterName" instead of "matter_Name" but currently not set in DB for translation
    }
  }, [project]);

  const handleValueChange = (e) => {
    setMatterNameValue(e.target.value);
  };

  const handleValueBlur = () => {
    updateBatchProject(project.id, "project_MatterName", matterNameValue);
    dispatch(updateProjectField({ fieldName: "project_MatterName", value: matterNameValue }));
    refreshBatchSummary(batchCreateState.project);
  };

  return (
    <ProjectSection title="Core Details">
      <div className="batch-create__core-details">
        <div className="core-details__row">
          <div className="core-details__label">{matterNameLabel}</div>
          {batchCreateState.project?.project_Status !== projectStatuses.MATTER_GENERATED ? (
            <div className="core-details__input">
              <input type="text" value={matterNameValue ?? ""} onChange={handleValueChange} onBlur={handleValueBlur} />
            </div>
          ) : (
            <div className="core-details__value">- {matterNameValue}</div>
          )}
        </div>
      </div>
    </ProjectSection>
  );
}

export default BatchCreateCoreDetails;
