import { setBearerToken, setCustAdmin, setCustomerId, setSysAdmin, setUserId } from "redux/appSlice";
import { signIn } from "api/user";
import { loadAllGlobalLookups } from "api/lookup";
import { loadDataFieldGroups, loadDataFields, loadSearchFavouriteFields } from "api/datafield";
import { loadTranslations } from "api/translations";
import { useState, useEffect } from "react";
import { setInitialLanguageStore } from "redux/localeSlice";
import { useDispatch, useSelector } from "react-redux";
import { t } from "locale/dictionary";
import Checkbox from "components/global/Checkbox";
import { loadBookmarks, loadCountryGroups, loadLegalTeam, loadWidgets } from "api/customer";
import { getDefaultMatterData, getRequiredMatterData, getSuggestedMatterData } from "api/matter";
import { setDefaultSearch, processDataFields } from "utilities/simpleSearch";
import { loadCountryMapData, loadWorldMap3dData, loadWorldMapData } from "api/mapData";
import { setFullName } from "redux/customerSlice";
import { setOverrideDisplayNames } from "utilities/datafield";
import { useNavigate } from "react-router-dom";
import { getSavedSearches } from "api/search";
import { setSavedSearches } from "redux/simpleSearchSlice";
import { getUsersFromCustomer } from "api/security";
import { loadAllMatterTypes, loadPermissions, loadThirdPartyKeys } from "api/app";
import { idsAreEqual } from "utilities/stringAndArray";
import SuperSelect from "components/global/SuperSelect";

// Functionality for authentication of a user
export default function Authentication() {
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modes = { LOGIN: 0, FORGOTPASSWORD: 1, SELECTCUSTOMER: 2 };

  const [isLoaded, setIsLoaded] = useState(false);
  const [isShowingLogin, setIsShowingLogin] = useState(false);
  const [isShowingCredentialError, setIsShowingCredentialError] = useState(false);
  const [usernameText, setUsernameText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [mode, setMode] = useState(modes.LOGIN);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [authInfo, setAuthInfo] = useState(null);

  useEffect(() => {
    getAuthTokenIfNone();
  }, [appState.bearerToken]);

  useEffect(() => {
    if (appState.customerId) {
      getUsersFromCustomer(appState.customerId);
    }
  }, [appState.customerId]);

  useEffect(() => {
    if (appState.customerId && !isLoaded) initialLoader(appState.isSysAdmin);
  }, [isLoaded, appState.customerId, appState.isSysAdmin]);

  useEffect(() => {
    if (selectedCustomerId && authInfo) processAuthentication(authInfo);
  }, [selectedCustomerId, authInfo]);

  const getAuthTokenIfNone = () => {
    let jwt = null;
    let username = "";
    let password = "";
    let fullName = "";
    let sysAdmin = false;
    let userId = "";
    let customerId = "";
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");
    //check cookies for sysAdmin
    cookieArr.forEach((cookie) => {
      const splitCookie = cookie.split("=");
      if (splitCookie[0].trim() === "a") sysAdmin = splitCookie[1];
      if (splitCookie[0].trim() === "i") userId = splitCookie[1];
      if (splitCookie[0].trim() === "c") customerId = splitCookie[1];
    });

    if (appState.bearerToken) {
      dispatch(setCustomerId(customerId));
      dispatch(setUserId(userId));
      dispatch(setCustAdmin(true));
      dispatch(setSysAdmin(sysAdmin === "true" ? true : false));
      return;
    }

    // Loop through the array elements
    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
      if (cookiePair[0].trim() === "jwt") {
        // Decode the cookie value and return
        jwt = decodeURIComponent(cookiePair[1]);
        dispatch(setBearerToken(jwt));
        dispatch(setUserId(userId));
        dispatch(setSysAdmin(sysAdmin));
      }
      if (cookiePair[0].trim() === "u") username = decodeURIComponent(cookiePair[1]);
      if (cookiePair[0].trim() === "p") password = decodeURIComponent(cookiePair[1]);
      if (cookiePair[0].trim() === "n") {
        fullName = decodeURIComponent(cookiePair[1]);
        dispatch(setFullName(fullName));
      }
    }
    if (jwt) return;
    // No token cookie present or has expired, so get new one

    // No username/password in cookies, so show login panel, otherwise authenticate with cookie credentials
    if (username.length === 0 || password.length === 0) {
      setIsShowingLogin(true);
      navigate("/");
    } else authenticate(username, password);
  };

  const authenticate = async (username, password) => {
    let authInfoLocal = await signIn(username, password);
    authInfoLocal = { ...authInfoLocal, username, password };
    if (authInfoLocal) {
      setIsShowingCredentialError(false);

      // User has access to multiple customers, so show customer selection and do not process authentication yet
      if (authInfoLocal.customers?.length > 1) {
        setMode(modes.SELECTCUSTOMER);
        setCustomers(authInfoLocal.customers.map((c) => ({ id: c.customerId, displayValue: c.customerName })));
        setAuthInfo(authInfoLocal);
        return;
      } else processAuthentication(authInfoLocal);
    } else {
      setIsShowingLogin(true);
      setIsShowingCredentialError(true);
    }
  };

  // Process authentication data and set cookies now that we have either a single customer or a user selected customer
  const processAuthentication = (ai) => {
    if (isRememberMe) {
      const expiresRemember = new Date(Date.now() + 14 * 24 * 3600 * 1000).toUTCString(); // set to 14 days
      document.cookie = `u=${encodeURIComponent(ai.username)}; path=/; expires=${expiresRemember}`;
      document.cookie = `p=${encodeURIComponent(ai.password)}; path=/; expires=${expiresRemember}`;
    }

    const expiresJwt = new Date(Date.now() + 12 * 3600 * 1000).toUTCString(); // set to 12 hours
    document.cookie = `jwt=${encodeURIComponent(ai.token)}; path=/; expires=${expiresJwt}`;
    document.cookie = `n=${encodeURIComponent(ai.fullName)}; path=/; expires=${expiresJwt}`;
    document.cookie = `a=${encodeURIComponent(ai.sysAdmin)}; path=/; expires=${expiresJwt}`;
    document.cookie = `i=${encodeURIComponent(ai.id)}; path=/; expires=${expiresJwt}`;
    dispatch(setBearerToken(ai.token));
    dispatch(setFullName(ai.fullName));

    let customerId = null;
    if (!ai.customers && ai.sysAdmin !== true) {
      console.error("No customers found in ai - exiting authentication");
      return;
    }

    if (ai.customers.length === 1 || selectedCustomerId) {
      customerId = selectedCustomerId ?? ai.customers[0].customerId;
      document.cookie = `c=${encodeURIComponent(customerId)}; path=/; expires=${expiresJwt}`;
    }

    dispatch(setCustomerId(customerId));
    dispatch(setUserId(ai.id));
    dispatch(setSysAdmin(ai.sysAdmin));
    // Set redux customer admin flag if ai.custAdmins contains a customerId property matching current customerId
    if (ai.custAdmins && ai.custAdmins[0]) {
      if (ai.custAdmins.find((custAdminObj) => idsAreEqual(custAdminObj.customerId, customerId)))
        dispatch(setCustAdmin(true));
    }

    setIsShowingLogin(false);
  };

  /*****************************************
    This is the main initialisation function for all the data in the customer's browser
    *****************************************/
  const initialLoader = async (isSysAdmin) => {
    setIsLoaded(true);
    dispatch(setInitialLanguageStore());
    if (!isSysAdmin) {
      await loadCountryGroups();
    }
    await loadTranslations();
    await loadAllGlobalLookups();
    await loadDataFields();
    await loadSearchFavouriteFields();
    setOverrideDisplayNames();
    processDataFields();
    loadDataFieldGroups();
    loadAllMatterTypes();
    loadThirdPartyKeys();
    await loadLegalTeam();
    getRequiredMatterData();
    getSuggestedMatterData();
    getDefaultMatterData();
    const savedSearches = await getSavedSearches();
    dispatch(setSavedSearches(savedSearches));
    setDefaultSearch(savedSearches);
    loadWorldMapData();
    loadWorldMap3dData();
    loadCountryMapData("us");
    if (!isSysAdmin) {
      loadBookmarks();
      loadWidgets();
      await loadPermissions();
    }
  };

  const handleUsernameTextChange = (e) => {
    setUsernameText(e.target.value);
  };

  const handlePasswordTextChange = (e) => {
    setPasswordText(e.target.value);
  };

  const handleResetPassword = () => {
    navigate(`/new-user?email=${usernameText}`, { replace: false });
  };

  const renderLogin = (
    <>
      <div className="login__row">
        <div className="textbox">
          <div className="textbox__label">{t("Password")}</div>
          <input type="password" value={passwordText} onChange={handlePasswordTextChange} />
        </div>
      </div>
      <button onClick={() => authenticate(usernameText, passwordText)}>{t("Submit")}</button>
      <div className="login__row">
        <Checkbox
          label={t("Remember me on this computer")}
          isChecked={isRememberMe}
          onCheckChange={() => setIsRememberMe(!isRememberMe)}
        />
      </div>
      <div className="link" onClick={() => setMode(modes.FORGOTPASSWORD)}>
        {t("Forgot password?")}
      </div>
    </>
  );

  const renderResetPassword = (
    <>
      <button onClick={handleResetPassword}>{t("Reset Password")}</button>
      <p>&nbsp;</p>
      <div className="link" onClick={() => setMode(modes.LOGIN)}>
        {t("Back to Login")}
      </div>
    </>
  );

  const renderSelectCustomer = (
    <>
      <div className="login__row">
        Your login has access to multiple customers. Please select the customer you wish to use for this session:
      </div>
      <div className="login__row">
        <SuperSelect
          options={customers}
          onChange={(optionId) => setSelectedCustomerId(optionId)}
          selectedOptionId={selectedCustomerId}
          value={selectedCustomerId}
        />
      </div>
    </>
  );

  return (
    isShowingLogin && (
      <div className="login">
        <div className="login__popup">
          <div className="login__header">{t("Login")}</div>
          <div className="login__body">
            {mode !== modes.SELECTCUSTOMER && (
              <div className="login__row">
                <div className="textbox">
                  <div className="textbox__label">{t("Username")}</div>
                  <input value={usernameText} onChange={handleUsernameTextChange} />
                </div>
              </div>
            )}
            {mode === modes.LOGIN && renderLogin}
            {mode === modes.FORGOTPASSWORD && renderResetPassword}
            {mode === modes.SELECTCUSTOMER && renderSelectCustomer}
            {isShowingCredentialError && (
              <div className="login__row">
                <div className="validation-error">{t("Username or password is not recognised")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}
