import { useState } from "react";
import { PropTypes } from "prop-types";
import Paginator from "components/global/Paginator";
import PopupHeader from "components/global/PopupHeader";
import { t } from "locale/dictionary";
import { MdFileCopy } from "react-icons/md";

function MailMergeEmailOutputPopup({ onClose, output }) {
  const { ClipboardItem } = window;
  const [pageNumber, setPageNumber] = useState(0);

  if (!output || output?.messages?.length === 0) {
    return null;
  }

  const handlePageFirstClick = () => {
    setPageNumber(0);
  };

  const handlePagePrevClick = () => {
    setPageNumber((preValue) => preValue - 1);
  };

  const handlePageNextClick = () => {
    setPageNumber((preValue) => preValue + 1);
  };

  const handlePageLastClick = () => {
    setPageNumber(output?.messages?.length - 1);
  };

  const handleCopy = async (htmlContent) => {
    await navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([htmlContent], { type: "text/html" }),
        "text/plain": new Blob(["This is plain text fallback"], { type: "text/plain" }),
      }),
    ]);
  };

  const handleCopyText = async (textContent) => {
    await navigator.clipboard.writeText(textContent);
  };

  const message = output.messages[pageNumber];

  return (
    <div className="output-container__mail-merge">
      <PopupHeader title={t("Email Output")} onClose={onClose} />
      <div className="mail-merge__container">
        <div className="mail-merge__header">
          <table>
            <tr>
              <td className="header__label">To</td>
              <td className="header__copy">
                <button onClick={() => handleCopyText(message.emailAddress)}>
                  <MdFileCopy />
                </button>
              </td>
              <td>{message.emailAddress}</td>
            </tr>
            <tr>
              <td className="header__label">Subject</td>
              <td className="header__copy">
                <button onClick={() => handleCopyText(message.subject)}>
                  <MdFileCopy />
                </button>
              </td>
              <td>{message.subject}</td>
            </tr>
            <tr>
              <td className="header__label">Body</td>
              <td className="header__copy">
                <button onClick={() => handleCopy(message.body)}>
                  <MdFileCopy />
                </button>
              </td>
              <td></td>
            </tr>
          </table>
        </div>
        <div className="mail-merge__message">
          <div style={{ userSelect: "auto" }} dangerouslySetInnerHTML={{ __html: message.body }} />
        </div>
        <div className="mail-merge__footer">
          <Paginator
            totalPages={output?.messages.length}
            currentPage={pageNumber}
            onPageFirstClick={handlePageFirstClick}
            onPagePrevClick={handlePagePrevClick}
            onPageNextClick={handlePageNextClick}
            onPageLastClick={handlePageLastClick}
          />
        </div>
      </div>
    </div>
  );
}

MailMergeEmailOutputPopup.propTypes = {
  onClose: PropTypes.func,
  output: PropTypes.object,
};

export default MailMergeEmailOutputPopup;
