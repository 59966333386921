import { fetchWrapper } from "utilities/fetchWrapper";
import store from "redux/store";
import { searchViewTypes } from "utilities/constants";
import { setBatchImportLoading } from "redux/batchImportSlice";
import { parseSearchResponse } from "utilities/searchResults";
import { setResults, setSelected } from "redux/searchResultsSlice";
import {
  processCompanySearchResultSet,
  processCompanyContactSearchResultSet,
  processMatterFieldSearchResultSet,
  processMatterNameSearchResultSet,
  processProjectSearchResultSet,
  processMatterReferenceSearchResultSet,
} from "utilities/simpleSearch";
import { setQueryObject } from "redux/simpleSearchSlice";

// Search-related API calls here

export async function search(body) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.post(`customers/${customerId}/search`, body);
  return response;
}

export const searchAndSetResults = async (queryObject, viewType, selected) => {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.post(`customers/${customerId}/search`, queryObject);

  if (!response || !response.header || !response.header.dataFieldNames) return;

  if (searchViewTypes.BATCH_IMPORT === viewType) store.dispatch(setBatchImportLoading(false));
  const cols = parseSearchResponse(response);
  store.dispatch(setResults({ cols, logId: response.logId }));
  store.dispatch(setQueryObject(queryObject));
  if (selected) store.dispatch(setSelected(selected));
};

export async function searchCompaniesQualified(customerId, fieldName, searchWords, matterTypeIds) {
  const searchArray = searchWords.split(/[,]+/); // split(/[\s,]+/)
  let outputArray = [];
  await Promise.all(
    searchArray.map(async (element) => {
      const searchString = element.trim();
      if (!searchString || searchString.length < 2) return;
      const body = {
        searchWords: searchString,
        matterTypeIds,
      };
      const response = await fetchWrapper.post(`customers/${customerId}/companies/qualify/${fieldName}`, body);
      const mappedResponse = processCompanySearchResultSet(response);

      outputArray = [...outputArray, ...mappedResponse];
    })
  );
  return outputArray;
}

export async function searchCompanyContactsQualified(customerId, fieldName, searchWords, matterTypeIds) {
  const searchArray = searchWords.split(/[,]+/); // split(/[\s,]+/)
  let outputArray = [];
  await Promise.all(
    searchArray.map(async (element) => {
      const searchString = element.trim();
      if (!searchString || searchString.length < 2) return;
      const body = {
        searchWords: searchString,
        matterTypeIds,
      };
      const response = await fetchWrapper.post(`customers/${customerId}/companycontacts/qualify/${fieldName}`, body);
      const mappedResponse = processCompanyContactSearchResultSet(response);

      outputArray = [...outputArray, ...mappedResponse];
    })
  );
  return outputArray;
}

export async function searchMatterFieldsQualified(customerId, fieldName, searchWords, matterTypeIds) {
  const searchArray = searchWords.split(/[,]+/); // split(/[\s,]+/)
  let outputArray = [];
  await Promise.all(
    searchArray.map(async (element) => {
      const searchString = element.trim();
      if (!searchString || searchString.length < 2) return;
      const body = {
        searchWords: searchString,
        matterTypeIds,
      };
      const response = await fetchWrapper.post(`customers/${customerId}/matters/qualify/${fieldName}`, body);
      const mappedResponse = processMatterFieldSearchResultSet(response);

      outputArray = [...outputArray, ...mappedResponse];
    })
  );
  return outputArray;
}

export async function searchMatterReferencesQualified(customerId, fieldName, searchWords, matterTypeIds) {
  const searchArray = searchWords.split(/[,]+/); // split(/[\s,]+/)
  let outputArray = [];
  await Promise.all(
    searchArray.map(async (element) => {
      const searchString = element.trim();
      if (!searchString || searchString.length < 2) return;
      const body = {
        searchWords: searchString,
        matterTypeIds,
      };
      const response = await fetchWrapper.post(`customers/${customerId}/matters/references/qualify/${fieldName}`, body);
      const mappedResponse = processMatterReferenceSearchResultSet(response);

      outputArray = [...outputArray, ...mappedResponse];
    })
  );
  return outputArray;
}

export async function searchProjectsQualified(customerId, fieldName, searchWords, matterTypeIds) {
  const searchArray = searchWords.split(/[,]+/); // split(/[\s,]+/)
  let outputArray = [];
  await Promise.all(
    searchArray.map(async (element) => {
      const searchString = element.trim();
      if (!searchString || searchString.length < 2) return;
      const body = {
        searchWords: searchString,
        matterTypeIds,
      };
      const response = await fetchWrapper.post(`customers/${customerId}/projects/qualify/${fieldName}`, body);
      const mappedResponse = processProjectSearchResultSet(response);

      outputArray = [...outputArray, ...mappedResponse];
    })
  );
  return outputArray;
}

export async function searchMatterName(customerId, matterTypeIds, searchWords) {
  const searchArray = searchWords.split(/[,]+/); // split(/[\s,]+/)
  let outputArray = [];
  await Promise.all(
    searchArray.map(async (element) => {
      const searchString = element.trim();
      if (!searchString || searchString.length < 2) return;
      const body = {
        searchWords: searchString,
        matterTypeIds,
      };
      const response = await fetchWrapper.post(`customers/${customerId}/matters/search`, body);
      outputArray = [...outputArray, ...response];
    })
  );
  return outputArray;
}

export async function qualifySearch(searchWords, queryType, matterTypeIds) {
  const searchArray = searchWords.split(/[,]+/); // split(/[\s,]+/)
  const state = store.getState();
  const customerId = state.app.customerId;
  let outputArray = [];
  await Promise.all(
    searchArray.map(async (element) => {
      const searchString = element.trim();
      if (!searchString || searchString.length < 2) return;
      const body = {
        searchWords: searchString,
        queryType,
        matterTypeIds,
      };
      const response = await fetchWrapper.post(`customers/${customerId}/qualifysearch`, body);

      const mappedResponse = response.map((item) => {
        let valueMatches = [];

        if (item.type === "APPLICATION_NUMBER" || item.type === "REGISTRATION_NUMBER") {
          valueMatches = processMatterFieldSearchResultSet(item.matterFieldSearch);
        } else if (item.type === "COMPANY" || item.type === "COMPANY_LINK") {
          valueMatches = processCompanySearchResultSet(item.companySearch);
        } else if (item.type === "COMPANYCONTACT") {
          valueMatches = processCompanyContactSearchResultSet(item.companyContactSearch);
        } else if (item.type === "MATTER" || item.type === "MATTER_LINK") {
          valueMatches = processMatterNameSearchResultSet(item.matterSearch);
        } else if (item.type === "PROJECT") {
          valueMatches = processProjectSearchResultSet(item.projectSearch);
        } else if (item.type === "REFERENCE") {
          valueMatches = processMatterReferenceSearchResultSet(item.referenceSearch);
        } else {
          // Not recognised
          return null;
        }

        return {
          dataFieldName: item.dataFieldName,
          qualifyType: item.type,
          valueMatches,
        };
      });

      outputArray = [...outputArray, ...mappedResponse];
    })
  );

  return outputArray;
}

// Get a list of saved (favourite) user searches
export async function getSavedSearches() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/searchfilters`);
  return response;
}

// Add a new search to saved
export async function addSavedSearch(name, pinned, filters) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = {
    name,
    pinned: !!pinned, // Converts "falsey" null to hard false to prevent API rejection
    filters,
  };

  const response = await fetchWrapper.post(`customers/${customerId}/searchfilters/`, body);
  return response;
}

export async function changeFavouriteSearch(savedSearchId, fieldName, value, initialValue) {
  // If no material change in value, do not save
  if (value === initialValue || (!value && initialValue === "") || (value === "" && !initialValue)) return;

  const state = store.getState();
  const customerId = state.app.customerId;

  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/searchfilters/${savedSearchId}`, body);
}

export async function setDefaultSearch(savedSearchId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const fieldName = "defaultSearchFilterId";
  const value = savedSearchId;

  const body = [
    // { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/usersettings/`, body);
}

export async function deleteFavouriteSearch(savedSearchId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.delete(`customers/${customerId}/searchfilters/${savedSearchId}`);
  return response;
}
