import { useState } from "react";
import { useDispatch } from "react-redux";
import { PropTypes } from "prop-types";
import { getDataSource, MailMergeObjectTypes } from "utilities/reportEditor";
import TableObject from "./TableObject";
import Delete from "components/global/Delete";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { getTranslation } from "utilities/reportShared";
import { deleteMailMergeObject, updateMailMergeObjectName } from "redux/reportSlice";
import { t } from "locale/dictionary";

function ObjectItem({ format, matterTypeIds, mailMergeObject }) {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isEditingName, setIsEditingName] = useState(false);

  const handleDeleteClick = () => {
    dispatch(deleteMailMergeObject({ id: mailMergeObject.id }));
  };

  let content = null;
  if (mailMergeObject.mailMergeFormatObjectType === MailMergeObjectTypes.TABLE) {
    content = <TableObject format={format} matterTypeIds={matterTypeIds} mailMergeObject={mailMergeObject} />;
  }

  let objectType = null;

  if (mailMergeObject.mailMergeFormatObjectType === MailMergeObjectTypes.TABLE) {
    let dataSourceLabel = null;

    if (mailMergeObject?.dataSourceName) {
      const dataSource = getDataSource(mailMergeObject.dataSourceName);
      dataSourceLabel = getTranslation(dataSource.translationCode);
    }

    const label = dataSourceLabel ? `(${dataSourceLabel})` : "";
    objectType = `Table ${label}: `;
  }

  const handleObjectNameChange = (e) => {
    dispatch(updateMailMergeObjectName({ id: mailMergeObject?.id, name: e.target.value }));
  };

  const handleObjectNameUpdate = (e) => {
    setIsEditingName(false);
  };

  return (
    <div className="mail-merge-container__object">
      <div className="object__header">
        <div className="object__title">
          <div className="data-panel__caret clickable" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <FaCaretDown /> : <FaCaretRight />}
          </div>
          <div>{objectType}&nbsp;</div>
          <div>
            {isEditingName ? (
              <input
                className="object__title-input"
                type="text"
                value={mailMergeObject?.name ?? ""}
                onChange={handleObjectNameChange}
                onBlur={handleObjectNameUpdate}
                placeholder={t("Enter a name for this Object")}
                onKeyUp={(e) => e.key === "Enter" && handleObjectNameUpdate()}
              />
            ) : (
              <>
                {mailMergeObject?.name ?? ""}
                &nbsp;
                <span className="link" onClick={() => setIsEditingName(true)}>
                  {t("Edit")}
                </span>
              </>
            )}
          </div>
        </div>
        <div>
          <Delete onConfirm={handleDeleteClick} message={t("Delete report object")} showTrashIconInstead={true} />
        </div>
      </div>
      {isExpanded && <div className="object__body">{content}</div>}
    </div>
  );
}

ObjectItem.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.arrayOf(PropTypes.number),
  mailMergeObject: PropTypes.object,
};

export default ObjectItem;
