import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import RadioGroup from "components/global/RadioGroup";
import {
  toggleRowExpansion,
  setRowNewValue,
  setRowOperationId,
  toggleAllExistingRowValuesChecked,
} from "redux/batchUpdateSlice";
import {
  getDataTypeForField,
  getDisplayNameForFieldName,
  getDisplayNameForFieldObject,
  getMatterTypeDisplayNamesFromIds,
} from "utilities/datafield";
import { labelTypes } from "utilities/constants";
import BatchUpdateFieldText from "./BatchUpdateFieldText";
import BatchUpdateFieldLookup from "./BatchUpdateFieldLookup";
import BatchUpdateFieldDate from "./BatchUpdateFieldDate";
import BatchUpdateFieldBoolean from "./BatchUpdateFieldBoolean";
import BatchUpdateFieldNameSearch from "./BatchUpdateFieldNameSearch";
import { getExistingCompanyLinksByLinkTypeId, getExistingFormFieldValues } from "api/batchUpdate";
import BatchUpdateExistingValues from "./BatchUpdateExistingValues";
import PropTypes from "prop-types";

const BatchUpdateTableRow = ({ rowItem, tableName, table }) => {
  const dispatch = useDispatch();
  const batchUpdateState = useSelector((state) => state.batchUpdate);
  const localeState = useSelector((state) => state.locale);

  const handleToggleRowExpandClick = (rowItem, tableName) => {
    dispatch(toggleRowExpansion({ rowItem, tableName }));

    if (rowItem.isExpanded) return; // We are now collapsing the row

    // Fetch the existing values for the row from the DB

    if (tableName === "formFields" && table[rowItem.fieldName]?.existingValues === undefined)
      getExistingFormFieldValues(rowItem);
    else if (tableName === "companyLinks" && table[rowItem.id]?.existingValues === undefined)
      getExistingCompanyLinksByLinkTypeId(rowItem);
    else {
      console.log("Existing field values already fetched for " + rowItem.fieldName);
    }
  };

  const handleFieldEditChange = (rowItem, newValue) => {
    dispatch(setRowNewValue({ rowItem, newValue, tableName }));
  };

  const handleOperationChange = (rowItem, operationId) => {
    dispatch(setRowOperationId({ rowItem, operationId, tableName }));
  };

  const handleClearAllFieldChanges = () => {
    dispatch(setRowNewValue({ rowItem, newValue: null, tableName }));
    dispatch(setRowOperationId({ rowItem, operationId: 0, tableName }));
    dispatch(toggleAllExistingRowValuesChecked({ rowItem, tableName, clearAll: true }));
  };

  let rowItemState = null;
  if (tableName === "formFields") rowItemState = table.find((f) => f.fieldName === rowItem.fieldName);
  else if (tableName === "companyLinks") {
    rowItemState = table.find((f) => f.id === rowItem.id);
  }

  const existingValues = rowItemState?.existingValues;
  const operationId = rowItemState?.operationId;
  //console.log("🚀 ~ BatchUpdateTableRow ~ operationId:", operationId);
  const fieldDisplayName = tableName === "formFields" && getDisplayNameForFieldObject(rowItem);

  const renderFieldName = () => {
    const renderClearFieldLink = (
      <div className="link text-x-small-italic" onClick={handleClearAllFieldChanges}>
        Clear all changes for this row
      </div>
    );

    const fieldHasChanges =
      (rowItemState?.newValue !== null && rowItemState?.newValue !== undefined) ||
      (rowItemState?.operationId !== null &&
        rowItemState?.operationId !== undefined &&
        rowItemState?.operationId !== 0);
    if (tableName === "formFields") {
      return (
        <div className="td__field-name">
          {getDisplayNameForFieldName(fieldDisplayName)}
          {rowItem.matterTypeIds.length !== batchUpdateState.matterTypeIds.length && (
            <div className="field-name__matter-type-names">
              {getMatterTypeDisplayNamesFromIds(rowItem.matterTypeIds)} records only
            </div>
          )}
          {fieldHasChanges && renderClearFieldLink}
        </div>
      );
    } else if (tableName === "companyLinks") {
      return (
        <div className="td__field-name">
          {localeState.translations[rowItem.translationCode]}
          {fieldHasChanges && renderClearFieldLink}
        </div>
      );
    }
  };

  const displayType = getDataTypeForField(rowItem.fieldName);

  const renderOperation = () => {
    let optionsArray = [];

    if (rowItem?.existingValues?.length > 0) {
      optionsArray = [{ id: 0, displayValue: "Replace" }];
      if (
        displayType !== labelTypes.BOOLEAN &&
        (rowItem.existingValues.length > 1 || rowItem.existingValues[0].value !== null)
      )
        optionsArray.push({ id: 1, displayValue: "Clear" });
    }

    if ((rowItem.isListType && rowItem.isMultiValued) || tableName === "companyLinks") {
      optionsArray.push({ id: 2, displayValue: "Add New" });
    }

    return (
      rowItem.isExpanded && (
        <RadioGroup
          groupName={`radio-group-${rowItem.fieldName}-${rowItem.id}`}
          optionsArray={optionsArray}
          selectedOptionIdExternal={operationId ?? 0}
          isVertical={true}
          onRadioChange={(id) => handleOperationChange(rowItem, id, table)}
        />
      )
    );
  };

  const renderNewValue = () => {
    if (!rowItem.isExpanded) return null;
    if (tableName === "formFields") {
      if (displayType === labelTypes.LOOKUP || rowItem.isListType)
        return (
          <BatchUpdateFieldLookup field={rowItem} onChange={handleFieldEditChange} isListType={rowItem.isListType} />
        );
      if (displayType === labelTypes.DATE)
        return <BatchUpdateFieldDate field={rowItem} onChange={handleFieldEditChange} />;
      if (displayType === labelTypes.BOOLEAN)
        return <BatchUpdateFieldBoolean field={rowItem} onChange={handleFieldEditChange} />;
      return <BatchUpdateFieldText field={rowItem} onChange={handleFieldEditChange} />;
    } else if (tableName === "companyLinks") {
      const existingIdsForLinkType = rowItem?.existingValues.filter((ev) => ev.isChecked).map((ev) => ev.matterId);
      return (
        <BatchUpdateFieldNameSearch
          linkType={rowItem}
          existingIdsForLinkType={existingIdsForLinkType}
          onChange={handleFieldEditChange}
        />
      );
    }
  };

  const checkedExistingValues = existingValues?.filter((ev) => ev.isChecked);
  //console.log("🚀 ~ renderTableRow ~ existingValues:", existingValues);

  const showNewValueField =
    operationId !== 1 && ((operationId === 0 && checkedExistingValues?.length > 0) || operationId === 2);

  return (
    <tr>
      <td>
        {batchUpdateState?.id && (
          <div className="td__row-expander" onClick={() => handleToggleRowExpandClick(rowItem, tableName)}>
            {rowItem.isExpanded ? <FaCaretDown /> : <FaCaretRight />}
          </div>
        )}
      </td>
      <td>{renderFieldName()}</td>
      <td>
        <BatchUpdateExistingValues rowItem={rowItem} tableName={tableName} existingValues={existingValues} />
      </td>
      <td>{renderOperation()}</td>
      <td>{showNewValueField && renderNewValue()}</td>
    </tr>
  );
};

BatchUpdateTableRow.propTypes = {
  rowItem: PropTypes.object,
  tableName: PropTypes.string,
  table: PropTypes.array,
};

export default BatchUpdateTableRow;
