import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addMailMergeObject } from "redux/reportSlice";
import Modal from "components/global/Modal";
import { getSectionChildDataSources, getMailMergeObjectTypes } from "utilities/reportEditor";
import { getTranslation } from "utilities/reportShared";
import { compareByDisplayValueAsc } from "utilities/stringAndArray";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";

function CreateObject() {
  const dispatch = useDispatch();

  const [objectTypes] = useState(getMailMergeObjectTypes());
  const [selectedObjectType, setSelectedObjectType] = useState(1);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [objectName, setObjectName] = useState(null);
  const [availableDataSources, setAvailableDataSources] = useState([]);

  useEffect(() => {
    const childDataSources = getSectionChildDataSources("Matter");

    setAvailableDataSources(
      childDataSources
        .map((dataSource) => {
          return { id: dataSource.name, displayValue: getTranslation(dataSource.translationCode) };
        })
        .sort(compareByDisplayValueAsc)
    );
  }, []);

  const handleAddObject = () => {
    dispatch(
      addMailMergeObject({
        mailMergeFormatObjectType: selectedObjectType,
        dataSourceName: selectedDataSource,
        name: objectName,
      })
    );
    setSelectedObjectType(1);
    setSelectedDataSource(null);
    setObjectName(null);
  };

  const handleObjectTypeChange = (objectTypeId) => {
    setSelectedObjectType(objectTypeId);
  };

  const handleDataSourceChange = (dataSourceId) => {
    setSelectedDataSource(dataSourceId);
  };

  return (
    <Modal buttonLabel="New Object" title="New Object" closeOnly={false} onConfirm={handleAddObject}>
      <div className="mail-merge-container__object-modal">
        <div>
          <div className="object-modal__display-name">{t("Object type")}</div>
          <div className="object-modal__display-value">
            <SuperSelect
              id="objectType"
              options={objectTypes}
              selectedOptionId={selectedObjectType}
              isMultiValued={false}
              onChange={handleObjectTypeChange}
              placeholderText="Select an object type"
            />
          </div>
        </div>
        <div>
          <div className="object-modal__display-name">{t("Data source")}</div>
          <div className="object-modal__display-value">
            <SuperSelect
              id="dataSource"
              options={availableDataSources}
              selectedOptionId={selectedDataSource}
              isMultiValued={false}
              onChange={handleDataSourceChange}
              placeholderText="Select a data source"
            />
          </div>
        </div>
        <div>
          <div className="object-modal__display-name">{t("Name")}</div>
          <div className="object-modal__display-value">
            <input type="text" value={objectName} onChange={(e) => setObjectName(e.target.value)} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateObject;
