import { useEffect, useRef, useState } from "react";
import { getDataTypeForField, getLookupValueForField } from "utilities/datafield";
import { FaPlusCircle } from "react-icons/fa";
import { labelTypes, permissionTypes, validationStates } from "utilities/constants";
import Tooltip from "components/global/Tooltip";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setChildTableAdding, setSettingFocusFirstColumnTableName } from "redux/matterSlice";
import { t } from "locale/dictionary";
import PanelTableRow from "./PanelTableRow";
import PanelTableHeader from "./PanelTableHeader";
import { nanoid } from "nanoid";
import { initializeAddingRowData } from "utilities/matter";
import { compareBySortCol, idsAreEqual } from "utilities/stringAndArray";

/* Container Component for the table type data panel for a matter record
   Has the following responsibilities:
    - Display of overall table structure
    - Anchoring of action bars above table
    - Sorting of columns on receiving click info from header component
    - Initialisation of new row data when user signals adding of new row
    - Display of validation errors
  EXCLUDES Connected Matters table - see MatterDataPanelTableLinks component for this
*/
function PanelTable({ keyProps, rowData, indexRow, children, onChange, onDelete }) {
  const matterState = useSelector((state) => state.matter);
  const localeState = useSelector((state) => state.locale);

  const dispatch = useDispatch();

  const record = keyProps.record;
  const tableName = keyProps.tableName;
  const childTableAdding = matterState.childTableAdding;

  const [isAddingRows, setIsAddingRows] = useState(false);
  const [addingRowsData, setAddingRowsData] = useState([]);
  const [addRowValidationMessage, setAddRowValidationMessage] = useState("");
  const [sortCol, setSortCol] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);
  const [dataRows, setDataRows] = useState([]);

  const hasInitialSortSetRef = useRef(false);

  // handle signal from a companyLinks row component when it needs to add a row within its child contacts table
  useEffect(() => {
    if (
      childTableAdding &&
      childTableAdding.tableName === tableName &&
      childTableAdding.parentIndexRow === keyProps.parentIndexRow
    ) {
      onAddRow(childTableAdding.parentRowData);
      dispatch(setChildTableAdding(null));
    }
  }, [record, tableName, childTableAdding]);

  useEffect(() => {
    let dataRowsTemp = [];
    if (keyProps.parentRowData)
      dataRowsTemp = keyProps.parentRowData.hasOwnProperty(tableName) && keyProps.parentRowData[tableName];
    else if (keyProps.groupedTableData) dataRowsTemp = keyProps.groupedTableData;
    else dataRowsTemp = record?.hasOwnProperty(tableName) && record[tableName];

    // In order to do column sorting in the user's language we need to get display values for all lookup types, and append these to the data set
    dataRowsTemp =
      dataRowsTemp &&
      dataRowsTemp.map((dataRow) => {
        let lookupDisplayValues = [];

        keyProps.fieldList.forEach((field) => {
          const fieldName = field.fieldName;
          const dataType = getDataTypeForField(fieldName);
          if (dataType === labelTypes.LOOKUP) {
            const lookupDisplayValue = getLookupValueForField(fieldName, dataRow[fieldName]);
            lookupDisplayValues.push({ fieldName, lookupDisplayValue });
          }
        });

        let dataRowExtra = { ...dataRow, lookupDisplayValues };
        return dataRowExtra;
      });
    if (dataRowsTemp?.length > 0 && !hasInitialSortSetRef.current) {
      hasInitialSortSetRef.current = true;
      let initialSortData = [...dataRowsTemp];
      // loop backwards through fields sorting by last (or 5th if more than 5 cols)
      // and finishing sort with the second (the first column we do not use as it's just for ID)
      const maxCol = Math.min(5, keyProps.fieldList.length - 1);
      for (let i = maxCol; i >= 0; i--) {
        initialSortData = sortColumn(keyProps.fieldList[i].fieldName, initialSortData, true);
      }
      dataRowsTemp = initialSortData;
    }
    setSortCol(keyProps.fieldList[0].fieldName);
    setDataRows(dataRowsTemp);
  }, [keyProps.parentRowData, keyProps.groupedTableData, record?.[tableName]]);

  // Need to handle state where there are no data rows and when the system needs to provide pre-filled adding rows for certain tables
  useEffect(() => {
    if (dataRows?.length === 0 && matterState.suggestedMatterData) {
      const suggestedMatterData = matterState.suggestedMatterData.filter(
        (smd) =>
          smd.parent === tableName &&
          (smd.matterTypeIds.length === 0 || smd.matterTypeIds.some((mt) => mt === record.matter_MatterTypeId))
      );

      if (suggestedMatterData.length > 0) {
        const addingRowsLocal = suggestedMatterData.map((smd) => {
          const fieldValuePair = { field: smd.dataFieldName, value: smd.value };
          const newAddRowData = initializeAddingRowData(record, keyProps.fieldList, nanoid(), fieldValuePair);
          return newAddRowData;
        });

        setAddingRowsData(addingRowsLocal);
        setIsAddingRows(true);
      }
    }
    // Remove any duplicate adding rows if a data row exists for that link type (Company Links table)
    else if (tableName === "companyLinks") {
      const addingRowsLocal = addingRowsData.filter(
        (addingRow) =>
          !dataRows.some((dataRow) =>
            idsAreEqual(addingRow.matterCompanyLink_LinkTypeId, dataRow.matterCompanyLink_LinkTypeId)
          )
      );
      setAddingRowsData(addingRowsLocal);
    }
    // and same for Legal Team
    else if (tableName === "legalTeamContactLinks") {
      const addingRowsLocal = addingRowsData?.filter(
        (addingRow) =>
          !dataRows?.some((dataRow) =>
            idsAreEqual(
              addingRow.matterLegalTeamContactLink_LegalTeamContactLinkTypeId,
              dataRow.matterLegalTeamContactLink_LegalTeamContactLinkTypeId
            )
          )
      );
      setAddingRowsData(addingRowsLocal);
    }
  }, [dataRows]);

  const sortColumn = (fieldName, dataToSort, isInitialSort) => {
    if (!dataToSort) {
      console.log("No data to sort for table: " + keyProps.tableName, " field: ", fieldName);
      return dataToSort;
    }
    let newSortAsc = true;
    if (!isInitialSort) newSortAsc = fieldName === sortCol ? !sortAsc : true;
    const field = keyProps.fieldList.find((field) => field.fieldName === fieldName);
    dataToSort.sort((a, b) => compareBySortCol(a, b, field, newSortAsc));
    if (!isInitialSort) {
      setSortAsc(newSortAsc);
      setSortCol(fieldName);
      setDataRows(dataToSort);
    }
    return dataToSort;
  };

  const handleColHeaderClick = (fieldNameToSort) => {
    sortColumn(fieldNameToSort, [...dataRows]);
  };

  const onAddRow = (parentRowData) => {
    dispatch(setSettingFocusFirstColumnTableName(tableName));
    setIsAddingRows(true);
    let initialAddingRowData = initializeAddingRowData(record, keyProps.fieldList, addingRowsData.length);
    if (parentRowData) initialAddingRowData.parentRowId = parentRowData.id;
    if (keyProps.parentIdFieldName) {
      initialAddingRowData[keyProps.parentIdFieldName] = parentRowData[keyProps.parentIdFieldName];
    }
    setAddingRowsData([...addingRowsData, initialAddingRowData]);
  };

  const handleSaveNewRow = (addingRowData) => {
    let addingRowsLocal = [...addingRowsData];
    addingRowsLocal = addingRowsLocal.filter((addingRow) => addingRow.index !== addingRowData.index);
    setAddingRowsData(addingRowsLocal);
  };

  const renderRows =
    dataRows &&
    dataRows.map((rowData) => (
      <PanelTableRow
        key={rowData.id}
        keyProps={keyProps}
        rowData={rowData}
        indexRow={rowData.id}
        onChange={onChange}
        onDelete={onDelete}
      />
    ));

  const renderAddingRows =
    addingRowsData &&
    addingRowsData.map((addingRowData) => {
      return (
        <PanelTableRow
          key={addingRowData.index}
          keyProps={keyProps}
          rowData={addingRowData}
          addingRowIndex={addingRowData.index}
          isAdding={true}
          onSaveNewRow={() => handleSaveNewRow(addingRowData)}
          onSetAddRowValidationMessage={(m) => setAddRowValidationMessage(m)}
          onChange={onChange}
        />
      );
    });

  // FORMATTING & RENDERING
  let message = "";
  const classSectionBase = "data-panel__validation";
  let classModifier = "";
  let errorStringArray = [];
  const section = record?.sections?.find((section) => section.sectionName === keyProps.sectionName);
  if (section?.validationStates) {
    const tableValidationErrors = section.validationStates.filter((vs) => vs.state === validationStates.ERROR);
    tableValidationErrors.forEach((tve) => {
      const errorString = localeState.translations[tve.string];
      errorStringArray.push(errorString);
    });
  }
  if (section?.fields) {
    section.fields.forEach((field) => {
      field.validationStates.forEach((vs) => {
        if (vs.state === validationStates.ERROR) {
          const errorString = localeState.translations[vs.string];
          errorStringArray.push(errorString);
        }
      });
    });

    errorStringArray = [...new Set([...errorStringArray])]; // De-dupe

    message = errorStringArray.join(". ");
    if (message.length > 0) classModifier = "--error";
  }

  const renderValidationErrors = message.length > 0 && !keyProps.parentTableName && (
    <div className={`${classSectionBase} ${classSectionBase}${classModifier}`}>{message}</div>
  );

  const tableClassNameBase = "data-panel__table";
  let tableClassName = tableClassNameBase;
  if (keyProps.noActionBar) tableClassName += ` ${tableClassNameBase}--sub-table`;

  const innerTableClassNameBase = "data-panel__table-inner";
  let innerTableClassName = innerTableClassNameBase;
  const scrollableContainerClassNameBase = "data-panel__table-scrollable-container";
  let scrollableContainerClassName = scrollableContainerClassNameBase;
  if (tableName === "documents" && matterState?.showExtraFieldsDocuments) {
    innerTableClassName += ` ${innerTableClassNameBase}--document-emails`;
    scrollableContainerClassName += ` ${scrollableContainerClassNameBase}--document-emails`;
  }

  let displayAddButton = true;
  if (keyProps.isPanelReadOnly || keyProps.parentRowData || tableName === "documents" || tableName === "links")
    displayAddButton = false;
  else if (keyProps.permissions) {
    displayAddButton = (keyProps.permissions & permissionTypes.CREATE) === permissionTypes.CREATE;
  }

  return (
    <>
      {keyProps.childTable && keyProps.tableName === "contactLinks" && dataRows.length === 0 && !isAddingRows ? null : (
        <div className={tableClassName}>
          {record && (
            <>
              {keyProps.hasSpecialActionBar && idsAreEqual(keyProps.sectionId, 90) && (
                <div className="data-panel__action-bar">{children}</div>
              )}
              <div className={scrollableContainerClassName}>
                {renderValidationErrors}

                <table className={innerTableClassName}>
                  <thead>
                    <PanelTableHeader
                      keyProps={keyProps}
                      onColHeaderClick={handleColHeaderClick}
                      sortCol={sortCol}
                      sortAsc={sortAsc}
                    />
                  </thead>
                  <tbody>
                    {renderRows}
                    {isAddingRows && !keyProps.isPanelReadOnly && renderAddingRows}
                  </tbody>
                </table>
              </div>
              {addRowValidationMessage.length > 0 && (
                <div className="data-panel__table-footer data-panel__table-footer--error">
                  {" "}
                  {addRowValidationMessage}{" "}
                </div>
              )}
              {displayAddButton && (
                <div className="data-panel__action-bar">
                  <div className="data-panel__table-add-button clickable" onClick={onAddRow}>
                    <Tooltip content={t("Add a new row of data")} direction="right">
                      <>
                        {t("Add")}&nbsp;
                        <FaPlusCircle />
                      </>
                    </Tooltip>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

PanelTable.propTypes = {
  keyProps: PropTypes.object,
  rowData: PropTypes.object,
  groupedTableData: PropTypes.object,
  addingRowIndex: PropTypes.number,
  indexRow: PropTypes.number,
  children: PropTypes.node,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default PanelTable;
