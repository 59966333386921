import { FaEdit } from "react-icons/fa";
import ToolbarButton from "./ToolbarButton";
import { useState } from "react";
import { OwnerTypes, getOwnerTypes } from "utilities/reportEditor";
import { useSelector } from "react-redux";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { t } from "locale/dictionary";
import SuperSelect from "components/global/SuperSelect";
import PopupHeader from "components/global/PopupHeader";
import { saveReportFieldChanges, saveReportOwnerType } from "api/report";
import { useEffect } from "react";
import { superSelectOperationTypes } from "utilities/constants";
import { filterOutOnId, idsAreEqual } from "utilities/stringAndArray";

function ToolbarProperties({ report }) {
  const accounts = useSelector((state) => state.lookup.global.find((lookup) => lookup.name === "Accounts"));
  const groups = useSelector((state) => state.lookup.global.find((lookup) => lookup.name === "Groups"));
  const matterTypes = getAllLookupValuesForSource("MatterTypes");
  const queryTypes = getAllLookupValuesForSource("QueryTypes");
  const ownerTypeOptions = getOwnerTypes();

  const [name, setName] = useState(report?.name);
  const [description, setDescription] = useState(null);
  const [matterTypeIds, setMatterTypeIds] = useState(null);
  const [queryTypeId, setQueryTypeId] = useState(null);

  const [ownerType, setOwnerType] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [groupId, setGroupId] = useState(null);

  const [canSave, setCanSave] = useState(false);
  const [hasSavedOk, setHasSavedOk] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (
      name !== report.name ||
      description !== report.description ||
      queryTypeId !== report.queryTypeId ||
      matterTypeIds !== report.matterTypeIds ||
      ownerType !== report.ownerType ||
      accountId !== report.accountId ||
      groupId !== report.groupId
    ) {
      setHasSavedOk(false);
      setCanSave(true);
    }
  }, [name, description, queryTypeId, matterTypeIds, ownerType, accountId, groupId]);

  // useEffect to initialize state on change in report
  useEffect(() => {
    setName(report.name);
    setDescription(report.description);
    setQueryTypeId(report.queryTypeId);
    setMatterTypeIds(report.matterTypeIds);
    setOwnerType(report.ownerType);
    setAccountId(report.accountId);
    setGroupId(report.groupId);
  }, [report]);

  const handleSaveClick = async () => {
    if (name !== report.name) saveReportFieldChanges("name", name, report.name);
    if (description !== report.description) saveReportFieldChanges("description", description, report.description);
    if (matterTypeIds !== report.matterTypeIds)
      saveReportFieldChanges("matterTypeIds", matterTypeIds, report.matterTypeIds);
    if (queryTypeId !== report.queryTypeId) saveReportFieldChanges("queryTypeId", queryTypeId, report.queryTypeId);

    let ownerIdToSave = null;
    if (ownerType === OwnerTypes.ACCOUNT) ownerIdToSave = accountId;
    if (ownerType === OwnerTypes.GROUP) ownerIdToSave = groupId;
    saveReportOwnerType(ownerType, ownerIdToSave);
    setHasSavedOk(true);
    setCanSave(false);
  };

  const handleClosePopupClick = () => {
    // reinitialize all state
    setName(report.name);
    setDescription(report.description);
    setMatterTypeIds(report.matterTypeIds);
    setOwnerType(report.ownerType);
    setQueryTypeId(report.queryTypeId);
    setAccountId(report.accountId);
    setGroupId(report.groupId);
    setHasSavedOk(false);
    setCanSave(false);
    setShowPopup(false);
  };

  const handleChangeMultiMatter = (optionId, op) => {
    let optionIds = matterTypeIds ? [...matterTypeIds] : [];
    if (op === superSelectOperationTypes.ADD) {
      optionIds.push(optionId);
    } else {
      optionIds = filterOutOnId(optionIds, optionId);
    }
    setMatterTypeIds(optionIds);
  };

  const renderForm = (
    <>
      <div className="modal__selection-grid modal__selection-grid--100">
        <p>{t("Name")}</p>
        <textarea
          className="modal__input"
          type="text"
          rows="3"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <p>{t("Description")}</p>
        <textarea
          className="modal__input"
          type="text"
          rows="4"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <p>{t("Data Type")}</p>
        {queryTypes && (
          <div className="modal__select">
            <SuperSelect
              options={queryTypes}
              selectedOptionId={queryTypeId}
              onChange={(optionId) => setQueryTypeId(optionId)}
              isMultiValued={false}
            />
          </div>
        )}
        <p>{t("Matter Types")}</p>
        {matterTypes && (
          <div className="modal__select">
            <SuperSelect
              options={matterTypes}
              selectedOptionIds={matterTypeIds}
              onChangeMulti={handleChangeMultiMatter}
              isMultiValued={true}
            />
          </div>
        )}
        <p>{t("Owner Type")}</p>
        <div className="modal__select">
          <SuperSelect
            id="ownerType"
            selectedOptionId={ownerType}
            options={ownerTypeOptions}
            onChange={(optionId) => setOwnerType(optionId)}
          />
        </div>
        {ownerType === OwnerTypes.ACCOUNT && (
          <>
            <p>{t("Owner")}</p>
            <div className="modal__select">
              <SuperSelect
                id="accountOwnerId"
                selectedOptionId={accountId}
                options={accounts?.lookup?.map((account) => {
                  return { id: account.id, displayValue: account.name };
                })}
                onChange={(optionId) => setAccountId(optionId)}
              />
            </div>
          </>
        )}
        {ownerType === OwnerTypes.GROUP && (
          <>
            <p>{t("Group")}</p>
            <div className="modal__select">
              <SuperSelect
                id="groupOwnerId"
                selectedOptionId={groupId}
                options={groups?.lookup?.map((group) => {
                  return { id: group.id, displayValue: group.name };
                })}
                onChange={(optionId) => setGroupId(optionId)}
              />
            </div>
          </>
        )}
      </div>
      {hasSavedOk && (
        <div className="modal__selection-row modal__selection-row--success">{t("Properties saved successfully")}</div>
      )}
      {canSave ? (
        <div className="modal__selection-grid modal__selection-grid--50">
          <button onClick={handleSaveClick}>{t("SAVE")}</button>
          <button onClick={handleClosePopupClick}>{hasSavedOk ? t("Close") : t("Cancel")}</button>
        </div>
      ) : (
        <div className="modal__selection-row">
          <button onClick={handleClosePopupClick}>{t("Close")}</button>
        </div>
      )}
    </>
  );

  const renderPopup = (
    <div className="modal-mask">
      <div className="modal__selection-popup modal__selection-popup--wide">
        <PopupHeader title="Report Properties" onClose={handleClosePopupClick} />
        <div className="modal__selection-body">{renderForm}</div>
      </div>
    </div>
  );
  return (
    <>
      <ToolbarButton
        tooltipText="Edit report basic properties"
        tooltipDirection="right"
        onClick={() => setShowPopup(true)}
      >
        <FaEdit className="button__icon" />
      </ToolbarButton>
      {showPopup && renderPopup}
    </>
  );
}

export default ToolbarProperties;
