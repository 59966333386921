import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import { addMailMergeTableObjectColumn, moveListField } from "redux/reportSlice";
import { getDisplayNameForField } from "utilities/reportEditor";
import Modal from "components/global/Modal";

function TableObjectColumn({ objectId, field, listIndex, matterTypeIds, maxEntries }) {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const [cellDisplayValue, setCellDisplayValue] = useState("");
  const [displayProperties, setDisplayProperties] = useState(false);

  useEffect(() => {
    if (field?.dataFieldName) {
      setCellDisplayValue(getDisplayNameForField(field?.dataFieldName, matterTypeIds));
    } else {
      setCellDisplayValue(" ");
    }
  }, [field?.dataFieldName, matterTypeIds]);

  const handlePropertiesClick = () => {
    setDisplayProperties((prevState) => !prevState);
  };

  const [, drop] = useDrop(
    () => ({
      accept: ["box", "self"],
      drop(item) {
        switch (item.type) {
          case "box":
            dispatch(
              addMailMergeTableObjectColumn({
                objectId,
                fieldName: item.name,
                toIndex: listIndex,
                maxEntries,
              })
            );
            break;
          case "self":
            dispatch(moveListField({ objectId, fromIndex: item.listIndex, toIndex: listIndex }));
            break;
          default:
            break;
        }
      },
    }),
    [listIndex, maxEntries]
  );

  const [, drag] = useDrag(
    () => ({
      type: "self",
      item: { type: "self", listIndex, field },
    }),
    [listIndex, field?.id]
  );

  drag(drop(ref));

  return (
    <div ref={ref} className="quick-report-grid__cell" onClick={handlePropertiesClick}>
      {cellDisplayValue}
      <Modal title="Properties" closeOnly={false} onConfirm={handlePropertiesClick}>
        <div>Properties</div>
      </Modal>
    </div>
  );
}

TableObjectColumn.propTypes = {
  objectId: PropTypes.number,
  field: PropTypes.object,
  listIndex: PropTypes.number,
  matterTypeIds: PropTypes.arrayOf(PropTypes.number),
  maxEntries: PropTypes.number,
};

export default TableObjectColumn;
