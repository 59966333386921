import { createSlice } from "@reduxjs/toolkit";

// Redux global state component for general app settings
export const appSlice = createSlice({
  name: "app",
  initialState: {
    theme: "dark",
    bearerToken: null,
    customerId: null,
    userId: null,
    isSysAdmin: false,
    isCustAdmin: false,
    documentTitle: "Cedar IP",
    toasts: [],
    isShowingFullScreenModal: false,
    allMatterTypes: [],
    loadingList: [],
    dataFieldsLoaded: false,
    isLoading: true,
    thirdPartyKeys: null,
    permissions: [],
  },
  reducers: {
    addToLoadingList: (state, action) => {
      state.loadingList.push(action.payload);
    },
    removeFromLoadingList: (state, action) => {
      const newLoadingList = state.loadingList.filter((item) => item !== action.payload);
      state.loadingList = newLoadingList;
      if (newLoadingList.length === 0 && state.dataFieldsLoaded === true) {
        state.isLoading = false;
      }
    },
    setDataFieldsLoaded: (state, action) => {
      state.dataFieldsLoaded = true;
      if (state.loadingList.length === 0) {
        state.isLoading = false;
      }
    },
    toggleTheme: (state) => {
      state.theme = state.theme === "dark" ? "light" : "dark";
    },
    setBearerToken: (state, action) => {
      state.bearerToken = "Bearer " + action.payload;
    },
    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setAllMatterTypes: (state, action) => {
      state.allMatterTypes = action.payload;
    },
    setThirdPartyKeys: (state, action) => {
      state.thirdPartyKeys = action.payload;
    },
    setCustAdmin: (state, action) => {
      state.isCustAdmin = action.payload;
    },
    setSysAdmin: (state, action) => {
      state.isSysAdmin = action.payload;
    },
    setIsShowingFullScreenModal: (state, action) => {
      state.isShowingFullScreenModal = action.payload;
    },
    setDocumentTitle: (state, action) => {
      state.documentTitle = action.payload;
    },
    addToast: (state, action) => {
      const toast = { id: action.payload.id, message: action.payload.message, severity: action.payload.severity };
      let toasts = state.toasts;
      toasts.push(toast);
      state.toasts = toasts;
    },
    deleteToast: (state, action) => {
      let toasts = state.toasts;
      toasts = toasts.filter((toast) => toast.message !== action.payload);
      state.toasts = toasts;
    },
    // showToast: (state, action) => {
    //   let toastToShow = state.toasts.find((toast) => toast.message === action.payload.message);
    //   toastToShow.hidden = false;
    // },
    // hideToast: (state, action) => {
    //   let toastToHide = state.toasts.find((toast) => toast.message === action.payload.message);
    //   toastToHide.hidden = true;
    // },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
});

export const {
  addToLoadingList,
  removeFromLoadingList,
  setDataFieldsLoaded,
  toggleTheme,
  setBearerToken,
  setCustomerId,
  setUserId,
  setIsShowingFullScreenModal,
  addToast,
  deleteToast /*, showToast, hideToast */,
  setSysAdmin,
  setCustAdmin,
  setDocumentTitle,
  setAllMatterTypes,
  setThirdPartyKeys,
  setPermissions,
} = appSlice.actions;

export default appSlice.reducer;
