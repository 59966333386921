import { exportedSectionDefinitions, matterTypes, referenceTypes, sectionTypes } from "./constants";
import { getAllListTypes, getAllReferenceTypes } from "./lookup";
import { hasMatchingId, idsAreEqual } from "./stringAndArray";

/* Handy reference for common section IDs
  1/2/3 - Core Details
  19 - Additional Details (dynamically generated)
  20/21 - Goods/Services
  30 - Actions
  40 - Comments
  50 - Company Links
  60 - Legal Team
  62 - Authors/Inventors/Designers
  70 - Categories
  80 - Connected Matters
  90 - Documents
  91 - Images
  110 - Record Status Details  
  */

/* NOTE: This is just a handy local reference for matter types. These codes may change in the DB

  */

export const defaultSectionOrder = [
  { matterTypeId: matterTypes.COPYRIGHTS, sectionOrder: [1, 206, 19, 80, 30, 40, 50, 60, 62, 70, 90, 91, 110] },
  { matterTypeId: matterTypes.DESIGNS, sectionOrder: [5, 210, 19, 24, 90, 91, 50, 80, 60, 62, 30, 40, 70, 110] },
  { matterTypeId: matterTypes.DOMAINNAME, sectionOrder: [1, 209, 19, 80, 30, 40, 50, 60, 70, 90, 110] },
  { matterTypeId: matterTypes.PATENT, sectionOrder: [7, 212, 19, 80, 24, 91, 30, 40, 50, 62, 60, 70, 90, 110] },
  { matterTypeId: matterTypes.SOCIALMEDIA, sectionOrder: [4, 80, 19, 91, 50, 30, 60, 40, 70, 90, 110] },
  { matterTypeId: matterTypes.TRADEMARK, sectionOrder: [3, 200, 19, 20, 30, 40, 50, 60, 70, 80, 90, 91, 100, 110] },
  { matterTypeId: matterTypes.CUSTOMSRECORDALS, sectionOrder: [1, 211, 19, 80, 30, 95, 40, 50, 60, 70, 90, 91, 110] },
  { matterTypeId: matterTypes.GENERALMATTER, sectionOrder: [1, 207, 19, 80, 50, 30, 40, 60, 70, 90, 91, 110] },
  {
    matterTypeId: matterTypes.OTHERPARTYREFERENCE,
    sectionOrder: [1, 205, 19, 22, 80, 50, 60, 30, 40, 90, 91, 70, 110],
  },
  { matterTypeId: matterTypes.ASSIGNMENTANDRECORDALS, sectionOrder: [1, 202, 19, 50, 60, 80, 30, 40, 70, 90, 110] },
  { matterTypeId: matterTypes.BRANDPROTECTION, sectionOrder: [8, 22, 19, 80, 50, 30, 95, 60, 40, 70, 90, 91, 110] },
  { matterTypeId: matterTypes.CONTRACT, sectionOrder: [1, 203, 19, 204, 23, 80, 30, 40, 50, 60, 70, 90, 110] },
  { matterTypeId: matterTypes.CLEARANCESEARCHES, sectionOrder: [1, 208, 19, 80, 22, 90, 91, 50, 30, 60, 40, 70, 110] },
  { matterTypeId: matterTypes.DISPUTE, sectionOrder: [2, 201, 19, 21, 80, 30, 40, 50, 60, 70, 90, 110] },
  { matterTypeId: matterTypes.COMPANY, sectionOrder: [6, 213, 19, 61, 30, 40, 50, 80, 90, 91, 70, 110] },
];

const sectionDefinitionsBase = [
  {
    // DEFAULT VERSION OF CORE DETAILS
    id: 1,
    name: "Core Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Name", width: 66 },
      { fieldName: `REF${referenceTypes.FILE}`, width: 33, referenceTypeId: referenceTypes.FILE },
      { fieldName: "matter_Description", width: 66 },
      { fieldName: `REF${referenceTypes.CASE}`, width: 33, referenceTypeId: referenceTypes.CASE },
      { fieldName: "countries", width: 33 },
      { fieldName: "matter_RecordTypeId", width: 33 },
      { fieldName: "matter_RecordSubtypeId", width: 33 },
      { fieldName: "matter_StatusId", width: 33 },
      { fieldName: "matter_SubstatusId", width: 33 },
      { fieldName: "matter_DateOfLastStatus", width: 33 },
    ],
  },
  {
    // SPECIAL DISPUTE VERSION
    id: 2,
    name: "Core Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Name", width: 66 },
      { fieldName: `REF${referenceTypes.FILE}`, width: 33, referenceTypeId: referenceTypes.FILE },
      { fieldName: "matter_Description", width: 100 },
      { fieldName: "countries", width: 33 },
      { fieldName: "matter_RecordTypeId", width: 33 },
      { fieldName: "matter_RecordSubtypeId", width: 33 },
      { fieldName: "matter_StatusId", width: 33 },
      { fieldName: "matter_SubstatusId", width: 33 },
      { fieldName: "matter_DateOfLastStatus", width: 33 },
      { fieldName: "matter_PublicationDate", width: 33 },
      { fieldName: "matter_DateActionFiled", width: 33 },
      { fieldName: `REF${referenceTypes.CASE}`, width: 33, referenceTypeId: referenceTypes.CASE },
    ],
  },
  {
    // SPECIAL TRADEMARK VERSION
    id: 3,
    name: "Core Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Name", width: 100 },
      { fieldName: `REF${referenceTypes.FILE}`, width: 33, referenceTypeId: referenceTypes.FILE },
      { fieldName: `REF${referenceTypes.CASE}`, width: 33, referenceTypeId: referenceTypes.CASE },
      { fieldName: "countries", width: 33 },
      { fieldName: "matter_StatusId", width: 33 },
      { fieldName: "matter_SubstatusId", width: 33 },
      { fieldName: "matter_ApplicationNo", width: 33 },
      { fieldName: "matter_ApplicationDate", width: 33 },
      { fieldName: "TRADEMARKTYPE", width: 33, isListType: true },
      { fieldName: "matter_RegistrationNo", width: 33 },
      { fieldName: "matter_RegistrationDate", width: 33 },
      { fieldName: "matter_GrantDate", width: 33 },
      //{ fieldName: "TRADEMARKCATEGORY", width: 33, isListType: true },
      { fieldName: "matter_RecordTypeId", width: 33 },
      { fieldName: "matter_RecordSubtypeId", width: 33 },
      { fieldName: "matter_DateOfLastStatus", width: 33 },
      { fieldName: "matter_WipoNotificationDate", width: 33 },
    ],
  },
  {
    // SPECIAL SOCIAL MEDIA VERSION
    id: 4,
    name: "Core Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Name", width: 66 },
      { fieldName: `REF${referenceTypes.FILE}`, width: 33, referenceTypeId: referenceTypes.FILE },
      { fieldName: "matter_Description", width: 66 },
      { fieldName: `REF${referenceTypes.CASE}`, width: 33, referenceTypeId: referenceTypes.CASE },
      { fieldName: "countries", width: 33 },
      { fieldName: "matter_RecordTypeId", width: 33 },
      { fieldName: "matter_RecordSubtypeId", width: 33 },
      { fieldName: "matter_Website", width: 33 },
      { fieldName: "matter_StatusId", width: 33 },
      { fieldName: "matter_SubstatusId", width: 33 },
      { fieldName: "matter_DateOfLastStatus", width: 33 },
      { fieldName: "matter_HashTagsUsed", width: 33 },
    ],
  },
  {
    // SPECIAL DESIGN VERSION
    id: 5,
    name: "Core Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Name", width: 100 },
      { fieldName: "matter_Description", width: 100 },
      { fieldName: "countries", width: 33 },
      { fieldName: "matter_StatusId", width: 33 },
      { fieldName: "matter_SubstatusId", width: 33 },
      { fieldName: "matter_ApplicationNo", width: 33 },
      { fieldName: "matter_ApplicationDate", width: 33 },
      { fieldName: `REF${referenceTypes.FILE}`, width: 33, referenceTypeId: referenceTypes.FILE },
      { fieldName: "matter_RegistrationNo", width: 33 },
      { fieldName: "matter_RegistrationDate", width: 33 },
      { fieldName: `REF${referenceTypes.CASE}`, width: 33, referenceTypeId: referenceTypes.CASE },
      { fieldName: "matter_GrantDate", width: 33 },
      { fieldName: "matter_RecordTypeId", width: 33 },
      { fieldName: "matter_RecordSubtypeId", width: 33 },
      { fieldName: "matter_DateOfLastStatus", width: 33 },
      { fieldName: "matter_FinalOfficeActionDate", width: 33 },
      { fieldName: "matter_FirstFiling", width: 33 },
      { fieldName: "matter_OfficeActionDate", width: 33 },
      { fieldName: "matter_Translation", width: 33 },
      { fieldName: "matter_WipoNotificationDate", width: 33 },
    ],
  },
  {
    // SPECIAL COMPANY VERSION
    id: 6,
    name: "Core Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Name", width: 66 },
      // { fieldName: "matter_CompanyOrIndividual", width: 25 },
      { fieldName: `REF${referenceTypes.FILE}`, width: 33, referenceTypeId: referenceTypes.FILE },
      { fieldName: "matter_Address1", width: 66 },
      { fieldName: `REF${referenceTypes.CASE}`, width: 33, referenceTypeId: referenceTypes.CASE },
      { fieldName: "matter_Address2", width: 66 },
      { fieldName: "matter_Phone1", width: 33 },
      { fieldName: "matter_Address3", width: 66 },
      { fieldName: "matter_Phone2", width: 33 },
      { fieldName: "matter_City", width: 33 },
      { fieldName: "matter_State", width: 33 },
      { fieldName: "matter_Email", width: 33 },
      // { fieldName: "matter_Fax", width: 33 },
      { fieldName: "matter_PostalCode", width: 33 },
      { fieldName: "countries", width: 33 },
      { fieldName: "matter_Website", width: 33 },
      { fieldName: "matter_IncorporatedIn", width: 33 },
      { fieldName: "matter_RecordTypeId", width: 33 },
      { fieldName: "matter_RecordSubtypeId", width: 33 },
      { fieldName: "matter_StatusId", width: 33 },
      { fieldName: "matter_SubstatusId", width: 33 },
    ],
  },
  {
    // SPECIAL PATENT VERSION
    id: 7,
    name: "Core Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Name", width: 66 },
      { fieldName: "matter_FirstFiling", width: 33 },
      { fieldName: "matter_Description", width: 100 },
      { fieldName: "countries", width: 33 },
      { fieldName: "matter_StatusId", width: 33 },
      { fieldName: "matter_SubstatusId", width: 33 },
      // TODO { fieldName: "matter_InventorAssignmentCompleted", width: 33 },
      { fieldName: "matter_ApplicationNo", width: 33 },
      { fieldName: "matter_ApplicationDate", width: 33 },
      { fieldName: "matter_DateOfLastStatus", width: 33 },
      { fieldName: "matter_RegistrationNo", width: 33 },
      { fieldName: "matter_RegistrationDate", width: 33 },
      { fieldName: "matter_GrantDate", width: 33 },
      { fieldName: "matter_RecordTypeId", width: 33 },
      { fieldName: "matter_RecordSubtypeId", width: 33 },
      { fieldName: `REF${referenceTypes.FILE}`, width: 33, referenceTypeId: referenceTypes.FILE },
      { fieldName: `REF${referenceTypes.CASE}`, width: 33, referenceTypeId: referenceTypes.CASE },
      { fieldName: "matter_PublicationRegDate", width: 33 },
      { fieldName: "matter_PublicationDate", width: 33 },
      { fieldName: "matter_WipoNotificationDate", width: 33 },
    ],
  },
  {
    // SPECIAL BRAND PROTECTION VERSION
    id: 8,
    name: "Core Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Name", width: 66 },
      { fieldName: `REF${referenceTypes.FILE}`, width: 33, referenceTypeId: referenceTypes.FILE },
      { fieldName: "matter_Description", width: 66 },
      { fieldName: `REF${referenceTypes.CASE}`, width: 33, referenceTypeId: referenceTypes.CASE },
      { fieldName: "countries", width: 33 },
      { fieldName: "matter_RecordTypeId", width: 33 },
      { fieldName: "matter_RecordSubtypeId", width: 33 },
      { fieldName: "matter_StatusId", width: 33 },
      { fieldName: "matter_SubstatusId", width: 33 },
      { fieldName: "matter_DateOfLastStatus", width: 33 },
      { fieldName: "matter_GrayMarketImportationRestriction", width: 33 },
      { fieldName: "matter_ScopeOfCoverage", width: 33 },
    ],
  },
  {
    id: 20,
    name: "Classes and goods/services",
    type: sectionTypes.TABLE,
    hasSpecialActionBar: true,
    tableName: "goods",
    fieldList: [
      { fieldName: "matterGoods_Class", width: 5 },
      { fieldName: "matterGoods_Goods", width: 999, longText: true }, // This invalid value sets this col width to "auto" to allow it to fill out the total cols width to 100% when cols 3/4 are hidden
      { fieldName: "matterGoods_FirstUseDate", width: 15 },
      { fieldName: "matterGoods_FirstUseInCommerceDate", width: 15 },
      { fieldName: "matterGoods_GoodsTypeId", width: 15, isRequired: true },
      { fieldName: "matterGoods_LanguageCode", width: 15 },
      { fieldName: "matterGoods_Local", width: 5, hideDisplayName: true },
    ],
  },
  {
    id: 21,
    name: "Classes and goods/services in dispute",
    type: sectionTypes.TABLE,
    tableName: "goods",
    fieldList: [
      { fieldName: "matterGoods_Class", width: 5 },
      { fieldName: "matterGoods_Goods", width: 80, longText: true },
      { fieldName: "matterGoods_GoodsTypeId", width: 15, isRequired: true },
    ],
  },
  {
    id: 22,
    name: "Classes and goods/services of main interest",
    type: sectionTypes.TABLE,
    tableName: "goods",
    fieldList: [
      { fieldName: "matterGoods_Class", width: 5 },
      { fieldName: "matterGoods_Goods", width: 95, longText: true },
    ],
  },
  {
    id: 23,
    name: "Classes and goods/services affected",
    type: sectionTypes.TABLE,
    tableName: "goods",
    fieldList: [
      { fieldName: "matterGoods_Class", width: 5 },
      { fieldName: "matterGoods_Goods", width: 95, longText: true },
    ],
  },
  {
    id: 24,
    name: "Classification",
    type: sectionTypes.TABLE,
    tableName: "goods",
    fieldList: [
      { fieldName: "matterGoods_Class", width: 10 },
      { fieldName: "matterGoods_Goods", width: 90, longText: true },
    ],
  },
  {
    id: exportedSectionDefinitions.GOODS_FOR_BATCH_CREATE,
    name: "Classes and Goods",
    type: sectionTypes.TABLE,
    tableName: "goods",
    fieldList: [
      { fieldName: "matterGoods_Class", width: 10 },
      { fieldName: "matterGoods_Goods", width: 60, longText: true },
      { fieldName: "matterGoods_LanguageCode", width: 15 },
      { fieldName: "matterGoods_Local", width: 15, hideDisplayName: true },
    ],
  },
  {
    id: 30,
    name: "Actions",
    type: sectionTypes.TABLE,
    hasSpecialActionBar: true,
    tableName: "actions",
    fieldList: [
      {
        fieldName: "matterAction_ActionDate",
        width: 20,
        linkedAlertField: "matterAction_CompletedDate",
        isRequired: true,
      },
      { fieldName: "matterAction_ActionTypeId", width: 20, isRequired: true },
      { fieldName: "matterAction_Notes", width: 20, longText: true },
      { fieldName: "matterAction_CompletedDate", width: 15 },
      {
        fieldName: "contactLinks",
        width: 25,
        //isReadOnly: true,
        objectProps: {
          propsList: ["matterActionContactLink_FullName"],
          delimiter: " ",
        },
      },
    ],
  },
  {
    id: 40,
    name: "Comments",
    type: sectionTypes.TABLE,
    tableName: "comments",
    fieldList: [
      { fieldName: "matterComment_Date", width: 15, isRequired: true },
      { fieldName: "matterComment_CommentTypeId", width: 15, isRequired: true },
      { fieldName: "matterComment_Text", width: 35, longText: true, isRequired: true },
      { fieldName: "matterComment_CreatedBy", width: 15, isReadOnly: true, isHiddenWhenAdding: true },
      {
        fieldName: "contactLinks",
        width: 20,
        isReadOnly: true,
        objectProps: {
          propsList: ["matterCommentContactLink_FullName"],
          delimiter: " ",
        },
      },
    ],
  },
  // GIT CHECK
  {
    id: 50,
    name: "Company Links",
    type: sectionTypes.TABLE,
    hasSpecialActionBar: true,
    tableName: "companyLinks",
    fieldList: [
      { fieldName: "matterCompanyLink_LinkTypeId", width: 15, isRequired: true },
      { fieldName: "matterCompanyLink_MatterId", width: 0, isRequired: true },
      { fieldName: "matterCompanyLink_Name", width: 20, linkPropertyName: "custom", isReadOnly: true },
      { fieldName: "matterCompanyLink_AssignedDate", width: 25 },
      { fieldName: "matterCompanyLink_Address1", width: 30, isReadOnly: true },
      { fieldName: "matterCompanyLink_Country", width: 20, isReadOnly: true },
      { fieldName: "matterCompanyLink_Reference", width: 10 },
      {
        fieldName: "matterCompanyLink_Website",
        width: 20,
        linkPropertyName: "matterCompanyLink_Website",
        isReadOnly: true,
      },
    ],
    childTable: {
      type: sectionTypes.TABLE,
      tableName: "contactLinks",
      linkIdFieldName: "matterCompanyLinkContactLink_MatterContactId",
      parentIdFieldName: "matterCompanyLink_MatterId",
      //parentIdFieldName: "matterCompanyLinkContactLink_MatterContactId",
      parentIdOtherFieldName: "matterCompanyLinkContactLink_MatterId",
      fieldList: [
        { fieldName: "matterCompanyLinkContactLink_MatterContactId", width: 20 }, // Displays full name
        { fieldName: "matterCompanyLinkContactLink_CompanyLinkContactLinkTypeId", width: 20 },
        {
          fieldName: "matterCompanyLinkContactLink_Email",
          width: 30,
          isReadOnly: true,
          linkPropertyName: "matterCompanyLinkContactLink_Email",
        },
        { fieldName: "matterCompanyLinkContactLink_Phone1", width: 15, isReadOnly: true },
        { fieldName: "matterCompanyLinkContactLink_Phone2", width: 15, isReadOnly: true },
        { fieldName: "matterCompanyLinkContactLink_MatterId", width: 0 },
      ],
    },
  },
  {
    // DEFAULT VERSION
    id: exportedSectionDefinitions.LEGAL_TEAM,
    name: "Legal Team",
    type: sectionTypes.TABLE,
    tableName: "legalTeamContactLinks",
    fieldList: [
      { fieldName: "matterLegalTeamContactLink_LegalTeamContactLinkTypeId", width: 33, isRequired: true },
      { fieldName: "matterLegalTeamContactLink_MatterContactId", width: 33 }, // Displays full name
      { fieldName: "matterLegalTeamContactLink_Email", width: 33, isReadOnly: true },
      { fieldName: "matterLegalTeamContactLink_LegalTeam", width: 0 },
      { fieldName: "matterLegalTeamContactLink_MatterId", width: 0 },
    ],
  },
  {
    id: 61,
    name: "Contacts",
    type: sectionTypes.TABLE,
    tableName: "contacts",
    fieldList: [
      { fieldName: "matterContact_GivenName", width: 15 },
      { fieldName: "matterContact_FamilyName", width: 15 },
      { fieldName: "matterContact_Email", width: 20 },
      { fieldName: "matterContact_Phone1", width: 15 },
      { fieldName: "matterContact_Phone2", width: 15 },
      { fieldName: "matterContact_ContactPositionId", width: 20 },
      { fieldName: "matterContact_LegalTeam", width: 15, hideDisplayName: true },
    ],
  },
  {
    id: 62,
    name: "Inventors, Designers & Authors",
    type: sectionTypes.TABLE,
    tableName: "contactLinks",
    fieldList: [
      { fieldName: "matterContactLink_ContactLinkTypeId", width: 15 },
      {
        fieldName: "matterContactLink_FullName",
        width: 15,
        isReadOnly: true,
        linkPropertyName: "custom",
      },
      { fieldName: "matterContactLink_Email", width: 25, isReadOnly: true },
      { fieldName: "matterContactLink_Phone1", width: 15, isReadOnly: true },
      { fieldName: "matterContactLink_Phone2", width: 15, isReadOnly: true },
    ],
  },
  // Categories (ID: 70) - See special handling below - fields are added dynamically
  // Connected Matters (ID: 80) - Format and fields are handled within special MatterDataPanelTableLinks component
  {
    id: 80,
    name: "Linked Matters",
    type: sectionTypes.TABLE,
    tableName: "links",
    hasSpecialActionBar: true,
    fieldList: [],
  },
  {
    id: 90,
    name: "Documents",
    type: sectionTypes.TABLE,
    tableName: "documents",
    hasSpecialActionBar: true,
    fieldList: [
      //{ fieldName: "matterDocument_Type", width: 20 },
      { fieldName: "matterDocument_Description", width: 10 },
      { fieldName: "matterDocument_DocumentTypeId", width: 5 },
      { fieldName: "matterDocument_UtcDateFileAdded", width: 5, isReadOnly: true, isHiddenWhenAdding: true },
      { fieldName: "matterDocument_Filename", width: 10, isReadOnly: true },
      { fieldName: "matterDocument_EmailDate", width: 5, isReadOnly: true, isHiddenWhenAdding: true },
      { fieldName: "matterDocument_EmailSubject", width: 5, isReadOnly: true, isHiddenWhenAdding: true },
      { fieldName: "matterDocument_EmailFrom", width: 5, isReadOnly: true, isHiddenWhenAdding: true },
      { fieldName: "matterDocument_EmailTo", width: 5, isReadOnly: true, isHiddenWhenAdding: true },
      { fieldName: "matterDocument_EmailImportance", width: 5, isReadOnly: true, isHiddenWhenAdding: true },
      //{ fieldName: "matterDocument_ByteCount", width: 5, isReadOnly: true },
    ],
  },
  {
    id: 91,
    name: "Images",
    type: sectionTypes.THUMBNAILS,
    tableName: "images",
    hasSpecialActionBar: true,
    fieldList: [
      { fieldName: "matterImage_Description" },
      { fieldName: "matterImage_Filename" },
      { fieldName: "matterImage_ImageId" },
      { fieldName: "matterImage_Primary" },
      { fieldName: "matterImage_SortIndex" },
    ],
  },
  {
    id: 95,
    name: "Infringements",
    type: sectionTypes.TABLE,
    tableName: "infringements",
    fieldList: [
      { fieldName: "matterInfringement_NotifiedDate", width: 20 },
      { fieldName: "matterInfringement_CountryId", width: 20 },
      { fieldName: "matterInfringement_Description", width: 20, linkPropertyName: "matterDocument_Url" },
      { fieldName: "matterInfringement_Location", width: 20 },
      { fieldName: "matterInfringement_URL", width: 20 },
    ],
  },
  {
    id: 100,
    name: "Mark Details Sections",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Description", width: 100 },
      { fieldName: "matter_Limitations", width: 33 },
      { fieldName: "matter_Disclaimer", width: 33 },
      { fieldName: "matter_ColorsClaimed", width: 33 },
      { fieldName: "matter_Transliteration", width: 50 },
      { fieldName: "matter_Translation", width: 50 },
      { fieldName: "matter_MarkInUse", width: 33 },
      { fieldName: "matter_Series", width: 33 },
    ],
  },
  {
    id: 110,
    name: "Record Status Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_ClosedDate", width: 33 },
      { fieldName: "matter_ClosedReason", width: 33 },
      { fieldName: "matter_Archived", width: 33 },
    ],
  },
  {
    id: 200,
    name: "Prosecution",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_FirstFiling", width: 33 },
      { fieldName: "matter_FinalOfficeActionDate", width: 33 },
      { fieldName: "matter_OfficeActionDate", width: 33 },
      { fieldName: "matter_NoticeOfAllowanceDate", width: 33 },
      { fieldName: "CURRENTFILINGBASIS", width: 33, isListType: true },
      { fieldName: "ORIGINALFILINGBASIS", width: 33, isListType: true },
      { fieldName: "matter_StatementOfUseDate", width: 33 },
      { fieldName: "matter_PublicationDate", width: 33 },
      { fieldName: "matter_PublicationRegDate", width: 33 },
    ],
  },
  {
    id: 201,
    name: "Dispute Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Resolution", width: 33 },
      { fieldName: "matter_DisputeNumber", width: 33 },
      { fieldName: "matter_CaseNumber", width: 33 },
      { fieldName: "matter_Court", width: 33 },
      { fieldName: "matter_CourtContactDetails", width: 33 },
    ],
  },
  {
    id: 202,
    name: "Assignment Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_ApplicationDate", width: 33 },
      { fieldName: "matter_PublicationDate", width: 33 },
      { fieldName: "matter_Goodwill", width: 33 },
      { fieldName: "matter_Consideration", width: 33 },
      { fieldName: "matter_StampDutyDeclaration", width: 33 },
      { fieldName: "matter_DeedDate", width: 33 },
      { fieldName: "matter_TransferDate", width: 33 },
      { fieldName: "matter_EffectiveDate", width: 33 },
      { fieldName: "matter_RecordedDate", width: 33 },
    ],
  },
  {
    id: 203,
    name: "Contract Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_DateSignedOwner", width: 33 },
      { fieldName: "matter_DateSignedOtherParty", width: 33 },
      { fieldName: "matter_EffectiveDate", width: 33 },
      { fieldName: "matter_OptionToExtend", width: 33 },
      { fieldName: "matter_Exclusive", width: 33 },
      { fieldName: "matter_Goodwill", width: 33 },
      { fieldName: "CONTRACTROYALTIES", width: 33, isListType: true },
      // { fieldName: "matter_Jurisdictions", width: 66 },
      { fieldName: "matter_RoyaltyMinimumAccount", width: 33 },
      { fieldName: "matter_RoyaltyPercentage", width: 33 },
      { fieldName: "matter_RoyaltyFrequencyId", width: 33, sortValuesById: true },
      { fieldName: "matter_RecordedDate", width: 33 },
    ],
  },
  {
    id: 204,
    name: "Contract Terms",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Consideration", width: 100 },
      { fieldName: "matter_Terms", width: 100 },
      { fieldName: "matter_TermsOfExtension", width: 100 },
      { fieldName: "matter_ScopeOfCoverage", width: 100 },
    ],
  },
  {
    id: 205,
    name: "Other Party Record Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_FirstFiling", width: 33 },
      { fieldName: "matter_ApplicationNo", width: 33 },
      { fieldName: "matter_ApplicationDate", width: 33 },
      { fieldName: "matter_PublicationDate", width: 33 },
      { fieldName: "matter_RegistrationNo", width: 33 },
      { fieldName: "matter_RegistrationDate", width: 33 },
      { fieldName: "matter_GrantDate", width: 33 },
      { fieldName: "matter_Translation", width: 33 },
      { fieldName: "matter_Transliteration", width: 33 },
    ],
  },
  {
    id: 206,
    name: "Copyright Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_RegistrationNo", width: 33 },
      { fieldName: "matter_WorkCreatedDate", width: 33 },
      { fieldName: "matter_WorkCompletedDate", width: 33 },
      { fieldName: "matter_ApplicationDate", width: 33 },
      { fieldName: "matter_PublicationDate", width: 33 },
      { fieldName: "matter_ReleaseDate", width: 33 },
      { fieldName: "matter_RegistrationDate", width: 33 },
      { fieldName: "matter_RecordedDate", width: 33 },
      { fieldName: "matter_ExecutionDate", width: 33 },
    ],
  },
  {
    // For GENERAL MATTERS
    id: 207,
    name: "Additional Details",
    type: sectionTypes.FORM,
    fieldList: [{ fieldName: "matter_ArchiveReference", width: 100 }],
  },
  {
    // For SEARCHES
    id: 208,
    name: "Additional Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_Translation", width: 33 },
      { fieldName: "matter_Transliteration", width: 33 },
      { fieldName: "matter_PhoneticEquivalents", width: 33 },
      { fieldName: "matter_SearchedVariations", width: 33 },
      { fieldName: "matter_OrderedDate", width: 33 },
      { fieldName: "matter_CompletedSearchDate", width: 33 },
      { fieldName: "URGENCY", width: 33, isListType: true },
    ],
  },
  {
    id: 209,
    name: "Domain Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_RegistrationDate", width: 33 },
      { fieldName: "PRIVACY", width: 33, isListType: true },
      { fieldName: "PROTECTION", width: 33, isListType: true },
      { fieldName: "DNSSEC", width: 33, isListType: true },
      { fieldName: "matter_NameServer", width: 33 },
    ],
  },
  {
    id: 210,
    name: "Design Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_LanguageCode", width: 33 },
      { fieldName: "matter_SecondLanguageCode", width: 33 },
      { fieldName: "matter_Series", width: 33 },
      { fieldName: "matter_Disclaimer", width: 33 },
      { fieldName: "matter_Limitations", width: 33 },
      { fieldName: "matter_VerbalElement", width: 33 },
      { fieldName: "matter_PublicationDate", width: 33 },
      { fieldName: "matter_PublicationDeferred", width: 33 },
      { fieldName: "matter_RepeatedPattern", width: 33 },
      { fieldName: "matter_NewnessAndDistinctivenessStatement", width: 66 },
      { fieldName: "matter_DefermentDate", width: 33 },
      { fieldName: "matter_PublicationRegDate", width: 33 },
    ],
  },
  {
    id: 211,
    name: "Customs Recordal Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_ApplicationDate", width: 33 },
      { fieldName: "matter_RegistrationDate", width: 33 },
      { fieldName: "matter_RegistrationNo", width: 33 },
      { fieldName: "matter_GrayMarketImportationRestriction", width: 33 },
    ],
  },
  {
    id: 212,
    name: "Patent Details",
    type: sectionTypes.FORM,
    fieldList: [
      { fieldName: "matter_FinalOfficeActionDate", width: 33 },
      //TODO { fieldName: "matter_PatentCategory", width: 33 },
      //TODO { fieldName: "matter_PatentType", width: 33 },
      { fieldName: "matter_OfficeActionDate", width: 33 },
      //TODO { fieldName: "matter_FinalOARefusalDate", width: 33 },
      { fieldName: "matter_NoticeOfAllowanceDate", width: 33 },
      //TODO { fieldName: "matter_DefermentRequested", width: 33 },
      { fieldName: "matter_DefermentDate", width: 33 },
      { fieldName: "matter_LanguageCode", width: 33 },
      { fieldName: "matter_SecondLanguageCode", width: 33 },
      //TODO { fieldName: "matter_PublicationName", width: 33 },
      //TODO { fieldName: "matter_PublicationNo", width: 33 },
      //TODO { fieldName: "matter_ExhibitDate", width: 33 },
      //TODO { fieldName: "matter_Exhibit", width: 33 },
      { fieldName: "matter_Claims", width: 33 },
      { fieldName: "matter_Disclaimer", width: 33 },
      { fieldName: "matter_Limitations", width: 33 },
      { fieldName: "matter_OptionToExtend", width: 33 },
      { fieldName: "matter_PublicationDeferred", width: 33 },
    ],
  },
  {
    id: 213,
    name: "Account Numbers",
    type: sectionTypes.TABLE,
    tableName: "accountNumbers",
    fieldList: [
      { fieldName: "matterAccountNumber_AccountNumberTypeId", width: 20 },
      { fieldName: "matterAccountNumber_AccountNumber", width: 80 },
    ],
  },
];

// Export function to get section definitions with dynamic variations based on matter type
export const getSectionDefinitions = (matterTypeId) => {
  let sectionDefinitions = [...sectionDefinitionsBase];

  // Determine reference types to display in ADDITIONAL DETAILS section
  // TODO (FUTURE): Add custom fields here also
  const referenceTypesAll = getAllReferenceTypes();

  // Remove any that are already hardcoded into existing sections
  let existingReferenceTypeFields = [];
  sectionDefinitions.forEach((sectionDefinition) => {
    const thisSectionReferenceTypeFields = sectionDefinition.fieldList.filter((field) =>
      field.fieldName.startsWith("REF")
    );
    if (thisSectionReferenceTypeFields.length > 0) {
      existingReferenceTypeFields = [...existingReferenceTypeFields, ...thisSectionReferenceTypeFields];
    }
  });
  let referenceTypesRemaining = [];
  referenceTypesRemaining = referenceTypesAll?.filter(
    (rta) => !existingReferenceTypeFields.some((existing) => idsAreEqual(existing.referenceTypeId, rta.id))
  );

  const additionalDetailsFieldList = referenceTypesRemaining.map((rtr) => {
    return { fieldName: `REF${rtr.id}`, width: 33, referenceTypeId: rtr.id };
  });
  const additionalDetailsSection = {
    id: 19,
    name: "Additional Details",
    type: sectionTypes.FORM,
    fieldList: additionalDetailsFieldList,
  };
  sectionDefinitions.push(additionalDetailsSection);

  // Determine list types to display in CATEGORIES section
  const listTypesAll = getAllListTypes();
  let listTypesForThisMatterType =
    listTypesAll &&
    listTypesAll.length > 0 &&
    listTypesAll.filter(
      (listType) => listType.matterTypeIds.length === 0 || hasMatchingId(listType.matterTypeIds, matterTypeId)
    );
  // Remove any that are already hardcoded into existing sections
  let existingListTypeFields = [];
  sectionDefinitions.forEach((sectionDefinition) => {
    const thisSectionListTypeFields = sectionDefinition.fieldList.filter((field) => field.isListType);
    if (thisSectionListTypeFields.length > 0) {
      existingListTypeFields = [...existingListTypeFields, ...thisSectionListTypeFields];
    }
  });
  let listTypesRemaining = [];
  listTypesRemaining = listTypesForThisMatterType.filter(
    (ltmt) => !existingListTypeFields.some((existing) => existing.fieldName === ltmt.code)
  );

  const categoriesFieldList = listTypesRemaining.map((ltr) => {
    return { fieldName: ltr.code, width: 33, isListType: true };
  });
  const categoriesSection = {
    id: 70,
    name: "Categories",
    type: sectionTypes.FORM,
    fieldList: categoriesFieldList,
  };
  sectionDefinitions.push(categoriesSection);

  return sectionDefinitions;
};
