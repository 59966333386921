import { PropTypes } from "prop-types";
import TableObjectColumn from "./TableObjectColumn";
import { removeMailMergeTableObjectColumn } from "redux/reportSlice";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useDrop } from "react-dnd";

function TableObject({ format, matterTypeIds, mailMergeObject }) {
  const dispatch = useDispatch();

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "self",
      drop(item) {
        dispatch(removeMailMergeTableObjectColumn({ id: item.field.id, objectId: mailMergeObject.id }));
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    []
  );

  const renderGrid = () => {
    let grid = new Array(10).fill(null);

    return grid.map((item, i) => {
      const field =
        mailMergeObject?.tableColumns && mailMergeObject.tableColumns.length >= i + 1
          ? mailMergeObject.tableColumns[i]
          : null;
      const key = field?.id ?? "u" + i;

      return (
        <TableObjectColumn
          key={key}
          field={field}
          listIndex={i}
          objectId={mailMergeObject.id}
          matterTypeIds={matterTypeIds}
          maxEntries={10}
        />
      );
    });
  };

  const trashButtonClassBase = "quick-report-grid__remove-button";
  let trashButtonClass = canDrop ? `${trashButtonClassBase}--active` : trashButtonClassBase;
  if (isOver) trashButtonClass = canDrop ? `${trashButtonClassBase}--drop` : trashButtonClassBase;

  return (
    <div className="object__item">
      <em>Drag fields from the left hand panel onto the right hand grid</em>
      <div className="quick-report-grid-container">
        <div className="quick-report-grid">{renderGrid()}</div>
      </div>
      <button className={trashButtonClass} ref={drop}>
        <FaTrashAlt />
        &nbsp; Remove
      </button>
    </div>
  );
}

TableObject.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.arrayOf(PropTypes.number),
  mailMergeObject: PropTypes.object,
};

export default TableObject;
