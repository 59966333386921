import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SuperSelect from "components/global/SuperSelect";
import { getAllLookupValuesForField } from "utilities/datafield";
import { superSelectOperationTypes } from "utilities/constants";
import { getAllListDisplayValues, getListTypeByCode } from "utilities/lookup";

// Container Component for the display of a text field within the batch update system
function BatchUpdateFieldLookup({ field, rowItem, onChange, isListType }) {
  const [selectedOptionId, setSelectedOptionId] = useState("");
  const [selectedOptionIds, setSelectedOptionIds] = useState([]);
  const [lookupOptions, setLookupOptions] = useState([]);

  const controlId = `field-select-${field?.fieldName}-${rowItem?.id}`;

  useEffect(() => {
    if (!field) return;
    let lookupOptionsLocal = [];
    if (isListType) {
      lookupOptionsLocal = getAllListDisplayValues(getListTypeByCode(field.fieldName)?.id);
    } else lookupOptionsLocal = getAllLookupValuesForField(field.fieldName);
    setLookupOptions(lookupOptionsLocal);
  }, [field?.fieldName]);

  useEffect(() => {
    setSelectedOptionId(field?.newValue || (rowItem && rowItem[field?.fieldName]));
  }, [field?.newValue, rowItem]);

  const handleSelectedOptionChange = (newValue) => {
    setSelectedOptionId(newValue);
    onChange(field, newValue);
  };

  const handleSelectedOptionChangeMulti = (optionId, operation) => {
    let newValues = [];
    if (operation === superSelectOperationTypes.ADD) {
      newValues = [...selectedOptionIds, optionId];
    } else {
      newValues = selectedOptionIds.filter((id) => id !== optionId);
    }
    setSelectedOptionIds(newValues);
    onChange(field, newValues);
  };

  return (
    <div className="td__edit-field-container">
      {lookupOptions?.length > 0 && (
        <SuperSelect
          id={controlId}
          options={lookupOptions}
          selectedOptionId={selectedOptionId}
          selectedOptionIds={selectedOptionIds}
          onChange={handleSelectedOptionChange}
          onChangeMulti={handleSelectedOptionChangeMulti}
          tabIndex={0}
          isMultiValued={field.isMultiValued}
          //sortValuesById={keyProps.field.sortValuesById}
        />
      )}
    </div>
  );
}

BatchUpdateFieldLookup.propTypes = {
  rowItem: PropTypes.object,
  field: PropTypes.object,
  onChange: PropTypes.func,
  isListType: PropTypes.bool,
};

export default BatchUpdateFieldLookup;
