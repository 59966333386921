import { t } from "locale/dictionary";
import { FaPlusCircle } from "react-icons/fa";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { createMatterRecord } from "api/matter";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "redux/appSlice";
import { compareByDisplayValueAsc, findById } from "utilities/stringAndArray";
import useComponentVisible from "components/global/useComponentVisible";
import { clearAllRecords } from "redux/matterSlice";
import { selectAll } from "redux/searchResultsSlice";
import { canCreateMatterRecord, createMatterAccounts } from "utilities/permission";
import { useState } from "react";
import { matterTypes } from "utilities/constants";
import AccountSelector from "components/global/AccountSelector";

// Container for the create record control in the site header
export default function CreateRecord() {
  const locale = useSelector((state) => state.locale);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [refMainPopup, showMainPopup, setShowMainPopup] = useComponentVisible(false);
  const [refMultiAccountPopup, showMultiAccountPopup, setShowMultiAccountPopup] = useComponentVisible(false);
  const [selectedMatterTypeId, setSelectedMatterTypeId] = useState(null);

  let matterTypeObjects = getAllLookupValuesForSource("MatterTypes");
  if (matterTypeObjects) matterTypeObjects = matterTypeObjects.sort(compareByDisplayValueAsc);

  const doCreate = async (accountId, matterTypeId) => {
    const matterType = findById(matterTypeObjects, matterTypeId ?? selectedMatterTypeId);
    const matterTypeName = locale.translations[matterType.translationCode];
    const response = await createMatterRecord(matterType.id, null, accountId);
    if (response?.id) {
      dispatch(selectAll(true));
      dispatch(clearAllRecords());
      dispatch(addToast(`${t("Created new")} ${matterTypeName}`));
      navigate(`/matter/${response.id}`, { replace: true });
      window.location.reload();
    } else {
      dispatch(addToast(`${t("Error creating new")} ${matterTypeName}`));
    }
  };

  const onCreateRecord = async (matterTypeId) => {
    // For companies always let through (they don't get an account assigned)
    if (matterTypeId === matterTypes.COMPANY) {
      if (canCreateMatterRecord(matterTypeId)) doCreate(null, matterTypeId);
      return;
    }
    const createAccounts = createMatterAccounts(matterTypeId);
    if (createAccounts.length === 0) {
      console.log("No accounts available to create record");
      return;
    }
    if (createAccounts.length > 1) {
      setShowMultiAccountPopup(true);
      setSelectedMatterTypeId(matterTypeId);
    } else {
      doCreate(createAccounts[0].accountId, matterTypeId);
    }
  };

  const buttonClassBase = "header__create-record-dropdown";
  let buttonClass = buttonClassBase;
  if (showMainPopup) buttonClass += ` ${buttonClassBase}--popup-visible`;

  const permissibleMatterTypes = matterTypeObjects?.filter((mt) => canCreateMatterRecord(mt.id)) ?? [];

  return (
    <>
      <div className="header__create-record">
        <div className={buttonClass} onClick={() => setShowMainPopup(!showMainPopup)}>
          {t("Create")}&nbsp;
          <FaPlusCircle />
          {showMainPopup && (
            <div className="create-record-dropdown__popup" ref={refMainPopup}>
              {permissibleMatterTypes.map((mt) => (
                <p key={mt.id} onClick={() => onCreateRecord(mt.id)}>
                  {mt.displayValue}
                </p>
              ))}
              <hr />
              <Link to="/batch-create">{t("Batch Create Records")}</Link>
              <Link to="/reportmenu/create">{t("Create Report")}</Link>
              <hr />
              <p>{t("Import via XYZ")}</p>
              <p>{t("Import via doc upload")}</p>
              <p>{t("Import via official record")}</p>
            </div>
          )}
        </div>
      </div>
      {showMultiAccountPopup && (
        <AccountSelector
          ref={refMultiAccountPopup}
          onSubmit={(selectedAccountId) => doCreate(selectedAccountId)}
          onClose={() => setShowMultiAccountPopup(false)}
        />
      )}
    </>
  );
}
