import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from "react-dnd";
import { PropTypes } from "prop-types";
import { updateMailMergeHtml } from "redux/reportSlice";
import { getMailMergeObjectTypes, getOutputDataFieldGroups } from "utilities/reportEditor";
import { CKEditor, useCKEditorCloud } from "@ckeditor/ckeditor5-react";

function RichTextEditor({ format, matterTypeIds, className }) {
  const thirdPartyKeys = useSelector((state) => state.app.thirdPartyKeys);
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [editorKey, setEditorKey] = useState(0);
  const [fieldGroups, setFieldGroups] = useState([]);
  const [mergeFields, setMergeFields] = useState({ definitions: [] });
  const [objectTypes] = useState(getMailMergeObjectTypes());

  useEffect(() => {
    setFieldGroups(getOutputDataFieldGroups(format.queryType, format.formatType, matterTypeIds));
  }, [format.queryType, format.formatType, matterTypeIds]);

  useEffect(() => {
    const objects =
      format.formatMailMerge.objects
        ?.filter((object) => object.mailMergeFormatObjectType === 1)
        .map((object) => {
          const dataSourceLabel = object?.dataSourceName ?? null;
          const objectType = objectTypes.find((objectType) => objectType.id === object.mailMergeFormatObjectType);
          const objectLabel = `${objectType.displayValue}: ${object?.name ?? object?.id} ${
            dataSourceLabel ? `(${dataSourceLabel})` : ""
          }`;

          return {
            id: `__objectId__${object.id}`,
            label: objectLabel,
            type: "block",
            height: "200",
          };
        }) ?? [];

    const customTables =
      objects.length > 0 ? [{ groupId: "Objects", groupLabel: "Objects", definitions: objects }] : [];

    const mergeFields = {
      definitions: [
        ...customTables,
        ...fieldGroups.map((group) => {
          return {
            groupId: group.name,
            groupLabel: group.label,
            definitions: group.dataFields.map((field) => {
              return {
                id: field.name,
                label: field.displayName,
              };
            }),
          };
        }),
      ],
    };
    setMergeFields(mergeFields);
    setEditorKey((prevKey) => prevKey + 1);
  }, [format.formatMailMerge.objects, fieldGroups, objectTypes]);

  const [, drop] = useDrop({
    accept: "box",
    drop: (item) => handleDrop(item),
  });

  const cloud = useCKEditorCloud({
    version: "44.1.0",
    premium: true,
  });

  if (cloud.status === "error") {
    return <div>Error!</div>;
  }

  if (cloud.status === "loading") {
    return <div>Loading...</div>;
  }

  const {
    ClassicEditor,
    Alignment,
    Bold,
    Essentials,
    FontColor,
    FontBackgroundColor,
    FontFamily,
    FontSize,
    Heading,
    HorizontalLine,
    Italic,
    ListProperties,
    Mention,
    Paragraph,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Undo,
    Underline,
  } = cloud.CKEditor;

  const { SlashCommand, MergeFields } = cloud.CKEditorPremiumFeatures;

  const plugins = [
    Alignment,
    Bold,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    Heading,
    HorizontalLine,
    Italic,
    ListProperties,
    Mention,
    MergeFields,
    Paragraph,
    SlashCommand,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Underline,
    Undo,
  ];

  const heading = {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  };

  const toolbar = {
    items: [
      "undo",
      "redo",
      "|",
      "insertMergeField",
      "previewMergeFields",
      "|",
      "heading",
      "|",
      "fontfamily",
      "fontsize",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "subscript",
      "superscript",
      "|",
      "alignment",
      "|",
      "insertTable",
      "|",
      "bulletedList",
      "numberedList",
    ],
    // shouldNotGroupWhenFull: false,
  };

  const table = {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
  };

  const editorConfig = {
    heading,
    initialData: format.formatMailMerge.messageBodyHtml ?? "",
    licenseKey: thirdPartyKeys?.ckEditor,
    mergeFields,
    plugins,
    table,
    toolbar,
  };

  const handleDrop = (item) => {
    const editor = editorRef.current.editor;
    const model = editor.model;
    const insertPosition = model.document.selection.getFirstPosition();

    const mergeField = "{{" + item.name + "}}";

    model.change((writer) => {
      writer.insertText(mergeField, insertPosition);
    });
  };

  const handleChange = (data) => {
    dispatch(updateMailMergeHtml({ html: data }));
  };

  return (
    <div key={editorKey} ref={drop} className={className}>
      <CKEditor
        editor={ClassicEditor}
        onReady={(editor) => {
          editorRef.current = { editor };
        }}
        onChange={(_event, editor) => {
          handleChange(editor.getData());
        }}
        config={editorConfig}
      />
    </div>
  );
}

RichTextEditor.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
  className: PropTypes.string,
};

export default RichTextEditor;
