import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useState } from "react";
import BatchUpdateInsertTableRow from "./BatchUpdateInsertTableRow";
import { t } from "locale/dictionary";
import { getDisplayNameForField } from "utilities/reportEditor";
import { getSectionDefinitions } from "utilities/sectionDefinitions";
import { addInsertRow } from "redux/batchUpdateSlice";

// Displays a table that allows for an insert of a row of composite data, based on dynamic sectionDefinition fields for Batch Update
const BatchUpdateInsertTable = ({ tableName }) => {
  const dispatch = useDispatch();
  const batchUpdateState = useSelector((state) => state.batchUpdate);
  const appState = useSelector((state) => state.app);

  const [section, setSection] = useState(null);
  const [table, setTable] = useState([]);

  useEffect(() => {
    setTable(batchUpdateState[tableName]);
    if (tableName === "commentInserts") {
      setSection(getSectionDefinitions().find((sd) => sd.tableName === "comments"));
    }
    setTable(batchUpdateState[tableName]);
  }, [tableName, batchUpdateState[tableName]]);

  const handleAddNewRow = () => {
    // Create a new row with an id that is unique
    const newRow = {
      id: Math.random().toString(36),
      ...section.fieldList.reduce((acc, field) => {
        acc[field.fieldName] = null; // Add each field name as a property with a null value
        if (field.fieldName === "matterComment_CreatedBy") {
          acc[field.fieldName] = appState.userId;
        }
        return acc;
      }, {}),
    };
    dispatch(addInsertRow({ tableName, newRow }));
  };

  const renderTableHeader = () => {
    return (
      <tr>
        {section?.fieldList
          ?.filter((field) => !field.isHiddenWhenAdding)
          ?.map((field) => (
            <th key={field?.fieldName}>{getDisplayNameForField(field?.fieldName)}</th>
          ))}
        <th></th>
      </tr>
    );
  };

  const renderTable = () => {
    let tableTitle = "";
    if (tableName === "commentInserts") {
      tableTitle = t("Comments");
    }
    return (
      <>
        <div className="batch-update__table-title">{tableTitle}</div>
        <table className="batch-update__table">
          <thead>{renderTableHeader()}</thead>
          <tbody>
            {table?.map((insertRow) => (
              <BatchUpdateInsertTableRow
                key={insertRow.id}
                insertRow={insertRow}
                tableName={tableName}
                section={section}
              />
            ))}
            <tr>
              <td colSpan={(section?.fieldList?.length ?? 0) + 1}>
                <button onClick={handleAddNewRow}>{t("Add Row")}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  return <div className="batch-update__table-container">{renderTable()}</div>;
};

BatchUpdateInsertTable.propTypes = {
  tableName: PropTypes.string,
};

export default BatchUpdateInsertTable;
