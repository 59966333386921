import { batchUpdateSubmit, getBatchUpdateId } from "api/batchUpdate";
import PopupHeader from "components/global/PopupHeader";
import { t } from "locale/dictionary";
import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearBatchUpdate } from "redux/batchUpdateSlice";
import { batchUpdateOperations, labelTypes, searchViewTypes } from "utilities/constants";
import { getDataTypeForField, getDisplayNameForFieldObject, getLookupValueForField } from "utilities/datafield";
import { getDateDisplayValue } from "utilities/dateTime";
import { getListDisplayValue, getListTypeByCode } from "utilities/lookup";
import { isArray } from "highcharts";
import { search, searchAndSetResults } from "api/search";

const BatchUpdateSubmission = ({ onClose }) => {
  const batchUpdateState = useSelector((state) => state.batchUpdate);
  const simpleSearchState = useSelector((state) => state.simpleSearch).currentSearch;
  const searchResultsState = useSelector((state) => state.searchResults).currentSearch;
  const localeState = useSelector((state) => state.locale);

  const [showSubmitResult, setShowSubmitResult] = useState(false);
  const [submissionData, setSubmissionData] = useState(null);
  const [hasSubmissionData, setHasSubmissionData] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setHasSubmissionData(
      submissionData?.fieldOperations?.length > 0 ||
        submissionData?.listOperations?.length > 0 ||
        submissionData?.referenceOperations?.length > 0 ||
        submissionData?.companyOperations?.length > 0 ||
        submissionData?.commentInserts?.length > 0 ||
        submissionData?.imageInserts?.length > 0 ||
        submissionData?.documentInserts?.length > 0
    );
  }, [submissionData]);

  useEffect(() => {
    let fieldOperations = [];
    let listOperations = [];
    let referenceOperations = [];

    let allChangedFormFields = [];
    if (batchUpdateState?.formFields.length > 0)
      allChangedFormFields = [
        ...batchUpdateState.formFields.filter(
          (field) =>
            ((field.newValue !== null || field.operationId === batchUpdateOperations.CLEAR) &&
              field.existingValues?.some((ev) => ev.isChecked === true)) ||
            (field.newValue !== null && field.operationId === batchUpdateOperations.ADD)
        ),
      ];
    allChangedFormFields.forEach((changedFormField) => {
      let valuesToReplace = changedFormField.existingValues
        .filter((value) => value.isChecked)
        .map((value) => value.value);
      const isAllChecked = valuesToReplace.length === changedFormField.existingValues.length;
      let operationVerb = isAllChecked ? "replace_all" : "replace";
      if (changedFormField.operationId === batchUpdateOperations.CLEAR) {
        operationVerb = isAllChecked ? "clear_all" : "clear";
      } else if (changedFormField.operationId === batchUpdateOperations.ADD) {
        operationVerb = "add";
      }
      if (isAllChecked) valuesToReplace = [];

      let submissionDataRow = {
        fieldName: changedFormField.fieldName,
        operation: operationVerb,
        valuesToReplace,
        isListType: changedFormField.isListType,
        isAllChecked,
      };
      // API cannot handle an array or a string, so we need to separate arrays and strings into separate properties (note the plural 'newValues')
      if (isArray(changedFormField.newValue)) submissionDataRow.newValues = changedFormField.newValue;
      else submissionDataRow.newValue = changedFormField.newValue;

      if (changedFormField.isListType) {
        submissionDataRow.id = getListTypeByCode(changedFormField.fieldName)?.id;
        listOperations.push(submissionDataRow);
      } else if (changedFormField?.fieldName?.includes("REF")) {
        submissionDataRow.id = changedFormField.fieldName.slice(3);
        referenceOperations.push(submissionDataRow);
      } else {
        fieldOperations.push(submissionDataRow);
      }
    });
    setSubmissionData((prev) => ({
      ...prev,
      fieldOperations,
      listOperations,
      referenceOperations,
    }));
  }, [batchUpdateState.formFields]);

  useEffect(() => {
    const changedCompanyLinks = [...batchUpdateState.companyLinks.filter((cl) => cl.newValue || cl.operationId === 1)];

    let companyOperations = changedCompanyLinks.map((cl) => {
      const checkedValues = cl.existingValues.filter((value) => value.isChecked);
      const isAllChecked = checkedValues.length === cl.existingValues.length;

      let operationVerb = isAllChecked ? "replace_all" : "replace";
      if (cl.operationId === batchUpdateOperations.CLEAR) {
        operationVerb = isAllChecked ? "clear_all" : "clear";
      }
      if (cl.operationId === batchUpdateOperations.ADD) {
        operationVerb = "add";
      }
      return {
        id: cl.id,
        linkTypeDisplayName: localeState.translations[cl.translationCode],
        operation: operationVerb,
        valuesToReplace: isAllChecked ? [] : checkedValues.map((value) => value.matterId),
        valuesToReplaceDisplayNames: isAllChecked ? ["All"] : checkedValues.map((value) => value.name),
        newValue: cl.newValue?.matterId,
        newValueDisplayName: cl.newValue?.name,
        isListType: cl.isListType,
        contactLinks: cl.contactLinks,
      };
    });
    setSubmissionData((prev) => ({ ...prev, companyOperations }));
  }, [batchUpdateState.companyLinks]);

  useEffect(() => {
    const commentInserts = [...batchUpdateState.commentInserts];
    setSubmissionData((prev) => ({ ...prev, commentInserts }));
  }, [batchUpdateState.commentInserts]);

  useEffect(() => {
    const imageInserts = [...batchUpdateState.imageInserts];
    setSubmissionData((prev) => ({ ...prev, imageInserts }));
  }, [batchUpdateState.imageInserts]);

  useEffect(() => {
    const documentInserts = [...batchUpdateState.documentInserts];
    setSubmissionData((prev) => ({ ...prev, documentInserts }));
  }, [batchUpdateState.documentInserts]);

  const handleFinalSubmit = async () => {
    const response = await batchUpdateSubmit(submissionData);
    if (response) {
      setShowSubmitResult(true);
      if (response?.success === true) {
        dispatch(clearBatchUpdate()); // Clear the batch update state if the submission was successful
        // Refresh the search results with the new DB data
        const selectedResults = searchResultsState.selected;
        await searchAndSetResults(simpleSearchState.queryObject, searchViewTypes.MAIN, selectedResults);
      }
    }
  };

  const handleGetNewBatch = () => {
    setShowSubmitResult(false);

    getBatchUpdateId(batchUpdateState.recordIds);
    onClose();
  };

  const renderSummaryChangesRow = (rowItem, tableName) => {
    let rowItemDisplayName = "";
    let valuesToReplaceDisplay = "";
    let newValueDisplay = "";

    if (tableName === "formFields") {
      rowItemDisplayName = getDisplayNameForFieldObject(rowItem);
      const fieldType = getDataTypeForField(rowItem.fieldName);
      if (fieldType === labelTypes.LOOKUP) {
        valuesToReplaceDisplay = rowItem.valuesToReplace
          .map((value) => getLookupValueForField(rowItem.fieldName, value))
          .join(", ");
        newValueDisplay = getLookupValueForField(rowItem.fieldName, rowItem.newValue);
      } else if (fieldType === labelTypes.DATE) {
        valuesToReplaceDisplay = rowItem.valuesToReplace.map((value) => getDateDisplayValue(value)).join(", ");
        newValueDisplay = getDateDisplayValue(rowItem.newValue);
      } else if (fieldType === labelTypes.BOOLEAN) {
        valuesToReplaceDisplay = rowItem.valuesToReplace.map((value) => (value ? "Yes" : "No")).join(", ");
        newValueDisplay = rowItem.newValue ? "Yes" : "No";
      } else if (rowItem.isListType) {
        const listTypeId = getListTypeByCode(rowItem.fieldName)?.id;
        valuesToReplaceDisplay = rowItem.valuesToReplace.map((value) => getListDisplayValue(listTypeId, value));
        if (isArray(rowItem.newValues))
          newValueDisplay = rowItem.newValues.map((nv) => getListDisplayValue(listTypeId, nv)).join(", ");
        else newValueDisplay = getListDisplayValue(listTypeId, rowItem.newValue);
      } else {
        valuesToReplaceDisplay = rowItem.valuesToReplace.join(", ");
        newValueDisplay = rowItem.newValue;
      }
      if (rowItem.isAllChecked) {
        valuesToReplaceDisplay = "All";
      }
      if (
        rowItem.valuesToReplace.length === 1 &&
        (rowItem.valuesToReplace[0] === null ||
          rowItem.valuesToReplace[0] === undefined ||
          rowItem.valuesToReplace[0] === "")
      ) {
        valuesToReplaceDisplay = "{Empty Value}";
      }
    } else if (tableName === "companyLinks") {
      rowItemDisplayName = rowItem.linkTypeDisplayName;
      valuesToReplaceDisplay = rowItem.valuesToReplaceDisplayNames.join(", ");
      newValueDisplay = rowItem.newValueDisplayName;
    } else if (tableName === "commentInserts") {
      rowItemDisplayName = t("Comment");
      valuesToReplaceDisplay = "n/a";
      newValueDisplay = rowItem.matterComment_Text;
    } else if (tableName === "imageInserts") {
      rowItemDisplayName = t("Image");
      valuesToReplaceDisplay = "n/a";
      newValueDisplay = rowItem.matterImage_Description;
    } else if (tableName === "documentInserts") {
      rowItemDisplayName = t("Document");
      valuesToReplaceDisplay = "n/a";
      newValueDisplay = rowItem.matterDocument_Description;
    }

    let operationDisplay = "Replace";
    if (rowItem.operation === "clear") operationDisplay = "Clear";
    if (rowItem.operation === "replace_all") operationDisplay = "Replace All";
    if (rowItem.operation === "clear_all") operationDisplay = "Clear All";
    if (rowItem.operation === "add") {
      operationDisplay = "Add";
      valuesToReplaceDisplay = "n/a";
    }
    if (rowItem.operation === "clear") {
      newValueDisplay = "n/a";
    }

    if (tableName === "commentInserts" || tableName === "imageInserts" || tableName === "documentInserts") {
      operationDisplay = "Add";
    }

    return (
      <tr key={rowItem.fieldName}>
        <td>{rowItemDisplayName}</td>
        <td>{valuesToReplaceDisplay}</td>
        <td>{operationDisplay}</td>
        <td>{newValueDisplay}</td>
      </tr>
    );
  };

  const renderSummaryChangesTable = () => {
    return (
      <table className="submission__table">
        <thead>
          <tr>
            <th>{t("Field")}</th>
            <th>{t("Current Value(s)")}</th>
            <th>{t("Operation")}</th>
            <th>{t("New Value")}</th>
          </tr>
        </thead>
        <tbody>
          {hasSubmissionData ? (
            <>
              {submissionData.fieldOperations?.map((field) => renderSummaryChangesRow(field, "formFields"))}
              {submissionData.referenceOperations?.map((field) => renderSummaryChangesRow(field, "formFields"))}
              {submissionData.listOperations?.map((field) => renderSummaryChangesRow(field, "formFields"))}
              {submissionData.companyOperations?.map((field) => renderSummaryChangesRow(field, "companyLinks"))}
              {submissionData.commentInserts?.map((field) => renderSummaryChangesRow(field, "commentInserts"))}
              {submissionData.imageInserts?.map((field) => renderSummaryChangesRow(field, "imageInserts"))}
              {submissionData.documentInserts?.map((field) => renderSummaryChangesRow(field, "documentInserts"))}
            </>
          ) : (
            <tr>
              <td colSpan={4}>
                <div className="submission__no-changes">{t("No changes to submit")}</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const renderFinalSubmit = (
    <>
      <div className="submission__confirmation-text">
        Are you sure you want to submit the above changes to these records? The operation cannot be reversed.
      </div>
      <div className="submission__button-row">
        <button className="submission__submit-button-final" onClick={handleFinalSubmit}>
          {t("SUBMIT")}
        </button>
        <button onClick={onClose}>{t("Cancel")}</button>
      </div>
    </>
  );

  const renderSubmissionResult = (
    <div className="submission__result">
      <div className="submission__result-row submission__result-row--success">
        {t("Batch Update Submitted Successfully")}
      </div>
      <div className="submission__result-row submission__result-row--link" onClick={handleGetNewBatch}>
        {t("Close this popup to perform more operations on the same batch")}
      </div>
      <div className="submission__result-row">{t("or")}</div>
      <Link to="/results" className="submission__result-row submission__result-row--link">
        {t("Return to search results")}
      </Link>
    </div>
  );

  return (
    <div className="modal-mask">
      <div className="batch-update__submission">
        <PopupHeader title={t("Confirm Batch Update Submission")} onClose={onClose} />
        <div className="submission__intro">Batch Update Summary Changes</div>
        {submissionData && (
          <>
            {renderSummaryChangesTable()}
            {hasSubmissionData && renderFinalSubmit}
            {showSubmitResult && renderSubmissionResult}
          </>
        )}
      </div>
    </div>
  );
};
BatchUpdateSubmission.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default BatchUpdateSubmission;
