import ObjectItem from "./ObjectItem";
import { PropTypes } from "prop-types";
import CreateObject from "./CreateObject";

function ObjectList({ format, matterTypeIds }) {
  return (
    <>
      {format.formatMailMerge.objects?.map((object) => (
        <ObjectItem key={object.id} format={format} mailMergeObject={object} matterTypeIds={matterTypeIds} />
      ))}
      <CreateObject />
    </>
  );
}

ObjectList.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default ObjectList;
