import { getCustomerAccounts } from "api/security";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PopupHeader from "./PopupHeader";
import { t } from "locale/dictionary";
import SuperSelect from "./SuperSelect";

// Control for display a popup for the user to select an account for either login or when performing a permission-restricted action
function AccountSelector({ onClose, onSubmit }) {
  const appState = useSelector((state) => state.app);

  const [accounts, setAccounts] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState(null);

  useEffect(() => {
    const accountsDisplayList = appState.permissions.map((a) => ({ id: a.accountId, displayValue: a.accountName }));
    setAccounts(accountsDisplayList);
  }, [appState.permissions]);

  return (
    <div className="modal-mask">
      <div className="link-copy-modal__popup">
        <PopupHeader title={t("Select Account for Record Creation")} onClose={onClose} />
        <div className="link-copy-modal__body">
          <div className="link-copy-modal__row">
            Your login has access to multiple accounts. Please select the account you wish to assign the new matter
            record to:
          </div>
          <div className="link-copy-modal__row">
            <SuperSelect
              options={accounts}
              onChange={(optionId) => setSelectedAccountId(optionId)}
              selectedOptionId={selectedAccountId}
              value={selectedAccountId}
            />
          </div>
          <div className="link-copy-modal__row">
            {selectedAccountId && <button onClick={() => onSubmit(selectedAccountId)}>Create Record</button>}
            <button onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}

AccountSelector.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AccountSelector;
