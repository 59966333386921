import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Control for displaying a boolean slider switch
function Switch({ isOn: isOnExternal, text, onSwitchChange, children, tabIndex }) {
  const [isOnInternal, setIsOnInternal] = useState(null);

  useEffect(() => {
    if (isOnInternal === null) setIsOnInternal(isOnExternal);
  }, [isOnExternal]);

  const labelClassBase = "react-switch-label";
  const buttonClassBase = "react-switch-button";

  let labelClass = labelClassBase;
  let buttonClass = buttonClassBase;
  if (isOnInternal === false) {
    labelClass = `${labelClassBase} ${labelClassBase}--off`;
    buttonClass = `${buttonClassBase} ${buttonClassBase}--off`;
  }
  if (isOnInternal === true) {
    labelClass = `${labelClassBase} ${labelClassBase}--on`;
    buttonClass = `${buttonClassBase} ${buttonClassBase}--on`;
  }

  const handleSwitchChange = () => {
    if (isOnInternal === null || isOnInternal === false) {
      setIsOnInternal(true);
      onSwitchChange(true);
    }
    if (isOnInternal === true) {
      setIsOnInternal(false);
      onSwitchChange(false);
    }
  };

  const handleClick = () => {
    handleSwitchChange();
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      handleSwitchChange();
    }
  };

  return (
    <div className="switch-container" onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={tabIndex}>
      {t(text)}
      <label className={labelClass} htmlFor="react-switch-new">
        <span className={buttonClass} />
      </label>
      &nbsp;{children}
    </div>
  );
}

Switch.propTypes = {
  isOn: PropTypes.bool,
  text: PropTypes.string,
  onSwitchChange: PropTypes.func,
  tabIndex: PropTypes.number,
};

export default Switch;
