import { createSlice } from "@reduxjs/toolkit";
import { batchUpdateOperations } from "utilities/constants";
import { findById, idsAreEqual } from "utilities/stringAndArray";

const getStateRow = (state, rowItem, tableName) => {
  const table = state[tableName];
  if (tableName === "formFields") return table.find((ff) => ff.fieldName === rowItem.fieldName);
  else if (tableName === "companyLinks") return table.find((ff) => ff.id === rowItem.id);
};

// Redux global state component for Batch Updating of matter records
export const batchUpdateSlice = createSlice({
  name: "batchUpdate",
  initialState: {
    id: null,
    recordIds: [],
    matterTypeIds: [],
    formFields: [], // table for single form fields
    companyLinks: [], // table for company links
    legalTeam: [], // table for legal team
    imageInserts: [], // table for image inserts
    commentInserts: [], // table for comment inserts
    documentInserts: [], // table for document inserts
  },
  reducers: {
    setBatchUpdateId: (state, action) => {
      state.id = action.payload;
    },
    setRecordIds: (state, action) => {
      state.recordIds = action.payload;
    },
    setMatterTypeIds: (state, action) => {
      state.matterTypeIds = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setCompanyLinks: (state, action) => {
      state.companyLinks = action.payload;
    },
    setLegalTeam: (state, action) => {
      state.legalTeam = action.payload;
    },
    toggleRowExpansion: (state, action) => {
      const { rowItem, tableName } = action.payload;
      const row = getStateRow(state, rowItem, tableName);
      if (row) row.isExpanded = !row.isExpanded;
    },
    setRowExistingValues: (state, action) => {
      const { rowItem, existingValues, tableName } = action.payload;
      const row = getStateRow(state, rowItem, tableName);
      if (row) row.existingValues = existingValues;
    },
    setRowNewValue: (state, action) => {
      const { rowItem, newValue, tableName } = action.payload;
      const row = getStateRow(state, rowItem, tableName);
      // if( rowItem.operationId === batchUpdateOperations.ADD) {
      //   state[tableName].push(rowItem);
      // }
      if (row) row.newValue = newValue;
    },
    setRowOperationId: (state, action) => {
      const { rowItem, operationId, tableName } = action.payload;
      const row = getStateRow(state, rowItem, tableName);
      if (row) row.operationId = operationId;
    },
    toggleExistingRowValueChecked: (state, action) => {
      const { rowItem, value, tableName } = action.payload;
      const row = getStateRow(state, rowItem, tableName);
      const existingValues = row.existingValues;

      let existingValueIndex = -1;
      if (tableName === "formFields") {
        existingValueIndex = existingValues.findIndex((ev) => ev.value === value);
      } else if (tableName === "companyLinks") {
        existingValueIndex = existingValues.findIndex((ev) => ev.matterId === value);
      }
      if (!existingValues) return;

      if (existingValueIndex >= 0)
        existingValues[existingValueIndex].isChecked = !existingValues[existingValueIndex].isChecked;
    },
    toggleAllExistingRowValuesChecked: (state, action) => {
      const { rowItem, tableName, clearAll } = action.payload;
      const row = getStateRow(state, rowItem, tableName);
      const existingValues = row.existingValues;

      if (!existingValues) return;

      if (clearAll) {
        // Override: Clear all checked values
        existingValues.forEach((ev) => {
          ev.isChecked = false;
        });
      } else {
        // Toggle logic
        const allChecked = existingValues.every((ev) => ev.isChecked);
        existingValues.forEach((ev) => {
          ev.isChecked = !allChecked;
        });
      }
    },
    clearBatchUpdate: (state) => {
      state.id = null;
      state.formFields = state.formFields.map((ff) => {
        return {
          fieldName: ff.fieldName,
          isExpanded: false,
          matterTypeIds: ff.matterTypeIds,
          isListType: ff.isListType,
        };
      });
      state.companyLinks = state.companyLinks.map((cl) => {
        return { id: cl.id, isExpanded: false, matterTypeIds: cl.matterTypeIds };
      });
      state.commentInserts = [];
      state.imageInserts = [];
      state.documentInserts = [];
      //state.legalTeam = [];
    },
    addContactLink: (state, action) => {
      const { parentRowItem, tableName, newObject } = action.payload;
      const table = state[tableName];
      const parentRow = findById(table, parentRowItem.id);
      if (parentRow) {
        (parentRow.contactLinks ??= []).push(newObject);
      }
    },
    updateContactLink: (state, action) => {
      const { contactLinkId, parentRowItem, tableName, fieldName, optionId, contactIdField, roleIdField } =
        action.payload;
      const table = state[tableName];
      const parentRow = findById(table, parentRowItem.id);
      if (parentRow) {
        const contactLink = parentRow.contactLinks.find((cl) => idsAreEqual(cl[contactIdField], contactLinkId)); // TODO: Find by type of id not just id
        if (contactLink) {
          contactLink[fieldName] = optionId;
        }
      }
    },
    deleteContactLink: (state, action) => {
      const { contactLinkId, parentRowItem, tableName, contactIdField } = action.payload;
      const table = state[tableName];
      const parentRow = findById(table, parentRowItem.id); // TODO: companyLinks by "linkTypeId" ?
      if (parentRow) {
        parentRow.contactLinks = parentRow.contactLinks.filter((cl) => !idsAreEqual(cl[contactIdField], contactLinkId)); // TODO: Find by type of id not just id
      }
    },
    clearContactLinks: (state, action) => {
      const { parentRowItem, tableName } = action.payload;
      const table = state[tableName];
      const parentRow = findById(table, parentRowItem.id);
      if (parentRow) {
        parentRow.contactLinks = [];
      }
    },
    saveImageData: (state, action) => {
      const { imageId, fileName, description, base64, sortIndex } = action.payload;
      state.imageInserts.push({
        matterImage_ImageId: imageId,
        matterImage_Filename: fileName,
        matterImage_Description: description,
        matterImage_Base64: base64,
        matterImage_SortIndex: sortIndex,
      });
    },
    updateImageSortIndex: (state, action) => {
      const { imageId, newSortIndex, existingSortIndex } = action.payload;
      const image = state.imageInserts.find((image) => idsAreEqual(image.matterImage_ImageId, imageId));
      if (image) {
        image.matterImage_SortIndex = newSortIndex;
      }
    },
    updateImageDescription: (state, action) => {
      const { imageId, description } = action.payload;
      const image = state.imageInserts.find((image) => idsAreEqual(image.matterImage_ImageId, imageId));
      if (image) {
        image.matterImage_Description = description;
      }
    },
    deleteImage: (state, action) => {
      const imageId = action.payload;
      state.imageInserts = state.imageInserts.filter((image) => !idsAreEqual(image.matterImage_ImageId, imageId));
    },
    saveDocumentData: (state, action) => {
      const { documentId, fileName, description, base64 } = action.payload;
      state.documentInserts.push({
        matterDocument_DocumentId: documentId,
        matterDocument_Filename: fileName,
        matterDocument_Base64: base64,
        matterDocument_Description: description,
        matterDocument_DocumentTypeId: 0,
      });
    },
    addInsertRow: (state, action) => {
      const { tableName, newRow } = action.payload;
      state[tableName].push(newRow);
    },
    updateInsertRow: (state, action) => {
      const { tableName, insertRow, fieldName, newValue } = action.payload;
      const row = state[tableName].find((r) => r.id === insertRow.id);
      if (row) {
        row[fieldName] = newValue;
      }
    },
    deleteInsertRow: (state, action) => {
      const { tableName, rowId } = action.payload;
      state[tableName] = state[tableName].filter((r) => r.id !== rowId);
    },
  },
});

export const {
  setBatchUpdateId,
  setRecordIds,
  setMatterTypeIds,
  setFormFields,
  setCompanyLinks,
  setLegalTeam,
  toggleRowExpansion,
  toggleExistingRowValueChecked,
  toggleAllExistingRowValuesChecked,
  setRowExistingValues,
  setRowNewValue,
  setRowOperationId,
  clearBatchUpdate,
  addContactLink,
  updateContactLink,
  deleteContactLink,
  clearContactLinks,
  updateImageDescription,
  updateImageSortIndex,
  deleteImage,
  saveImageData,
  saveDocumentData,
  addInsertRow,
  updateInsertRow,
  deleteInsertRow,
} = batchUpdateSlice.actions;

export default batchUpdateSlice.reducer;
