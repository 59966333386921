import { PropTypes } from "prop-types";
import RichTextEditor from "./text-editor/RichTextEditor";
import ObjectList from "./objects/ObjectList";
import Properties from "./properties/Properties";

function FormatMailMerge({ format, matterTypeIds }) {
  return (
    <div className="report-editor__mail-merge-container">
      <RichTextEditor format={format} matterTypeIds={matterTypeIds} className="mail-merge-container__editor" />
      <Properties format={format} matterTypeIds={matterTypeIds} />
      <ObjectList format={format} matterTypeIds={matterTypeIds} />
    </div>
  );
}

FormatMailMerge.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default FormatMailMerge;
