import { setAllMatterTypes, setPermissions, setThirdPartyKeys } from "redux/appSlice";
import store from "redux/store";
import { fetchWrapper } from "utilities/fetchWrapper";

export async function loadAllMatterTypes() {
  const state = store.getState();
  if (state.app.allMatterTypes.length > 0) return;
  const response = await fetchWrapper.get("matterTypes");
  store.dispatch(setAllMatterTypes(response));
}

export async function loadPermissions() {
  const state = store.getState();
  const response = await fetchWrapper.get(`customers/${state.app.customerId}/permissions`);
  store.dispatch(setPermissions(response));
}

export async function loadThirdPartyKeys() {
  const state = store.getState();
  if (state.app.thirdPartyKeys !== null) return;
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/thirdPartyKeys`);
  store.dispatch(setThirdPartyKeys(response));
}
