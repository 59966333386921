import { useEffect, useState } from "react";
import { getDataTypeForField, getLookupValueForField } from "utilities/datafield";
import { labelTypes, matterTypes, permissionTypes, searchViewTypes, validationStates } from "utilities/constants";
import PropTypes from "prop-types";
import { FaCaretDown, FaCaretRight, FaPlusCircle } from "react-icons/fa";
import PanelTable from "./PanelTable";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";
import { compareByDisplayName, findById, idsAreEqual } from "utilities/stringAndArray";
import { setViewTypeSearchResults } from "redux/searchResultsSlice";
import { queryTypeChange, setViewTypeSimpleSearch } from "redux/simpleSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearSectionTableExpandByMatterId } from "redux/matterSlice";

// Container Component for the special Links (Connected Matters) table type data panel for a matter record
/* Has the following responsibilities:
    - Display of overall table structure including hierarchies by matter type
    - Determination of columns to display by matter type
    - Sorting of columns on receiving click info from header component
    - Initiation of Add Connected Matter popup
    - Display of validation errors
*/
function PanelTableLinks({ keyProps, onSetConnectedMatterPopupVisible }) {
  const localeState = useSelector((state) => state.locale);
  const lookupState = useSelector((state) => state.lookup);
  const isAllExpanded = useSelector((state) => state.matter).childTableIsExpandingAll.includes(keyProps.sectionId);
  const expandedByMatterType = useSelector((state) => state.matter).sectionTableExpandByMatterId?.[keyProps.sectionId];
  const dispatch = useDispatch();

  const record = keyProps.record;

  const [dataRowsByMatterType, setDataRowsByMatterType] = useState([]);

  const fieldListCommon = [
    { fieldName: "matterLink_LinkTypeId", width: 20, isRequired: true },
    { fieldName: "matterLink_Name", width: 20, linkPropertyName: "custom", isReadOnly: true },
    { fieldName: "matterLink_FirstMatterImageId", width: 20, isReadOnly: true },
    { fieldName: "matterLink_RecordTypeId", width: 20, isReadOnly: true },
    { fieldName: "matterLink_CountryId", width: 20, isReadOnly: true },
    { fieldName: "matterLink_StatusId", width: 20, isReadOnly: true },
  ];

  const rowDefsByMatterType = [
    {
      matterTypeId: matterTypes.COPYRIGHTS,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_AppRegNo", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AppRegDate", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.DESIGNS,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_AppRegNo", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AppRegDate", width: 20, isReadOnly: true },
        { fieldName: "matterLink_LocarnoClass", width: 33, isReadOnly: true }, // check name
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.DOMAINNAME,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_ServiceProviderName", width: 20, isReadOnly: true }, // check name
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.PATENT,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_AppRegNo", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AppRegDate", width: 20, isReadOnly: true },
        { fieldName: "matterLink_Class", width: 20, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.SOCIALMEDIA,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_ServiceProviderName", width: 20, isReadOnly: true }, // check name
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.TRADEMARK,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_AppRegNo", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AppRegDate", width: 20, isReadOnly: true },
        { fieldName: "matterLink_Class", width: 20, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.CUSTOMSRECORDALS,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_ClientName", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.GENERALMATTER,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_ClientName", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.OTHERPARTYREFERENCE,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true }, // other party owner?
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true }, // other party agent?
        { fieldName: "matterLink_AppRegNo", width: 20, isReadOnly: true },
        { fieldName: "matterLink_AppRegDate", width: 20, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.ASSIGNMENTANDRECORDALS,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_OtherPartyName", width: 20, isReadOnly: true }, // check name
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.BRANDPROTECTION,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_ServiceProviderName", width: 20, isReadOnly: true }, // check name
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.CONTRACT,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_OtherPartyName", width: 20, isReadOnly: true }, // check name
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.CLEARANCESEARCHES,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_ServiceProviderName", width: 20, isReadOnly: true }, // check name
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.DISPUTE,
      fieldList: [
        ...fieldListCommon,
        { fieldName: "matterLink_OwnerName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_OtherPartyName", width: 20, isReadOnly: true }, // check name
        { fieldName: "matterLink_AgentName", width: 33, isReadOnly: true },
        { fieldName: "matterLink_FileReferenceNumber", width: 20, isReadOnly: true },
        { fieldName: "matterLink_CaseReferenceNumber", width: 20, isReadOnly: true },
      ],
    },
    {
      matterTypeId: matterTypes.COMPANY,
      fieldList: [...fieldListCommon],
    },
  ];

  useEffect(() => {
    dispatch(clearSectionTableExpandByMatterId());
    if (!record.links) return;
    let dataRowsLocal = [];
    record.links.forEach((link) => {
      const matterTypeId = link.matterLink_MatterTypeId;
      if (!dataRowsLocal.find((dr) => idsAreEqual(dr.matterTypeId, matterTypeId))) {
        const isExpanded = expandedByMatterType?.[matterTypeId] ?? false;
        dataRowsLocal.push({
          matterTypeId,
          links: [link],
          isExpanded,
          displayName: getLookupValueForField("matter_MatterTypeId", matterTypeId),
        });
      }
      let dataRow = dataRowsLocal.find((dr) => idsAreEqual(dr.matterTypeId, matterTypeId));
      if (!findById(dataRow.links, link.id)) dataRow.links.push(link);

      // In order to do column sorting in the user's language we need to get display values for all lookup types, and append these to the data set
      if (!dataRow.lookupDisplayValues) {
        let lookupDisplayValues = [];
        const rowDef = rowDefsByMatterType.find((rowDef) => idsAreEqual(rowDef.matterTypeId, matterTypeId));
        rowDef.fieldList.forEach((field) => {
          const fieldName = field.fieldName;
          const dataType = getDataTypeForField(fieldName);
          if (dataType === labelTypes.LOOKUP) {
            const lookupDisplayValue = getLookupValueForField(fieldName, dataRow[fieldName]);
            lookupDisplayValues.push({ fieldName, lookupDisplayValue });
          }
        });
        dataRow = { ...dataRow, lookupDisplayValues };
      }
    });
    // Clear out any deleted rows or empty groups
    dataRowsLocal.forEach((group) => {
      group.links = group.links.filter((groupLink) =>
        record.links.some((rLink) => idsAreEqual(groupLink.matterLink_MatterId, rLink.matterLink_MatterId))
      );
    });
    dataRowsLocal = dataRowsLocal.filter((group) => group.links.length > 0);

    // Alphabetise
    dataRowsLocal = dataRowsLocal.sort(compareByDisplayName);

    setDataRowsByMatterType(dataRowsLocal);
  }, [record.links]);

  const handleGroupExpanderClick = (groupMatterTypeId) => {
    let dataRowsLocal = [...dataRowsByMatterType];
    let groupToChange = dataRowsLocal.find((g) => idsAreEqual(g.matterTypeId, groupMatterTypeId));
    groupToChange.isExpanded = !groupToChange.isExpanded;
    setDataRowsByMatterType(dataRowsLocal);
  };

  const onAddRow = (parentRowData) => {
    onSetConnectedMatterPopupVisible(true);
    dispatch(setViewTypeSimpleSearch(searchViewTypes.CONNECTED_MATTERS));
    dispatch(setViewTypeSearchResults(searchViewTypes.CONNECTED_MATTERS));
    const lookupSource = lookupState.global.find((lookup) => lookup.name === "QueryTypes");
    const queryTypeMatter = lookupSource.lookup.find((lookup) => lookup.code === "MATTER");
    dispatch(queryTypeChange(queryTypeMatter));
  };

  const renderGroups =
    dataRowsByMatterType &&
    dataRowsByMatterType.map((g, index) => {
      const isExpanded = g.isExpanded || isAllExpanded;
      let keyPropsGroup = {
        ...keyProps,
        fieldList: rowDefsByMatterType?.find((rowDef) => idsAreEqual(rowDef.matterTypeId, g.matterTypeId)).fieldList,
        groupedTableData: g.links,
      };
      const classNameHeaderBase = "table-group__header";
      let classNameHeader = classNameHeaderBase;
      if (isExpanded) classNameHeader += ` ${classNameHeaderBase}--expanded`;
      return (
        <div key={g.matterTypeId} className="data-panel__table-group">
          <div className={classNameHeader} onClick={() => handleGroupExpanderClick(g.matterTypeId)}>
            {isExpanded ? <FaCaretDown /> : <FaCaretRight />}
            {`${getLookupValueForField("matterLink_MatterTypeId", g.matterTypeId)} (${g.links.length})`}
          </div>
          {isExpanded && <PanelTable keyProps={keyPropsGroup} />}
        </div>
      );
    });

  // FORMATTING & RENDERING
  let message = "";
  const classSectionBase = "data-panel__validation";
  let classModifier = "";
  let fieldErrorStringArray = [];
  const section = record.sections?.find((section) => section.sectionName === keyProps.sectionName);
  if (section?.fields) {
    section.fields.forEach((field) => {
      field.validationStates.forEach((vs) => {
        if (vs.state === validationStates.ERROR) {
          const errorString = localeState.translations[vs.string];
          fieldErrorStringArray.push(errorString);
        }
      });
    });

    message = fieldErrorStringArray.join(". ");
    if (message.length > 0) classModifier = "--error";
  }

  const renderValidationErrors = message.length > 0 && !keyProps.parentTableName && (
    <div className={`${classSectionBase} ${classSectionBase}${classModifier}`}>{message}</div>
  );

  const tableClassNameBase = "data-panel__table";
  let tableClassName = tableClassNameBase;
  if (keyProps.noActionBar) tableClassName += ` ${tableClassNameBase}--sub-table`;

  let displayAddButton = true;
  if (keyProps.permissions) {
    displayAddButton = (keyProps.permissions & permissionTypes.CREATE) === permissionTypes.CREATE;
  }

  return (
    <div className={tableClassName}>
      {record && (
        <>
          {renderValidationErrors}
          {renderGroups}
          {displayAddButton && (
            <div className="data-panel__action-bar">
              <div className="data-panel__table-add-button clickable" onClick={onAddRow}>
                <Tooltip content={t("Add a new row of data")} direction="right">
                  <>
                    {t("Add")}&nbsp;
                    <FaPlusCircle />
                  </>
                </Tooltip>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

PanelTableLinks.propTypes = {
  keyProps: PropTypes.object,
  onSetConnectedMatterPopupVisible: PropTypes.func,
};

export default PanelTableLinks;
